import { acceptHMRUpdate, defineStore } from 'pinia'
import cookieService from '@/services/cookie.service'
import {setCookie,getCookie} from "@/utils/CookieFunc";

export const useCookieStore = defineStore({
  id: 'cookieStore',
  state: () => ({
    cookie: null
  }),
  setup () {},
  actions: {
    getCookie () {
      return cookieService.getCookie(getCookie("cookieToken")).then(
        response => {
          if (response.status === 200) {
            this.cookie = response.data.data.cookie

            setCookie("cookieToken",response.data.data.cookie.token)
            if(response.data.data.isShowCookie === true) setCookie("cookieShow",true)
          }
          return response
        }
      )
    },
    async updateCookie() {
      if (this.cookie == null) await this.get()

      return cookieService.updateCookie({
        'token': this.cookie.token,
        'analyticalAndPerformanceCookies': (this.cookie.analyticalAndPerformanceCookies) ? 1 : 0,
        'functionalityCookies': (this.cookie.functionalityCookies) ? 1 : 0,
        'targetingCookies': (this.cookie.targetingCookies) ? 1 : 0,
      }).then(
        response => {
          if (response.status === 200) {
            this.cookie = response.data.data.cookie
          }
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCookieStore, import.meta.hot))
}
