import { BaseService } from './BaseService'
const GET_MY_PROFILE_DATA_PATH = '/v1/user-profile/info'
const UPDATA_MY_PROFILE_DATA_PATH = '/v1/user-profile/update'

class MeService extends BaseService {
  getMyProfileData () {
    return this.get(GET_MY_PROFILE_DATA_PATH)
  }

  updateMyProfileData () {
    return this.put(UPDATA_MY_PROFILE_DATA_PATH)
  }
}

export default new MeService()
