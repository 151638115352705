import { BaseService } from './BaseService'
const APPLY_VIEW_PATH = '/v1/apply/view'
const APPLY_CREATE_PATH = '/v1/apply/create'
const SET_ANSWER_PATH = '/v1/apply/set-answer'
const SET_APPLY_PARENT_ID_PATH = '/v1/apply/set-parent-apply-id'
const UPLOAD_PATH = '/v1/apply/file-upload'
const SEND_PATH = '/v1/apply/send'
const GET_MY_IDEAS_PATH = '/v1/ideas/my-ideas'
const GET_MY_IDEA_DRAFTS_PATH = '/v1/ideas/my-drafts'
const GET_MY_RETURNED_IDEA_PATH = '/v1/ideas/returned-to-me'
const GET_MY_EDITABLE_IDEA_PATH = '/v1/ideas/my-editable-ideas'
const DELETE_DRAFT = '/v1/ideas/delete-draft'
const GET_SINGLE_PATH = '/v1/ideas/preview'
const APPLY_DETAIL = '/v1/apply/detail'

class ApplicationsService extends BaseService {
  get (applyUid, data) {
    return super.post(APPLY_VIEW_PATH + '/' + applyUid, data)
  }

  create (token, data) {
    return super.post(APPLY_CREATE_PATH + '/' + token, data)
  }

  setAnswer (applyId, formItemId, data) {
    return super.post(SET_ANSWER_PATH + '/' + applyId + '/' + formItemId, data)
  }

  setApplyParentId (applyId, data) {
    return super.post(SET_APPLY_PARENT_ID_PATH + '/' + applyId, data)
  }

  upload (applyId, formItemId, data) {
    return super.post(UPLOAD_PATH + '/' + applyId + '/' + formItemId, data)
  }

  sendApply (applyId, data) {
    return super.post(SEND_PATH + '/' + applyId, data)
  }

  getBeneficiaryTypes (eventUid) {
    return super.get('/v1/event/' + eventUid + '/beneficiary-types')
  }

  getMyIdeas (paging) {
    return super.get(GET_MY_IDEAS_PATH + '?page=' + paging.page + '&length=' + paging.length)
  }

  getMyIdeaDrafts () {
    return super.get(GET_MY_IDEA_DRAFTS_PATH)
  }

  getMyReturnedIdea () {
    return super.get(GET_MY_RETURNED_IDEA_PATH)
  }

  getMyEditableIdea () {
    return super.get(GET_MY_EDITABLE_IDEA_PATH)
  }

  deleteDraft (applyUId) {
    return this.delete(DELETE_DRAFT, applyUId)
  }

  getSingle (applyId) {
    return super.get(GET_SINGLE_PATH + '/' + applyId)
  }

  applyDetail (applyUId) {
    return super.get(APPLY_DETAIL + '/' + applyUId)
  }
}

export default new ApplicationsService()
