import { acceptHMRUpdate, defineStore } from 'pinia'
import reportService from '@/services/report.service'

export const useReportStore = defineStore({
  id: 'reportStore',
  state: () => ({
    reportData: [],
    listOfIdeas: []
  }),
  setup () {},
  actions: {
    getReport () {
      return reportService.getReport().then(
        response => {
          this.reportData = response.data.data.reports
          return response
        }
      )
    },
    getListOfIdeas (orderParam, orderByParam) {
      return reportService.getListOfIdeas(orderParam, orderByParam).then(
        response => {
          this.listOfIdeas = response.data.data.list_of_ideas
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportStore, import.meta.hot))
}
