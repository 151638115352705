<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
              <left-list></left-list>
            </div>
            <div class="col-12 col-md-9">
                <div v-for="post in applyStore.myReturnedIdea" :key="post" class="mb-5">
                  <div v-if="post.event.eventKey === 'feed_program'">
                    <wall-post :data="post" :likeBox="false" :commentBox="false"/>
                  </div>
                  <div v-else>
                    <apply-cart :apply="post" :seeApplicationButton="true"/>
                  </div>
                  <div class="row mt-1 mb-5">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center pe-0 down_sm_pb_1 down_sm_flex_center">
                      <!--
                      <button @click="onSendDraftClick(post)" type="button" class="btn btn-black font-size-base text-capitalize border-0 me-3 button_4" data-bs-toggle="modal" data-bs-target="#sendIdeaModal" style="width:138px;height:37px !important;">Gönder</button>
                      -->
                      <button @click="onEditDraftClick(post)" type="button" class="btn btn-black font-size-base text-capitalize border-0 me-3 button_4" style="width:138px;height:37px!important;">Düzenle</button>
                    </div>
                    <!--
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-end pe-0 down_sm_flex_center">
                      <button @click="onDeleteDraftClick(post)" type="button" class="btn text-capitalize font-size-base me-3 button_5" data-bs-toggle="modal" data-bs-target="#deleteDraftModal" style="width:138px;height:37px!important;">Vazgeç</button>
                    </div>
                    -->
                    <hr class="mt-2">
                  </div>
                </div>
            </div>
        </div>

        <BSModal ref="refApplyFormModal">
            <template #header>
            </template>
            <template #body>
              <apply-form ref="refApplyForm" v-if="lastInteractedPost" :applyFormUId="lastInteractedPost.apply.uid" @onApplySuccess="onApplySuccess"/>
            </template>
            <template #footer>
            </template>
        </BSModal>
        <ApplySuccessModal ref="refApplySuccessModal"/>

        <!-- Modal -->
        <div class="modal fade" id="sendIdeaModal" tabindex="-1" aria-labelledby="sendIdeaLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 rounded-30 border-0">
        <div class="modal-header border-0 p-1">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
        <p class="m-0 black fw-bold font-size-xl">Fikri göndermek istediğinize emin misiniz?</p>
        </div>
        <div class="modal-footer border-0 d-flex align-items-center justify-content-center">
        <button @click="onApproveSendClick()" type="button" class="btn btn-black text-capitalize font-size-base fw-bold border-0 me-3 button_4" data-bs-toggle="modal" data-bs-target="#sendIdeaModal" style="width:138px;height:37px!important;">Gönder</button>
        <button type="button" class="btn btn-light-gray text-capitalize font-size-base me-3 button_5" data-bs-toggle="modal" data-bs-target="#sendIdeaModal" style="width:138px;height:37px!important;" data-bs-dismiss="modal">Vazgeç</button>
        </div>
        </div>
        </div>
        </div>

        <div class="modal fade" id="deleteDraftModal" tabindex="-1" aria-labelledby="deleteDraftModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 rounded-30 border-0">
        <div class="modal-header border-0 p-1">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
        <p class="m-0 black fw-bold font-size-xl">Fikir taslağınızı silmek istediğinize emin misiniz?</p>
        </div>
        <div class="modal-footer border-0 d-flex align-items-center justify-content-center">
        <button @click="onDeleteApproveClick()" type="button" class="btn btn-black text-capitalize font-size-base fw-bold border-0 me-3 button_4" data-bs-toggle="modal" data-bs-target="#deleteDraftModal" style="width:138px;height:37px!important;">Sil</button>
        <button type="button" class="btn btn-light-gray text-capitalize font-size-base me-3 button_5" data-bs-toggle="modal" data-bs-target="#deleteDraftModal" style="width:138px;height:37px!important;" data-bs-dismiss="modal">Vazgeç</button>
        </div>
        </div>
        </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '../../store/apply'
import WallPost from '../../components/bonapply/WallPost'
import LeftList from '../../components/bonapply/LeftList.vue'
import ApplySuccessModal from '@/components/bonapply/ApplySuccessModal'
import ApplyCart from "@/components/bonapply/ApplyCart.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: 'CommonView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | İade Edilen Fikirlerim'
      applyStore.getMyReturnedIdea()
    })
    const router = useRouter()
    const applyStore = useApplyStore()
    const refApplyForm = ref(null)
    const refApplyFormModal = ref(null)
    const refApplySuccessModal = ref(null)
    const lastInteractedPost = ref(null)
    const onSendDraftClick = (post) => {
      lastInteractedPost.value = post
    }

    const onEditDraftClick = (post) => {
      lastInteractedPost.value = post
      if(lastInteractedPost.value.event.eventKey === 'feed_program'){
        refApplyFormModal.value.show()
      }else{
        router.push('/apply-form/'+lastInteractedPost.value.applyFormCache.token+'/'+lastInteractedPost.value.uid)
      }
    }

    const onDeleteDraftClick = (post) => {
      lastInteractedPost.value = post
    }

    const onDeleteApproveClick = () => {
      if(lastInteractedPost.value.event.eventKey === 'feed_program'){
        applyStore.deleteDraft(lastInteractedPost.value.apply.uid)
      }else{
        applyStore.deleteDraft(lastInteractedPost.value.uid)
      }
    }

    const onApplySuccess = () => {
      applyStore.getMyReturnedIdea()
      refApplyFormModal.value.close()
      refApplySuccessModal.value.show()
    }

    const onApproveSendClick = () => {
      refApplyFormModal.value.show()
      refApplyForm.value.sendApplyAfterLoaded()
    }

    return {
      applyStore,
      refApplyForm,
      refApplyFormModal,
      refApplySuccessModal,
      lastInteractedPost,
      onSendDraftClick,
      onEditDraftClick,
      onDeleteDraftClick,
      onDeleteApproveClick,
      onApplySuccess,
      onApproveSendClick
    }
  },
  components: {
    ApplyCart,
    WallPost,
    LeftList,
    ApplySuccessModal
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

@import 'src/styles/vakifbank-style.scss';

</style>
