<template>
    <div>
        <div class="form-check">
        <input v-model="inputValue" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label @click="onLabelClick" class="form-check-label" for="flexCheckDefault">

        </label>
        <span @click="onLabelClick" class="fw-bold" style="cursor:pointer !important;" v-html="agreement.title + '’ni okudum, anladım ve kabul ediyorum.'"></span>
        </div>

        <BSModal ref="refModal">
            <template #header>
            </template>
            <template #body>
                <div class="d-flex align-items-center justify-content-center">
                  <div v-html="agreement.content"></div>
                </div>
            </template>
            <template #footer>
            </template>
        </BSModal>
    </div>
</template>
<script>
import { defineComponent, defineExpose, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'AgreementCheck',
  props: {
    agreement: {}
  },
  disabled () { return false },
  setup (props) {
    const refModal = ref(null)
    const inputValue = ref(null)
    const onChange = (event) => {
    }

    const onLabelClick = () => {
      refModal.value.show()
    }

    const isChecked = () => {
      return inputValue.value
    }

    onMounted(() => {

    })

    defineExpose({ isChecked, inputValue })
    return {
      refModal,
      onChange,
      onLabelClick,
      inputValue
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
