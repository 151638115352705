import { acceptHMRUpdate, defineStore } from 'pinia'
import LandingService from '@/services/landing.service'

export const useLandingStore = defineStore({
  id: 'landingStore',
  state: () => ({
    landingInfo: [],
    landingHouseInfo: []
  }),
  setup () {},
  actions: {
    getLandingPageInformation (key) {
      return LandingService.getLandingPageInformation(key).then(
        response => {
          this.landingInfo = response.data
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLandingStore, import.meta.hot))
}
