import { BaseService } from './BaseService'
import { useConfigStore } from '@/store/config'

const EVENTS_PATH = '/v1/event/'

class ProgramsService extends BaseService {
  getPrograms (type = 'active', tag) {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    if (tag != null) {
      return this.get(EVENTS_PATH + pageKey + '/list/' + type + '?tag=' + tag)
    } else {
      return this.get(EVENTS_PATH + pageKey + '/list/' + type)
    }
  }
}

export default new ProgramsService()
