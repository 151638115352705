import { BaseService } from './BaseService'
import { useConfigStore } from '@/store/config'

const BLOG = '/v1/blog/'

class BlogService extends BaseService {
  getPosts (filter, paging) {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    return this.get(BLOG + pageKey + '/list' + '?page=' + paging.page + '&length=' + paging.length, filter)
  }

  getRecentPosts () {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    return this.get(BLOG + pageKey + '/recently-added-list')
  }

  getPopularPosts () {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    return this.get(BLOG + pageKey + '/popular_list')
  }

  getSingle (slug) {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    return this.get(BLOG + pageKey + '/find/' + slug)
  }
}

export default new BlogService()
