<template>
    <div class="container mb-5 down_md_pb_none" style="padding-bottom:3rem;">
        <div class="row down-md-mb mb-3 px-3">
            <div class="col-xl-9 col-lg-9 col-6 p-0">
                <p class="m-0 font-size-11 text-aubergine-color fw-bold">Blog</p>
            </div>
            <div class="col-xl-3 col-lg-3 col-6 d-flex justify-content-end">
                <a href="/blog" class="font-size-3 text-dodgerBlue-color fw-bold">Tüm Yazılar</a>
            </div>
        </div>
        <carousel v-bind="settings" :breakpoints="breakpoints" :wrap-around="false" :autoplay="1000" :navigation="true">
            <slide v-for="latestPosts in blogStore.latestPosts" :key="latestPosts">
            <div class="container px-2 h-100">
              <div class="col-12 h-100">
                <div class="bg-purple-color h-100 rounded-5">
                  <div class="position-relative">
                  <div v-for="tag in latestPosts.tags" :key="tag" class="position-absolute" style="right:15px; top:15px;">
                    <div class="px-3 bg-dodgerBlue-color text-white-color fw-bold rounded-5 font-size-3" style="width:max-content !important;">{{tag.title}}</div>
                  </div>
                  <div v-if="latestPosts.cover_img != null && latestPosts.cover_img != ''">
                    <img :src="latestPosts.cover_img" class="img-fluid w-100" style="border-top-left-radius: 30px !important;border-top-right-radius: 30px !important;">
                  </div>
                    
                  </div>
                  <div style="padding:2rem;">
                    <div class="mb-2 text-start">
                      <p class="m-0 font-size-11 text-aubergine-color fw-bold down-md-fs-4" style="line-height:1.2;">{{latestPosts.title}}</p>
                    </div>
                    <div class="mb-4 text-start">
                      <p class="m-0 font-size-5 text-aubergine-color fw-light truncate" v-html="latestPosts.content"></p>
                    </div>
                    <div class="row">
                      <div class="col-7 d-flex justify-content-start">
                        <a :href="'/blog/' + latestPosts.slug">
                          <button type="submit" class="btn btn-primary">Yazıya Git</button>
                        </a>
                      </div>
                      <div class="col-5 d-flex align-items-center justify-content-end">
                        <p class="m-0 font-size-3 text-aubergine-color fw-light">{{ latestPosts.release_date }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </slide>
            <template #addons>
              <Navigation />
            </template>
        </carousel>
    </div>
</template>
<script>
import {defineComponent, onMounted} from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { useBlogStore } from '../../store/blog'
import { useRoute } from 'vue-router'


export default defineComponent({
  data () {
    return {
      settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      },
      breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    },
    }
  },
  props: {
    section: {}
  },
  setup (props,context) {
    onMounted(() => {
      blogStore.post = null
      setTimeout(function () {
        context.emit('isReady', {})
      }, 200)
    })
    const route = useRoute()
    const blogStore = useBlogStore()
    blogStore.getLatestPosts()
    blogStore.getPosts().then((response) => {
      if (route.params.slug === undefined) {
        blogStore.post = blogStore.posts[0]
      } else {
        blogStore.getSingle(route.params.slug).then((response) => {
          analyticsStore.sendView('blog', blogStore.post.id)
        })
      }
    })
    return {
      blogStore
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  }
})
</script>
<style lang="scss" scoped>


.carousel__next{
  right: -50px !important;
}
.truncate {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5; /* Satır sayısı */
            overflow: hidden;
            text-overflow: ellipsis;
        }
</style>
