import { acceptHMRUpdate, defineStore } from 'pinia'
import feedService from '@/services/feed.service'
import { useRoute } from 'vue-router'

export const useFeedStore = defineStore({
  id: 'feedStore',
  state: () => ({
    getData: true,
    paging: {
      page: 0,
      length: 12
    },
    filter: {
      applyIds: [],
      tagIds: [],
      userIds: [],
      beneficiaryTypeIds: [],
      createDateFrom: '',
      createDateTo: '',
      orderBy: '',
      orderDirection: ''
    },
    posts: [],
    post: null,
    route: useRoute()
  }),
  setup () {},
  actions: {
    resetPost () {
      this.post = null
    },
    resetPosts () {
      this.posts = []
    },
    resetFilter () {
      this.filter.applyIds = []
      this.filter.tagIds = []
      this.filter.userIds = []
      this.beneficiaryTypeIds = []
      this.createDateFrom = ''
      this.createDateTo = ''
      this.orderBy = ''
      this.orderDirection = ''
    },
    filterControl () {
      if (Array.isArray(this.filter.createDateFrom)) this.filter.createDateFrom = ''
      if (Array.isArray(this.filter.createDateTo)) this.filter.createDateTo = ''
      if (Array.isArray(this.filter.orderBy)) this.filter.orderBy = ''
      if (Array.isArray(this.filter.orderDirection)) this.filter.orderDirection = ''
    },
    resetPaging () {
      this.getData = true
      this.paging.page = 0
    },
    feed (usePaging = false, page = null) {
      if (usePaging) {
        if (page !== null) {
          this.paging.page = page
        } else {
          this.paging.page = this.paging.page + 1
        }
        this.paging.length = 12
      } else {
        this.resetPaging()
        this.paging.page = 1
        this.paging.length = 30
      }

      if (this.getData) {
        let eventUId = 'all'
        if (this.route.params.eventUId !== undefined && this.route.params.eventUId !== null) {
          eventUId = this.route.params.eventUId
        }
        this.filterControl()
        return feedService.feed(eventUId, this.filter, this.paging).then(
          response => {
            if (response.status === 200) {
              if (usePaging) {
                this.posts = [].concat.apply(this.posts, response.data.data.posts)
                if (response.data.data.posts.length < this.paging.length) {
                  this.getData = false
                }
              } else {
                this.posts = response.data.data.posts
              }
            }
            return response
          }
        )
      } else {
        return false
      }
    },
    singleFeed () {
      const applyUId = this.route.params.applyUId
      return feedService.singleFeed(applyUId).then(
        response => {
          if (response.status === 200) {
            this.post = response.data.data.post
          }
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFeedStore, import.meta.hot))
}
