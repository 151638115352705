<template>
    <div class="container my-2">
        <div class="row">
            <div class="col-12">
                 <div v-html="kvkkStore.kvkkInfo.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useKvkkStore } from '@/store/kvkk'
import { useCookiesPolicyStore } from '@/store/cookies_policy'

export default defineComponent({
  setup () {
    const kvkkStore = useKvkkStore()
    const cookiesPolicyStore = useCookiesPolicyStore()
    kvkkStore.getKvkkInfo()
    kvkkStore.getCookieInfo()
    cookiesPolicyStore.getCookiesPolicyInfo()
    return {
      kvkkStore,
      cookiesPolicyStore
    }
  }
})
</script>
