<template v-if="landingStore.landingInfo.lenght !== 0">

    <div v-if="landingStore.landingInfo.data">
      <div class="" v-for="section in landingStore.landingInfo.data.content.sections" :key="section">
          <div v-if="step > 0 && section.sectionId === 'slider'">
            <slider-section :section="section" @isReady="isReady"></slider-section>
          </div>
          <div v-if="step > 1 && section.sectionId === 'aboutUs'">
              <about-us :section="section" :landingInfo="landingStore.landingInfo" @isReady="isReady"></about-us>
          </div>
          <div v-if="step > 2 && section.sectionId === 'programs'">
            <programs-cart :section="section" @isReady="isReady"></programs-cart>
          </div>
          <div v-if="step > 3 && section.sectionId === 'featuredPrograms'">
            <highlights-cart :section="section" @isReady="isReady"></highlights-cart>
          </div>
          <div v-if="step > 4 && section.sectionId === 'blog'">
            <landing-blog :section="section" @isReady="isReady"></landing-blog>
          </div>
      </div>
    </div>

    <sss-view v-if="step > 5"></sss-view>
    <contact-view v-if="step > 5" id="contact"></contact-view>

  <landing-footer v-if="step > 5"></landing-footer>
</template>
<script>
import {defineComponent, onMounted, ref} from 'vue'
import LandingBlog from '../../components/landing/LandingBlog.vue'
import ProgramsCart from '../../components/landing/ProgramsCart.vue'
import SssView from '../../components/landing/SssView.vue'
import InovationView from '../../components/landing/InovationProcess.vue'
import ContactView from '../../components/landing/ContactView.vue'
import HighlightsCart from '../../components/landing/HighlightsCart.vue'
import LandingFooter from '../../components/landing/LandingFooter.vue'
import SliderSection from '../../components/landing/SliderSection.vue'
import AboutUs from '../../components/landing/AboutUs.vue'
import { useLandingStore } from '../../store/landing'
import { useConfigStore } from '../../store/config'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()
    const configStore = useConfigStore()
    const landingStore = useLandingStore()
    const step = ref(1)
    const isReady = () => {
      step.value++;
    }
    onMounted(() => {
      if (window.location.hostname === configStore.data.VUE_APP_INTERNAL_DOMAIN) {
        landingStore.getLandingPageInformation('in_house_user_landing_page')
      } else {
        landingStore.getLandingPageInformation('external_user_landing_page')
      }
    })
    return {
      landingStore,
      route,
      step,
      isReady
    }
  },
  components: {
    SliderSection,
    AboutUs,
    LandingBlog,
    ProgramsCart,
    HighlightsCart,
    LandingFooter,
    SssView,
    InovationView,
    ContactView
  }
})
</script>
<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
*{
    font-family: 'Roboto', sans-serif !important;
}
</style>
