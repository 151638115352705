<template>
    <div class="container">
        <div class="row mb-3">
            <div v-if="leftMenuStatus" class="col-12 col-md-3">
              <div v-if="newIdeaButtonStatus">
                <new-idea/>
              </div>
              <div class="mt-2">
                <div v-if="authStore.isLoggedIn" class="nav d-block nav-pills me-3 min-width mb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <div class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0" style="font-weight: bold">Program</div>
                  <router-link :to="{ name: 'my-leaderboard-for-event', params : { eventUId:route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0">Liderlik Tablosu</router-link>
                  <router-link :to="{ name: 'populars-for-event', params : { eventUId:route.params.eventUId, eventFormToken: route.params.eventFormToken } }" class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0">En Popüler</router-link>
                </div>
                <div v-if="!authStore.isLoggedIn" class="nav d-block nav-pills me-3 min-width mb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <div class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0" style="font-weight: bold">Program</div>
                  <div @click="toast.error('Liderlik tablosunu görebilmek için giriş yapmalısınız.')" class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0 cursor-pointer">Liderlik Tablosu</div>
                  <div @click="toast.error('En Popüler listesini görebilmek için giriş yapmalısınız.')" class="nav-link nav-link-2 text-start font-size-lg down_md_pl_0 ps-0 cursor-pointer">En Popüler</div>
                </div>
              </div>
            </div>
            <div class="col-12" :class="leftMenuStatus ? 'col-md-8' : 'col-md-12'">
              <div v-if="leftMenuStatus" class="row w-100">
                <div class="col-12 d-flex align-items-center">
                  <h5 class="m-0">{{ programStore.active_event_title }}</h5>
                </div>
                <div v-if="programStore.active_event_cover_subject !== '' && programStore.active_event_cover_subject !== '<p><br></p>'">
                  <div class="font-size-base black m-0 html-content" v-html="programStore.active_event_cover_subject"></div>
                  <p v-if="programStore.active_event_subject !== '' && programStore.active_event_subject !== '<p><br></p>'" class="font-size-base black m-0">
                    <span v-if="readMoreSubject"></span>
                    <span v-else>...</span>
                  </p>
                  <div v-if="programStore.active_event_subject !== '' && programStore.active_event_subject !== '<p><br></p>'" class="font-size-base black m-0" v-show="readMoreSubject" v-html="programStore.active_event_subject"></div>
                  <div>
                    <button v-if="programStore.active_event_subject !== '' && programStore.active_event_subject !== '<p><br></p>'" class="btn border-0 black p-0 mb-3" @click="readMoreSubject =! readMoreSubject">
                      <span v-if="readMoreSubject">Daha Az Göster <svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></span>
                      <span v-else>Daha Fazla Göster<svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="programStore.active_event_award_cover_description !== ''" class="d-flex align-items-baseline">
                <div class="me-3">
                   <img src="@/assets/bonapply/LeadImages/cup.png" alt="cup image" class="img-fluid pt-3" style="min-width:55px !important;width:55px!important;"/>
                </div>
                <div class="text-start">
                    <p v-if="programStore.active_event_award_title !== ''" class="fw-bold black font-size-base m-0">{{programStore.active_event_award_title}}</p>
                    <div class="font-size-base black m-0" v-html="programStore.active_event_award_cover_description"></div>
                    <p v-if="programStore.active_event_award_description !== ''" class="font-size-base black m-0">
                      <span v-if="readMoreAward"></span>
                      <span v-else>...</span>
                    </p>
                    <div v-if="programStore.active_event_award_description !== ''" class="font-size-base black m-0" v-show="readMoreAward" v-html="programStore.active_event_award_description"></div>
                    <button v-if="programStore.active_event_award_description !== ''" class="btn border-0 black p-0" @click="readMoreAward =! readMoreAward">
                        <span v-if="readMoreAward">Daha Az Göster <svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></span>
                        <span v-else>Daha Fazla Göster<svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></span>
                    </button>
                </div>
                </div>
                <div class="mb-4">
                  <wall-header :eventUid="route.params.eventUId"/>
                </div>
                <div v-if="isLoading">
                  Yükleniyor...
                </div>
                <div v-if="feedStore.posts.length === 0 && !isLoading">
                </div>
                <div v-if="feedStore.posts.length > 0">
                  <div v-for="post in feedStore.posts" :key="post">
                    <wall-post :data="post" layout="wall"/>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useFeedStore } from '../../store/feed'
import { useProgramStore } from '../../store/program'
import WallPost from '../../components/bonapply/WallPost'
import WallHeader from '../../components/bonapply/WallHeader'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'WallView',
  data () {
    return {
      readMoreSubject: false,
      readMoreAward: false
    }
  },
  setup (props) {
    onMounted(() => {
      if (route.name !== 'search-result') {
        feedStore.resetPaging()
      }
      feedStore.resetPosts()
      document.title = 'Yaşar IDEA | Wall'
      Object.keys(route.query).forEach(filterKey => {
        const values = route.query[filterKey].split(',')
        feedStore.filter[filterKey] = values
      })

      const listElm = document.querySelector('html')
      window.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (keepScrolling.value) {
            keepScrolling.value = false
            setTimeout(function () {
              keepScrolling.value = true
            }, 500)
            getFeed(true, null)
          }
        }
      })
      if (route.name !== 'search-result') {
        getFeed(true, 1)
      }
    })
    const getFeed = (starting = false, page) => {
      if (feedStore.getData) {
        isLoading.value = true
        feedStore.feed(true, page).then(
          response => {
            if (response.status !== 200) {
              const redirectToUrl = window.location.pathname
              localStorage.setItem('redirectToUrl', redirectToUrl)
              toast.error('Bu sayfaya erişebilmek için giriş yapmalısınız')
              authStore.logout()
            } else {
              if (starting) {
                if (response.data.data.event.title !== '') {
                  leftMenuStatus.value = true
                  newIdeaButtonStatus.value = !!(response.data.data.event.status)
                  programStore.setActiveEventTitle(response.data.data.event.title)
                  programStore.setActiveEventCoverSubject(response.data.data.event.cover_subject)
                  programStore.setActiveEventSubject(response.data.data.event.subject)
                  programStore.setActiveEventAwardDescriptionTitle(response.data.data.event.award_description_title)
                  programStore.setActiveEventAwardCoverDescription(response.data.data.event.award_cover_description)
                  programStore.setActiveEventAwardDescription(response.data.data.event.award_description)
                  document.title = 'Yaşar IDEA | ' + response.data.data.event.title
                }
              }
              isLoading.value = false
            }
          }
        )
      }
    }
    const authStore = useAuthStore()
    const feedStore = useFeedStore()
    const programStore = useProgramStore()
    const route = useRoute()
    const isLoading = ref(false)
    const leftMenuStatus = ref(false)
    const newIdeaButtonStatus = ref(false)
    const keepScrolling = ref(true)
    const toast = useToast()
    return {
      authStore,
      feedStore,
      programStore,
      isLoading,
      leftMenuStatus,
      newIdeaButtonStatus,
      route,
      toast
    }
  },
  components: {
    WallPost,
    WallHeader
  },
  methods: {

  }
})
</script>
<style>
.html-content p{
  margin-bottom: 0px !important;
}
</style>
