<template>
     <div class="container">
      <div class="row mb-4">
          <div class="rounded-5 bg-purple-color py-xl-3 py-lg-3 py-4 down-xl-pl-pr" style="padding-right:6rem;padding-left:6rem;">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-12 d-flex align-items-center down-md-text-center down-md-mb">
                <p class="m-0 font-size-15 text-blueDark-color">Topluluk çalışanlarımızı <strong>Kurum İçi Girişimcilik Programı’na başvurmaya davet ediyoruz.</strong></p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center justify-content-center ps-0 text-center">
                <div>
                <div class="mb-3">
                  <a href="/book.pdf" target="_blank" type="btn" class="btn btn-primary py-3" style="width:210px;border-radius:30px !important;">
                    <div class="bg-book mb-1"></div>Program Kitapçığı
                  </a>
                </div>
                <div>
                  <button v-if="!authStore.isLoggedIn" @click="goToLogin()" style="width:185px !important;" class="btn btn-primary" type="submit">Şimdi Başvur!</button>
                  <a v-if="authStore.isLoggedIn" href="/programs" style="width:185px !important;" class="btn btn-primary" type="submit">Şimdi Başvur!</a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="down-md-pb-none" style="padding-bottom:3rem;">
        <div class="row mb-5 position-relative">
        <div class="col-12 text-center pb-4 pt-2">
          <div>
            <p class="m-0 text-aubergine-color font-size-11 fw-bold down-md-fs-5">Süreç Nasıl İlerler?</p>
          </div>
        </div>
        <div class="down-sm-block align-items-center justify-content-between bg-purple-color rounded-5 position-relative mb-xl-5 mb-lg-5 mb-4 down-lg-pr-pl down-md-pl-pr-2" style="display:flex;padding:48px;">
          <div class="col-12 position-absolute px-4 down-mb down-md-d-none down-lg-height" style="width:-webkit-fill-available!important;padding-right:7rem !important;top:39%;">
          <div class="line_box">
            <div class="line"></div>
          </div>
        </div>
        <div class="down-sm-mb">
            <div class="bg-purple-color d-flex align-items-center justify-content-center">
              <div class="bg-purple-color rounded-circle d-flex align-items-center justify-content-center inovation_circle" style="z-index:9!important;">
              <img src="@/assets/LeadImages/img-12.svg" alt="Inovation Image" height="51">
            </div>
            </div>
            <div class="text-center mt-2">
              <p class="m-0 font-size-6 fw-700 text-aubergine-color rounded-circle-text fw-bold down-md2-font5 down-md-fs6 down-sm-fs-3">Başvuru<br> Dönemi</p>
              <p class="m-0 font-size-6 fw-normal text-aubergine-color fw-light down-md2-font5 down-md-fs6 down-sm-fs-3">7 Mayıs - 7 Haziran 2024</p>
            </div>
        </div>
        <div class="down-sm-mb">
          <div class="bg-purple-color d-flex align-items-center justify-content-center">
            <div class="bg-purple-color rounded-circle d-flex align-items-center justify-content-center inovation_circle" style="z-index:9!important;">
            <img src="@/assets/LeadImages/img-13.svg" alt="Inovation Image" height="51">
          </div>
          </div>
          <div class="text-center mt-2">
            <p class="m-0 font-size-6 fw-700 text-aubergine-color rounded-circle-text fw-bold down-md2-font5 down-md-fs6 down-sm-fs-3">Değerlendirme<br> Dönemi</p>
            <p class="m-0 font-size-6 fw-normal text-aubergine-color fw-light down-md2-font5 down-md-fs6 down-sm-fs-3">Haziran - Temmuz 2024</p>
          </div>
        </div>
        <div class="down-sm-mb">
          <div class="bg-purple-color d-flex align-items-center justify-content-center">
            <div class="bg-purple-color rounded-circle d-flex align-items-center justify-content-center inovation_circle" style="z-index:9!important;">
            <img src="@/assets/LeadImages/img-14.svg" alt="Inovation Image" height="51">
          </div>
          </div>
          <div class="text-center mt-2">
            <p class="m-0 font-size-6 fw-700 text-aubergine-color rounded-circle-text fw-bold down-md2-font5 down-md-fs6 down-sm-fs-3">Takımlaşma ve <br>Hazırlık Dönemi</p>
            <p class="m-0 font-size-6 fw-normal text-aubergine-color fw-light down-md2-font5 down-md-fs6 down-sm-fs-3">Temmuz - Ağustos 2024</p>
          </div>
        </div>
        <div class="down-sm-mb">
          <div class="bg-purple-color d-flex align-items-center justify-content-center">
            <div class="bg-purple-color rounded-circle d-flex align-items-center justify-content-center inovation_circle" style="z-index:9!important;">
            <img src="@/assets/LeadImages/img-15.svg" alt="Inovation Image" height="51">
          </div>
          </div>
          <div class="text-center mt-2">
            <p class="m-0 font-size-6 fw-700 text-aubergine-color rounded-circle-text fw-bold down-md2-font5 down-md-fs6 down-sm-fs-3">Hızlandırma<br> Dönemi</p>
            <p class="m-0 font-size-6 fw-normal text-aubergine-color fw-light down-md2-font5 down-md-fs6 down-sm-fs-3">Eylül - Kasım 2024</p>
          </div>
        </div>
        <div class="down-sm-mb">
          <div class="bg-purple-color d-flex align-items-center justify-content-center">
            <div class="bg-purple-color rounded-circle d-flex align-items-center justify-content-center inovation_circle" style="z-index:9!important;">
            <img src="@/assets/LeadImages/img-16.svg" alt="Inovation Image" height="51">
          </div>
          </div>
          <div class="text-center mt-2">
            <p class="m-0 font-size-6 fw-700 text-aubergine-color rounded-circle-text fw-bold down-md2-font5 down-md-fs6 down-sm-fs-3">MVP ve Pilot<br> Uygulama Dönemi</p>
            <p class="m-0 font-size-6 fw-normal text-aubergine-color fw-light down-md2-font5 down-md-fs6 down-sm-fs-3">2025</p>
          </div>
        </div>
        </div>
        <div class="col-12 text-center">
          <button v-if="!authStore.isLoggedIn" @click="goToLogin()" type="btn" class="btn btn-primary btn-lg">Hemen Başvur</button>
          <a v-if="authStore.isLoggedIn" href="/programs" type="btn" class="btn btn-primary btn-lg">Hemen Başvur</a>
        </div>
        </div>
        <div class="row mb-xl-4 mb-lg-4 mb-2">
          <div class="col-12 text-center">
            <p class="m-0 text-blueDark-color font-size-11 fw-bold down-md-fs-5">Neden Yaşar İDEA Kurum İçi Girişimcilik Programı?</p>
          </div>
        </div>
        <div class="row mb-xl-5 mb-lg-5 mb-1">
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">ŞİRKET KAYNAKLARINI KULLANARAK GİRİŞİMCİLİĞİ DENEYİMLEYİN</p>
             <img src="@/assets/LeadImages/circle1.png" height="109" style="display:none">
            </div>
          </div>
           <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Girişimcilik ve inovasyon alanında KAPSAMLI EĞİTİMLERLE KENDİNİZİ GELİŞTİRİN</p>
             <img src="@/assets/LeadImages/circle2.png" height="109" style="display:none">
            </div>
          </div>
           <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Yaratıcı bakış açısı ve tasarım odaklı düşünme gibi iş hayatında kullanabileceğiniz BECERİ SETLERİ KAZANIN</p>
             <img src="@/assets/LeadImages/circle3.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Programdaki ilerlemenize paralel olarak TEŞVİK VE ÖDÜLLERDEN FAYDALANIN</p>
             <img src="@/assets/LeadImages/circle4.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">YENİLİKÇİ ÇALIŞMA YÖNTEMLERİNİ TECRÜBE EDİN</p>
             <img src="@/assets/LeadImages/circle1.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">İş fikrinizi farklı Topluluk şirketlerinden oluşan MULTİDİSİPLİNER BİR TAKIMLA GELİŞTİRİN</p>
             <img src="@/assets/LeadImages/circle2.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Saha çalışmaları ile müşteriyle buluşarak PAZARLAMA İÇGÖRÜNÜZÜ GELİŞTİRİN</p>
             <img src="@/assets/LeadImages/circle3.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">TOPLULUĞUN ÜST YÖNETİMİ İLE TANIŞIP, DENEYİM VE EKSPERTİZLERİNDEN FAYDALANMA FIRSATI YAKALAYIN
</p>
             <img src="@/assets/LeadImages/circle4.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">İŞ GELİŞTİRME MENTORLUĞU İLE İŞ MODELİNİZİ topluluğun inovasyon stratejisine uygun bir şekilde TASARLAYIN</p>
             <img src="@/assets/LeadImages/circle1.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Hukuk, muhasebe ve teknik alanlarda GENİŞ UZMAN AĞININ UÇTAN UCA DESTEĞİNİ ALIN </p>
             <img src="@/assets/LeadImages/circle2.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">İNOVASYON ELÇİSİ OLARAK TOPLULUKTA VE GİRİŞİMCİLİK EKOSİSTEMİNDE KENDİNİZİ GÖSTERİN</p>
             <img src="@/assets/LeadImages/circle3.png" height="109" style="display:none">
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6 col-xs-6 col-12 p-1">
            <div class="explain_box bg-dodgerBlue-color rounded-5 d-flex text-center align-items-center justify-content-center down-xl-pl-pr mb-xl-3 mb-lg-3 mb-1" style="padding-right:3rem;padding-left:3rem;height:270px;">
             <p class="m-0 text-white-color font-size-6 fw-bold down-xl-fs-2 down-sm-fs-3">Mevcut işlerinizin dışında EŞSİZ BİR DENEYİM ELDE ETME FIRSATINI DEĞERLENDİRİN</p>
             <img src="@/assets/LeadImages/circle4.png" height="109" style="display:none">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useToast } from 'vue-toastification'
import Button from "@/components/Cookie/Shared/Button.vue";

export default defineComponent({
  name: 'LoginView',
  components: {Button},
  data () { return {} },
  setup () {
    const authStore = useAuthStore()
    const toast = useToast()
    const goToLogin = () => {
      localStorage.setItem('redirectToUrl', '/programs')
      toast.error('Bu sayfaya erişebilmek için giriş yapmalısınız')
      authStore.logout()
    }
    return {
      authStore,
      goToLogin
    }
  }
})
</script>
<style scoped>
.bg-book{
  background-image: url(@/assets/LeadImages/book1.png);
  background-size: cover;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px !important;
}
</style>
