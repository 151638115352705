import { BaseService } from './BaseService'
const REQUEST_TO_JOIN = '/v1/apply-partner/send-request'
const MY_ACTIVE_PARTNERSHIPS_TO_OTHERS = '/v1/apply-partner/my-partners'
const PARTNERSHIP_REQUESTS_TO_ME = '/v1/apply-partner/incoming-requests'
const REPLY_REQUEST = '/v1/apply-partner/reply-request'

class CollaborateService extends BaseService {
  requestToJoin (applyId) {
    const data = {
      apply_id: applyId,
      process: 'join'
    }
    return this.post(REQUEST_TO_JOIN, data)
  }

  leaveRequestToJoin (applyId) {
    const data = {
      apply_id: applyId,
      process: 'leave'
    }
    return this.post(REQUEST_TO_JOIN, data)
  }

  getMyCollaborationsToOtherIdeas () {
    return this.post(MY_ACTIVE_PARTNERSHIPS_TO_OTHERS)
  }

  getPartnershipRequestsToMe () {
    return this.post(PARTNERSHIP_REQUESTS_TO_ME)
  }

  replyRequest (id, process) {
    const data = {
      apply_partner_id: id,
      process: process
    }

    return this.post(REPLY_REQUEST, data)
  }
}

export default new CollaborateService()
