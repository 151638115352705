<template>
  <div class="container">
    <div class="row mb-5 up-md-padding-2">
      <div class="col-12">
          <p class="m-0 text-midnightBlue-color font-size-11 fw-bold">Blog</p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
          <div class="bg-purple-color up-md-padding-1 rounded-5 down-md-padding mb-5">
          <div v-if="blogStore.post">
                          <div class="row">
                            <div class="col-12 mb-3">
                              <h4 class="m-0 text-midnightBlue-color font-size-14 fw-bold down-md-fs-4">{{ blogStore.post.title }}</h4>
                            </div>
                            <div class="col-12 mb-5" v-if="blogStore.post.coverImg != null && blogStore.post.coverImg != '' && blogStore.post.videoPath == ''">
                              <img :src="blogStore.post.coverImg" alt="blog image" class="img-fluid content-image mb-5"/>
                              <p class="card-text black font-size-6 fw-normal" v-html="blogStore.post.content"></p>
                              <div class="col-12 mt-3">
                              <p class="m-0 text-lightGray-color font-size-5 fw-bold down-md-fs-1">{{ blogStore.post.releaseDate }}</p>
                            </div>
                            </div>
                          </div>
                        </div>
          </div>
      </div>
    </div>
    <ul class="nav nav-tabs border-0 w-100 d-flex justify-content-center mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button @click="blogStore.getLatestPosts()" v-if="blogStore.posts" class="nav-link active font-size-6 down-xs-font-size-1 px-0 text-blue-color me-3" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">En Son Eklenenler</button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="blogStore.getPopularPosts()" v-if="blogStore.popular_posts" class="nav-link font-size-6 me-0 down-xs-font-size-1 px-0 text-blue-color" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">En Çok Okunanlar</button>
            </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="row up-md-padding-1">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3" v-for="latestPosts in blogStore.latestPosts" :key="latestPosts">
                    <a :href="'/blog/' + latestPosts.slug" class="text-decoration-none">
                    <div class="bg-purple-color rounded-5 p-4">
                      <div class="row">
                        <div class="col-12 mb-2" v-if="latestPosts.cover_img != null && latestPosts.cover_img != ''">
                          <img :src="latestPosts.cover_img" class="img-fluid" alt="Blog Image"/>
                        </div>
                        <div class="col-12 mb-3">
                          <p class="m-0 text-lightGray-color font-size-5 fw-semibold">
                            {{ latestPosts.release_date }}
                          </p>
                        </div>
                        <div class="col-12">
                          <div class="card-body p-0">
                            <div style="height:100px;">
                              <p class="m-0 text-midnightBlue-color fw-bold font-size-7 line-overflow down-md-fs-2">{{ latestPosts.title }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade text-blue-color fw-700" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div class="row up-md-padding-1">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3" v-for="popular_posts in blogStore.popular_posts" :key="popular_posts">
                    <a :href="'/blog/' + popular_posts.slug" class="text-decoration-none">
                    <div class="bg-purple-color rounded-5 p-4">
                      <div class="row">
                        <div class="col-12 mb-2" v-if="popular_posts.coverImg != null && popular_posts.coverImg != ''">
                          <img :src="popular_posts.coverImg" class="img-fluid" alt="Blog Image"/>
                        </div>
                        <div class="col-12 mb-3">
                          <p class="m-0 text-lightGray-color font-size-5 fw-semibold">
                            {{ popular_posts.releaseDate }}
                          </p>
                        </div>
                        <div class="col-12">
                          <div class="card-body p-0">
                            <div style="height:100px;">
                              <p class="m-0 text-midnightBlue-color fw-bold font-size-7 line-overflow down-md-fs-2">{{ popular_posts.title }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useBlogStore } from '../../store/blog'
import { useRoute } from 'vue-router'
import BlogCard from '../../components/bonapply/BlogCard.vue'
import BlogSearch from '@/components/bonapply/BlogSearch'
import PopularBlogSearch from '@/components/bonapply/PopularBlogSearch'
import { useAnalyticsStore } from '@/store/analytics'
export default defineComponent({
  name: 'BlogDetailView',
  data () {
    return {}
  },
  setup () {
    onMounted(() => {
      blogStore.post = null
      document.title = 'Yaşar IDEA | Blog'
      // refModal.value.show()
    })
    const analyticsStore = useAnalyticsStore()
    const onNewButtonClick2 = () => {
      refModal.value.show()
    }
    const onNewButtonClick3 = () => {
      refModal.value.show()
    }
    const refModal = ref(null)
    const route = useRoute()
    const blogStore = useBlogStore()
    blogStore.getPosts().then((response) => {
      if (route.params.slug === undefined) {
        blogStore.post = blogStore.posts[0]
      } else {
        blogStore.getSingle(route.params.slug).then((response) => {
          analyticsStore.sendView('blog', blogStore.post.id)
        })
      }
    })
    blogStore.getPopularPosts().then((response) => {})

    blogStore.getLatestPosts().then((response) => {})

    return {
      blogStore,
      refModal,
      onNewButtonClick2,
      onNewButtonClick3,
      analyticsStore
    }
  },
  components: {
    BlogCard,
    BlogSearch,
    PopularBlogSearch
  }
})
</script>
<style lang="scss" scoped>
.content-image {
  float: left;
  margin: 0 15px 15px 0;
}
/* Tabs Menu */
.nav-tabs {
  .nav-link {
    color: $blue !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    font-size: 18px !important;

    &.active {
      border: none !important;
      border-bottom: solid 2px $green !important;
    }

    &:hover {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
/* End */
</style>
