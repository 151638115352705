<script setup>
import { computed } from '@vue/reactivity';

const props = defineProps(["button"])

const isDeclineActive = computed(() => {
	return props.button.active !== false
})

</script>

<template>
	<div>
		<button v-if="isDeclineActive" class="cookie--button" @click="props.button.handler"> {{props.button.text}} </button>
	</div>
</template>

<style lang="scss" scoped>
	.cookie--button{
			background-color: #C1E600;
			color: #383E42;
			padding: 10px;
			border-radius: 30px;
			margin: 10px;
			border: none;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			&:hover {
				background-color: #007FFF !important;
				color: #ffffff !important;
			}
		}

		@media screen and (max-width: 800px) {
			.cookie--button{
				padding: 8px;
				font-size: 9px;
			}
		}

</style>
