import { acceptHMRUpdate, defineStore } from 'pinia'
import ApplicationsService from '@/services/applications.service'

export const useApplicationStore = defineStore({
  id: 'applicationStore',
  state: () => ({
    events: []
  }),
  setup () {

  },
  actions: {
    getApplications () {
      return ApplicationsService.getApplications().then(
        response => {
          if (undefined !== response.data) this.events = response.data.data.events
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApplicationStore, import.meta.hot))
}
