import { acceptHMRUpdate, defineStore } from 'pinia'
import faqService from '@/services/faq.service'

export const useFaqStore = defineStore({
  id: 'faqStore',
  state: () => ({
    faqInfo: []
  }),
  setup () {},
  actions: {
    getFaqInfo () {
      return faqService.getFaqInfo().then(
        response => {
          this.faqInfo = response.data.data.faq
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFaqStore, import.meta.hot))
}
