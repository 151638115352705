<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
              <left-list></left-list>
            </div>
            <div v-if="collaborateStore.myCollaborationsToOtherIdeas" class="col-12 col-md-9">
                <h5 v-if="collaborateStore.myCollaborationsToOtherIdeas && collaborateStore.myCollaborationsToOtherIdeas.length==0">Henüz aktif bir ortaklığınız bulunmuyor</h5>
                <div v-for="post in collaborateStore.myCollaborationsToOtherIdeas" :key="post">
                  <wall-post :data="post" :likeBox="true" :commentBox="true" layout="my-collaborate-ideas"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useCollaborateStore } from '../../store/collaborate'
import WallPost from '../../components/bonapply/WallPost'
import LeftList from '../../components/bonapply/LeftList.vue'

export default defineComponent({
  name: 'MyCollaborationsToOtherIdeasView',
  data () { return {} },
  setup () {
    onMounted(() => {
      collaborateStore.getMyCollaborationsToOtherIdeas()
    })
    const collaborateStore = useCollaborateStore()
    return {
      collaborateStore
    }
  },
  components: {
    WallPost,
    LeftList
  },
  methods: {

  }
})
</script>
