<template>
    <div class="blog-search">
        <form class="d-flex position-relative mb-4 align-items-center">
            <input class="form-control me-2 border-top-0 border-end-0 border-start-0 position-relative"
            type="search" placeholder="Ara" aria-label="Ara"
            v-model="inputValue" @keyup="onKeyUp" @click="onInputClick"/>
            <i class="fa-solid fa-magnifying-glass position-absolute end-0"></i>
        </form>
        <div class="down_md_none" style="max-height: 650px; overflow-y: scroll" v-if="blogStore.popular_posts">
            <div v-if="blogStore.popular_posts.length === 0">
            Blog yazısı bulunamadı
            </div>
            <div class="me-3 d-block down_xl_height down_xl_mr_0" v-for="post in blogStore.popular_posts" :key="post">
            <a :href="'/blog/' + post.slug" class="nav-link text-start px-0 font-size-lg border-bottom fw-bold
                active
                w-100
                "
            >
                {{ post.title }}
                <div class="mt-1">
                <i class="bi bi-arrow-right font-size-2xl"></i>
                </div>
            </a>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useBlogStore } from '@/store/blog'

export default defineComponent({
  setup () {
    const blogStore = useBlogStore()
    const inputValue = ref(null)

    const onKeyUp = (event) => {
      const search = event.target.value
      blogStore.getPopularPosts({ search })
    }

    return {
      blogStore,
      onKeyUp,
      inputValue
    }
  }
})
</script>
