<template>
<div class="container">
   <div class="row my-5">
        <div class="col-12 text-start mb-3">
            <p style="font-size:16px;" class="fw-bold">KULLANIM KOŞULLARI</p>
        </div>
        <div class="col-12">
            1. KULLANICI,
            Yaşar Holding’e ait Yaşar İDEA web sitesinin ("Sitemiz") kullanımına ilişkin olarak
            aşağıda belirtilen Kullanım Koşullarını benimsediğini ve bu koşullara uygun hareket edeceğini
            kabul beyan ve tahhüt eder. Bu internet sitesine girmeniz veya bu internet sitesindeki
            herhangi bir bilgiyi kullanmanız aşağıdaki koşulları kabul ettiğiniz anlamına gelir.<br><br>
            2. KULLANICI , işbu Sözleşme’ye taraf olmak için gerekli hukuki ehliyete ve her türlü yetkiye sahip
            olduğunu ve siteyi kullandığı sürece de sahip olmaya devam edeceğini beyan ve taahhüt eder.<br><br>
            3. KULLANICI, doğru, eksiksiz ve güncel kayıt bilgilerini vermek zorundadır. Aksi halde bu
            Sözleşme ihlal edilmiş sayılacak ve Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir.<br><br>
            4. KULLANICI, kullanıcı adı veya kayıtlı e-posta adresiyle yapacağı her türlü işlemden bizzat
            kendisinin sorumlu olduğunu ve Yaşar İDEA web sitesinde oluşturulmuş her bir Kullanıcı adı
            için yalnızca bir adet e-posta
            tanımlanabileceğini, tanımlanan e-posta adresinin
            değiştirilemeyeceğini, yeni bir e-posta adresinin ancak Sitemiz üzerinden yeni bir kullanıcı
            oluşturulması halinde tanımlanılabileceğini kabul eder.<br><br>
            5. Üyelik sistemi kullanılması durumunda, Sistem üzerinden doğrulanan telefon numarası
            ve/veya e-posta adresi ile sisteme giriş yapılacaktır. Kullanıcılar’a kimliklerini doğrulamaları
            için sistemde kayıtlı telefon numaralarına/e-posta adreslerine mesajı gönderilecektir.
            Kullanıcılar Bu mesajı onaylayarak sisteme giriş sağlayacaktır.Sistemde kayıtlı telefon numarası
            /e-posta adresinin doğruluğu ve başka kişilerin kullanımına geçmemesi tamamıyla kullanıcının
            kendi sorumluluğundadır ve Yaşar Holding telefon numarasının - e posta adreslerinin ve/veya
            onay mesajlarının üçüncü Kişiler tarafından kullanılması/ele geçirilmesi vb. Nedenlerle
            doğacak sorunlardan ve/veya oluşabilecek zararlardan sorumlu değildir.<br><br>
            6. Sitemize girilen bilgilerin ve işlemlerin güvenliği için gerekli önlemler, bilgi ve işlemin
            mahiyetine göre Yaşar Holding ve/veya Yaşar Topluluğu şirketlerine ait sistem ve internet
            altyapısında alınmıştır.<br><br>
            7. Kişisel bilgilere sadece yetkili Yaşar Holding ve/veya Yaşar Topluluğu Şirket personeli ve
            bilgileri gizli tutmayı kabul etmiş olan temsilcilerimiz ile tarafımızdan bu bilgilerin gizlilik kaydı
            ile paylaşıldığı ilgili iş ortaklarımız erişebilirler. Ancak Yaşar Holding’in kusuru olmaksızın bu
            bilgilere üçüncü kişilerin erişimi nedeniyle doğacak zararlardan Yaşar Holding sorumlu
            olmaz.Müşterilerimizin Sitemize üyelik, ürün satın alma ve bilgi güncelleme amaçlı girdikleri
            bilgiler işbu maddede belirtilenler dışında kalan diğer internet kullanıcıları tarafından
            görüntülenemez.<br><br>
            8. Yaşar Holding’e ait sitelerdeki bağlantılar aracılığıyla gideceğiniz diğer sitelerin, Yaşar Holding
            ve/veya Yaşar Topluluğu Şirketleri'nin Gizlilik İlkeleri'ne uyacağını garanti etmez, bu nedenle diğer sitelerde kişisel olarak belirlenebilir herhangi bir bilgi vermeden önce gittiğiniz sitelerin
            gizlilik yaklaşımlarını değerlendirmenizi öneririz.<br><br>
            9. Sitemizin diğer internet web sitelerine bağlantı verdiği hallerde tüm kullanım ve işlemler için o
            sitelere ait gizlilik, güvenlik politikası ve kullanım şartları geçerlidir; Sitemizden reklam, banner,
            içerik görmek veya başka herhangi bir amaç ile ulaşılan diğer web sitelerinden bilgi
            kullanımları, keza sitelerin etik ilkeleri, gizlilik-güvenlik prensipleri, servis kalitesi ve diğer
            uygulamaları sebebi ile oluşabilecek ihtilaf, maddi-manevi zarar ve kayıplardan Yaşar Holding
            sorumlu değildir.<br><br>
            10. Sitemizde yer alan her türlü ticari ünvan, işletme adı, marka, patent, logo, tasarım, bilgi, içerik
            ile bunların düzenlenmesi ve kısmen/tamamen kullanımı konusunda Yaşar Holding ile
            anlaşmasına göre üçüncü şahıslara ait olanlar hariç tüm fikri-sınai haklar ve mülkiyet hakları
            Yaşar Holding ve/veya YAŞAR TOPLULUĞU şirketlerine aittir. İşbu Site’nin ziyaret edilmesi veya
            bu Site’deki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları konusunda
            Kullanıcıya hiçbir hak vermez. Sitemizde bulunan hiçbir materyal; önceden izin alınmadan ve
            kaynak gösterilmeden, kod ve yazılım da dahil olmak üzere, değiştirilemez, kopyalanamaz,
            çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, başka bir bilgisayara
            yüklenemez, postalanamaz, iletilemez, sunulamaz ya da dağıtılamaz. İnternet sitesinin bütünü
            veya bir kısmı başka bir internet sitesinde izinsiz olarak kullanılamaz. Aksine hareketler hukuki
            ve cezai sorumluluğu gerektirir. Yaşar Holding’in burada açıkça belirtilmeyen diğer tüm hakları
            saklıdır.<br><br>
            11. Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak
            kodunu bulmak veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını
            aksi halde üçüncü Kişiler nezdinde doğacak zararlardan sorumlu olacağını, hakkında hukuki ve
            cezai işlem yapılacağını peşinen kabul eder<br><br>
            12. İşbu kullanım koşullarında yer alan hususlar kanunun izin verdiği azami ölçüde geçerli
            olacaktır. Yaşar Holding tarafından sunulan hizmetler "olduğu gibi” ve "mümkün olduğu”
            temelde sunulmakta ve pazarlanabilirlik, belirli bir amaca uygunluk veya ihlal etmeme
            konusunda tüm zımni garantiler de dâhil olmak üzere hizmetler veya uygulama ile ilgili olarak
            (bunlarda yer alan tüm bilgiler dâhil) sarih veya zımni, kanuni veya başka bir nitelikte hiçbir
            garanti sağlamamaktadır.<br><br>
            13. Yaşar Holding, Sitede yer alan her tür hizmet, ürün, kampanya, vs. bilgiler ve kullanma
            koşullarını önceden bir ihtara gerek olmaksızın değiştirme, içeriğini yeniden düzenleme, yayını
            durdurma ve/veya duraklatma hakkını saklı tutar. Değişiklikler, Sitemizde yayınlanmalarıyla
            yürürlüğe girerler. Sitemizin kullanımı ya da Sitemize giriş ile bu değişiklikler de kabul edilmiş
            sayılır. Bu koşullar link verilen diğer web sayfaları için de geçerlidir.<br><br>
            14. Yaşar Holding, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin
            kesintiye uğraması, hata, ihmal, kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi, bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi,
            değiştirilmesi veya kullanılması hususunda ve durumunda herhangi bir sorumluluk kabul
            etmez.<br><br>
            15. Yaşar Holding, kullanıcıların sistemden yararlanmaları sırasında ortamda bulunduracakları
            dosyaların, mesajların bazılarını veya tamamını uygun göreceği periyotlarla yedekleme ve
            silme yetkisine sahiptir. Yedekleme ve silme işlemlerinden dolayı Yaşar Holding sorumlu
            tutulmayacaktır.<br><br>
            16. Yaşar Holding, ileride doğacak teknik zaruretler ve mevzuata uyum amacıyla işbu Kullanım
            Koşulları’nın uygulamasında değişiklikler yapabilir, mevcut maddelerini değiştirebilir veya yeni
            maddeler ilave edebilir. Yaptığımız değişikliklerden haberdar olabilmeniz için bu Kullanım
            Koşullarını düzenli olarak kontrol ediniz. Sitemizi kullanmaya devam ediyorsanız bu
            değişiklikleri kabul ettiğiniz varsayılacaktır. Bu değişiklikleri kabul etmiyorsanız Sitemizi
            kullanmamalısınız.<br><br>
            17. Yaşar Holding’e ait Yaşar İDEA web sitesinde virüs ve benzeri amaçlı yazılımlardan arındırılmış
            olması için mevcut imkanlar dahilinde tedbir almıştır fakat uygun kötücül içerik engelleme ve
            kontrol yazılımının kurulmasını sağlamaktan kullanıcı sorumludur. Kullanıcı, Sitemiz‘ deki,
            yüklenmeye (download) ve/veya paylaşıma müsait dosya, bilgi ve belgelerin, her türlü kötü ve
            zarar verme amaçlı materyal kontrolünden kendisi sorumlu olduğunu ve Sitemiz‘ in bu tür
            durumlar için garanti vermediğini kabul etmektedir. Bu tip kötü ve zarar verme amaçlı
            programların, kodların veya materyallerin önlenmesi, veri giriş - çıkışlarının doğruluğu veya
            herhangi bir kayıp verinin geri kazanılması için gereken tüm yazılım ve donanım ihtiyaçlarını
            karşılamak, bakım ve güncellemelerini yapmak tamamen KULLANICI‘ nın sorumluluğundadır.
            Bu tür kötü amaçlı programlar, kodlar veya materyallerin sebep olabileceği, veri yanlışlıkları
            veya kayıplarından dolayı KULLANICI’ nın veya üçüncü kişilerin uğrayabileceği hiçbir zarardan
            Yaşar Holding sorumlu değildir.<br><br>
            18. Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde
            genel ahlaka ve adaba aykırı, kanuna aykırı, üçüncü Kişilerin haklarını zedeleyen, yanıltıcı,
            saldırgan, müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı
            faaliyetleri teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde
            oluşacak zarardan tamamen kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür
            hesapları askıya alabilir, sona erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu sebeple
            yargı mercilerinden etkinlik veya kullanıcı hesapları ile ilgili bilgi talepleri gelirse paylaşma
            hakkını saklı tutar. Kullanıcı Site üzerinden iletişim araçları (forum, sohbet araçları veya mesaj
            merkezi gibi) sağlanması durumunda, iletişim araçlarını yalnızca hukuka uygun amaçlar
            çerçevesinde kullanacağını beyan ve taahhüt eder.<br><br>
            19. Kullanıcı, hesabını ve işbu Sözleşme ile site kullanımından doğan hak ve yükümlülüklerini
            herhangi bir şekilde üçüncü bir kişiye devir veya temlik edemez.<br><br>
            20. Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri kendi sorumluluğundadır.<br><br>
            21. Ek bilgi almak istediğiniz konularda… Yaşar Holding İletişim Merkezi telefonlarımızdan veya
            info@yasar.com.tr eposta adresinden Holding yetkilisine danışabilirsiniz.<br><br>
            Yaşar Holding
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {}
  },
  components: { }
})
</script>
