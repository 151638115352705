import { acceptHMRUpdate, defineStore } from 'pinia'
import bultenService from '@/services/bulten.service'

export const useBultenStore = defineStore({
  id: 'bultenStore',
  state: () => ({

  }),
  setup () {},
  actions: {
    sendMessage (data) {
      return bultenService.sendMessage(data)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBultenStore, import.meta.hot))
}
