<template>
 <BSModal ref="refModal">
      <template #header>
      </template>
      <template #body>
        <div class="d-flex align-items-center justify-content-center">
          <div class="w-75">
            <div class="text-center">
              <i
              class="bi bi-check-circle teal"
              style="font-size: 55px"
              ></i>
            </div>
            <div class="text-center">
              <p class="font-size-2xl black">
                Başvurunuz başarıyla gönderildi.
              </p>
            </div>
            <div class="text-center">
              <p class="font-size-lg black">Tebrikler!</p>
            </div>
          </div>
        </div>
       </template>
      <template #footer>
      </template>
  </BSModal>
</template>
<script>
import { defineComponent, defineExpose, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ApplySuccessModal',
  setup () {
    const refModal = ref(null)

    onMounted(() => {
      // refModal.value.show()
    })

    const show = () => {
      refModal.value.show()
    }

    const close = () => {
      refModal.value.hide()
    }

    defineExpose({ show, close })
    return {
      refModal,
      show,
      close
    }
  }
})
</script>
