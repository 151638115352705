import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/bonapply/HomeView.vue'
import LoginView from '../views/bonapply/LoginView.vue'
import LogoutView from '../views/bonapply/LogoutView.vue'
import RegisterView from '../views/bonapply/RegisterView.vue'
import ForgotPasswordView from '../views/bonapply/ForgotPasswordView.vue'
import ProgramsView from '../views/bonapply/ProgramsView.vue'
import RenewPasswordView from '../views/bonapply/RenewPasswordView.vue'
import ApplicationsView from '@/views/bonapply/ApplicationsView'
import ApplyFormView from '@/views/bonapply/ApplyFormView'
import MyProfileView from '@/views/bonapply/MyProfileView'
import PartnershipRequestsToMeView from '@/views/bonapply/PartnershipRequestsToMeView'
import BlogDetailView from '@/views/bonapply/BlogDetailView'
import BlogView from '@/views/bonapply/BlogView'
import ProfileRenewPasswordView from '@/views/bonapply/ProfileRenewPasswordView'
import LeaderboardView from '@/views/bonapply/LeaderboardView'
import PopularsView from '@/views/bonapply/PopularsView'
import WallView from '@/views/bonapply/WallView'
import FeedDetail from '@/views/bonapply/FeedDetail'
import ThematicView from '@/views/bonapply/ThematicView'
import MyCollaborationsToOtherIdeasView from '@/views/bonapply/MyCollaborationsToOtherIdeasView'
import MyIdeasView from '@/views/bonapply/MyIdeasView'
import MyReturnedIdeasView from '@/views/bonapply/MyReturnedIdeasView'
import MyEditableIdeasView from '@/views/bonapply/MyEditableIdeasView'
import DraftsView from '@/views/bonapply/DraftsView'
import ReportView from '@/views/bonapply/ReportView'
import ContactView from '@/views/bonapply/ContactView'
import ContactLogoutView from '@/views/bonapply/ContactLogoutView'
import NotificationsView from '@/views/bonapply/NotificationsView'
import ActivationView from '@/views/bonapply/ActivationView'
import ActivationRegisterView from '@/views/bonapply/ActivationRegisterView'
import LandingExternalView from '@/views/landing/LandingExternalView'
import IndexView from '@/views/IndexView'
import FaqView from '@/views/landing/FaqView'
import KvkkView from '@/views/landing/KvkkView'
import CookiesPolicyView from '@/views/landing/CookiesPolicyView'
import AboutPage from '@/views/bonapply/AboutPage'
import Sss from '@/views/bonapply/SssView'
import Ebulten from '@/views/bonapply/Ebulten'
import ProgramDetail from '@/views/bonapply/ProgramDetail'
import OAuthView from '@/views/bonapply/OAuthView.vue'
import KvkkView2 from '@/views/bonapply/KvkkView.vue'
import CookieView from '@/views/bonapply/CookieView.vue'
import TermOfUseView from '@/views/bonapply/TermOfUseView.vue'
import LegalWarningView from '@/views/bonapply/LegalWarningView.vue'
import ApplyDetail from '@/views/bonapply/ApplyDetail'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/kvkk',
    name: 'kvkk',
    component: KvkkView2
  },
  {
    path: '/cerez-politikasi',
    name: 'cookie',
    component: CookieView
  },
  {
    path: '/oauth/:apiToken',
    name: 'OAuth',
    component: OAuthView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPasswordView
  },
  {
    path: '/renew-password/:token',
    name: 'renew-password',
    component: RenewPasswordView
  },
  {
    path: '/activation/:token',
    name: 'activation',
    component: ActivationView
  },
  {
    path: '/register-activation/:token',
    name: 'activation_register',
    component: ActivationRegisterView
  },
  {
    path: '/profile-password-renew',
    name: 'renew',
    component: ProfileRenewPasswordView
  },
  {
    path: '/programs/:programType?',
    name: 'programs',
    component: ProgramsView
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationsView
  },
  {
    path: '/apply-form/:eventFormToken',
    name: 'apply-form',
    component: ApplyFormView
  },
  {
    path: '/apply/:applyUId',
    name: 'apply-detail',
    component: ApplyDetail
  },
  {
    path: '/apply-form/:eventFormToken/:applyUId',
    name: 'apply-form-detail',
    component: ApplyFormView
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: MyProfileView
  },
  {
    path: '/partnership-requests-to-me',
    name: 'partnership-requests-to-me',
    component: PartnershipRequestsToMeView
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView
  },
  {
    path: '/blog/:slug',
    name: 'blogPost',
    component: BlogDetailView
  },
  {
    path: '/my-leaderboard/:eventUId/:eventFormToken',
    name: 'my-leaderboard-for-event',
    component: LeaderboardView
  },
  {
    path: '/populars/:eventUId/:eventFormToken',
    name: 'populars-for-event',
    component: PopularsView
  },
  {
    path: '/my-thematic',
    name: 'my-thematic',
    component: ThematicView
  },
  {
    path: '/my-collaborations-to-other-ideas',
    name: 'my-collaborations-to-other-ideas',
    component: MyCollaborationsToOtherIdeasView
  },
  {
    path: '/my-ideas',
    name: 'my-ideas',
    component: MyIdeasView
  },
  {
    path: '/my-returned-ideas',
    name: 'my-returned-ideas',
    component: MyReturnedIdeasView
  },
  {
    path: '/my-editable-ideas',
    name: 'my-editable-ideas',
    component: MyEditableIdeasView
  },
  {
    path: '/drafts-view',
    name: 'drafts-view',
    component: DraftsView
  },
  {
    path: '/report-view',
    name: 'report-view',
    component: ReportView
  },
  {
    path: '/wall',
    name: 'wall',
    component: WallView
  },
  {
    path: '/search',
    name: 'search-result',
    component: WallView
  },
  {
    path: '/feed/:applyUId',
    name: 'feed',
    component: FeedDetail
  },
  {
    path: '/wall/:eventUId/:eventFormToken',
    name: 'wall',
    component: WallView
  },
  {
    path: '/contact-view',
    name: 'contact-view',
    component: ContactView
  },
  {
    path: '/contact-logout-view',
    name: 'contact-logout-view',
    component: ContactLogoutView
  },
  {
    path: '/notifications-view',
    name: 'notifications-view',
    component: NotificationsView
  },
  {
    path: '/landing/:key',
    name: 'landing',
    component: LandingExternalView
  },
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/faq-view',
    name: 'faq-view',
    component: FaqView
  },
  {
    path: '/kvkk',
    name: 'kvkk-view',
    component: KvkkView
  },
  {
    path: '/kullanim-kosullari',
    name: 'term-of-use-view',
    component: TermOfUseView
  },
  {
    path: '/yasal-bildirim',
    name: 'legal-warning-view',
    component: LegalWarningView
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy-view',
    component: CookiesPolicyView
  },
  {
    path: '/aboutPage',
    name: 'about-page',
    component: AboutPage
  },
  {
    path: '/sikca-sorulan-sorular',
    name: 'Sss',
    component: Sss
  },
  {
    path: '/Ebulten',
    name: 'Ebulten',
    component: Ebulten
  },
  {
    path: '/ProgramDetail',
    name: 'ProgramDetail',
    component: ProgramDetail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

export default router
