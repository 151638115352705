<template>
<div class="container mb-5">
  <div class="row up-md-padding-1">
    <div class="col-xl-5 col-lg-6 col-12 pb-3 rounded-5">
      <div class="position-relative ">
        <img src="@/assets/LeadImages/new.png" class="img-fluid rounded-5">
        <img src="@/assets/LeadImages/img-2.png" class="position-absolute down-md-d-none" style="bottom:-30px !important;right:-30px !important;z-index:-99 !important;"/>
      </div>
    </div>
    <div class="col-xl-7 col-lg-6 col-12 py-3 down-xl-pl-pr" style="padding-right:4rem;padding-left:4rem;">
      <div class="w-100">
      <div class="mb-4">
        <FormTextSlot form-text="E-Bülten’e Üye Ol!" class="m-0 text-aubergine-color font-size-11 fw-bold"></FormTextSlot>
      </div>
      <Form></Form>
      <form @submit="onSubmit" class="mb-4">
        <div class="form-outline mb-3 text-start">
          <field id="name" name="name" placeholder="Ad" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-3 text-start">
          <field id="surname" name="surname" placeholder="Soyad" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.surname }}</span>
        </div>
         <div class="form-outline mb-3 text-start">
          <field v-maska data-maska="0 (###) ### ## ##" id="phone" name="phone" placeholder="Cep Telefonu" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.phone }}</span>
        </div>
        <div class="form-outline mb-3 text-start">
          <field id="email" name="email" placeholder="E-Posta Adresi" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.email }}</span>
        </div>
         <div class="form-outline mb-4 text-start">
          <field id="linkedin" name="linkedin" placeholder="Linkedin Adresi" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.linkedin }}</span>
        </div>
        <div class="row">
            <div class="col-12 pe-0 d-flex align-items-center mb-4">
                    <div class="form-check">
                        <Field v-model="isCheckedKvkk" id="kvkk" name="kvkk" class="form-check-input me-2" type="checkbox"/>
                        <a style="text-decoration:none;cursor:pointer;" data-bs-toggle="modal" data-bs-target="#kvkk2" class="m-0 text-aubergine-color down-md-fs-1 text-left font-size-5 fw-500">
                        <span style="text-decoration:underline;">Aydınlatma Metni</span> çerçevesinde, bu form aracılığıyla paylaştığım kişisel verilerimin işlenmesini ve amacı ile bağlantılı faaliyetlerde kullanılmasını onaylıyorum.
                        </a>
                        <!-- Modal -->
                        <div class="modal fade" id="kvkk2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                  <br> <br>
                             <strong>KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA METNİ</strong>  <br>

Sayın İdealist Adayı,

6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) uyarınca veri sorumlusu sıfatını haiz Yaşar Holding A.Ş. (Şirket) olarak, başta temel hak ve özgürlükleri korunması olmak üzere özel hayatlara ilişkin gizliliğin korunması, bilgi güvenliğinin sağlanması ve korunması, etik değerlere saygı öncelikli prensiplerimiz arasında yer almaktadır. Buna bağlı olarak Kişisel Verilerin Korunması Kanunu (“KVKK”) 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine getirmek kapsamında kişisel verilerinizi hangi amaçlarla işlendiği, kimlerle paylaşabileceği ve verilerinizin toplanma yöntemi hususunda, sizleri bilgilendirmek istiyoruz.
<br><br>
1. Tanımlar
İşbu aydınlatma metninde geçen; 
	Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi, 
	Kişisel Verilerin Korunması Kanunu (“KVKK”): 7 Nisan 2016 tarihinde Resmi Gazete’de yayınlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu, 
	Veri İşleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun adına Kişisel Verileri işleyen gerçek veya tüzel kişiyi, 
	Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi, ifade eder.
	İlgili Kişi: Kişisel verisi işlenen gerçek kişiyi ifade eder.
<br><br>
2. Şirket Tarafından Toplanan ve İşlenen Veriler
Kişisel verileriniz; Kanun’da öngörülen temel ilkelere uygun ve Kanun’un 5. maddesinde sayılan hukuki sebeplerden; sözleşmenin kurulması, kanunlarda açıkça öngörülmesi, tarafınızca alenileştirilmiş olması ve ilgili kişinin temel hak ve özgürlüklüklerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaati ve açık rızasının bulunması hallerine dayanarak ve bunlarla sınırlı olarak, Yaşar İDEA Kurum İçi Girişimcilik Programı’na (“Program”) başvurmak için doğrudan başvuran olmanız halinde dolduracağınız; internet sitesinde yer alan başvuru formunda (“Form”) talep edilen bilgileriniz:
	Kimlik Bilgileriniz (Ad-soyad, doğum tarihi ve cinsiyet) iletişim bilgileriniz (e-posta ve cep telefon numarası)
	İş fikri bilgileriniz, katılım bilgileriniz ve Program kapsamında Yaşar Holding ile paylaştığınız bilgileriniz (talepleriniz, sorularınız, anket önerileriniz içerisinde yer alan paylaştığınız kişisel veri niteliğindeki diğer bilgileriniz)
	Program sonunda Yaşar Holding ile yürütülecek işlemler kapsamında ihtiyaç duyulacak kişisel verileriniz
<br><br>
3. Kişisel Verilerinizin İşlenme Amacı
KVKK’nın 5 ve 6. maddeleri kapsamında aşağıda belirttiğimiz şirket amaçlarımızı, ilgili amaçlar ile sınırlı olmak kaydı ile gerçekleştirebilmek için veri toplamakta ve işlenmekteyiz. Bunlar;
	Yetkili kurum ve kuruluşların bilgi / belge talep etmesi;
	Başvuru sürecinizin değerlendirilmesi; değerlendirmenin olumlu sonuçlanması halinde Program’a kaydınızın yapılması ve katılımınız boyunca sizinle girişim projenizin (“Proje”) yürütülmesi / geliştirilmesine ilişkin iletişime geçilmesi;
	Projenizin ve Program sürecindeki çalışmalarınızın takibi, değerlendirilmesi ve Program sonundaki seçim süreçlerinin yürütülmesi;
	Kişisel verileriniz anonimleştirilmek suretiyle Program süreçlerinin iyileştirilmesi amacıyla istatistiki amaçlarla kullanılması;
	Program sonunda Yaşar Holding desteği almaya hak kazanmanız halinde Yaşar Holding ile ilgili hukuki işlemlerin (mevcut bir şirket yoksa şirket kuruluşu ve tescili, hisse devir, ortaklık sözleşmesi vs.) yürütülmesi ve tamamlanması;
	Uyuşmazlıkların önlenmesi, gerekli hukuki yollara başvurulması, başlatılan hukuki süreçlere dahil olunması halinde gerekli bilgi ve belgelerin sağlanmasıdır.
<br><br>
4. Verilerin Toplanma Yöntemi ve Hukuki Sebebi
Kişisel veriler, şirketimiz tarafından kişisel veri sahiplerine açıkça ve anlaşılabilir bir şekilde sözlü, yazılı ve/veya elektronik bilgilendirmede bulunularak ve gerektiğinde açık rızaları alınarak sözlü, yazılı ve/veya elektronik yollarla, hukuka ve dürüstlük kurallarına uygun olarak, yukarıda açıkça belirtilen meşru amaçlarla bağlantılı ve sınırlı olmak kaydıyla, ölçülülük prensibi çerçevesinde internet sitesi arayüzü aracılığı ile toplanmakta, kullanılmakta, kaydedilmekte, depolanmakta ve işlenmektedir.

Kişisel verilerinizin şirketimiz tarafından, bu aydınlatma belgesinde belirtilen amaçlar dışında işlenmeyeceğini, yurt içinde ve yurt dışında bulunan 3. kişilere aktarılmayacağını ve saklanmayacağını temin ederiz.

Form’da paylaştığınız üçüncü kişilere ait kişisel veriler de yine işbu Aydınlatma Metni kapsamında işlenecektir. Söz konusu kişileri Program’a kayıt öncesi bu kapsamda bilgilendirmeniz gerekmekte olup bu kişilerin bilgilerini Yaşar Holding ile KVKK’ya uygun paylaşma sorumluluğu size aittir.

Program kapsamında yapılacak projelerin ve benzeri çalışmaların takibi için dış tedarikçilerden hizmet alınabilir. Doğrudan Yaşar Holding tarafından veri aktarımı yapılmayan bu gibi durumlarda, ilgili tedarikçiler tarafından kişisel verilerinizin işlenmesi, saklanması ve yurt dışına aktarılması söz konusu olabilir. Bu halde gerekli aydınlatmanın yapılması ve açık rızaya başvurulması gibi süreçler KVKK kapsamında ilgili tedarikçi tarafından yürütülecektir.
<br><br>
5. Kişisel Verileriniz Hangi Amaçlarla Kimlere Aktarılmaktadır?
Girişimciler ile mentörlerin ilgili platform üzerinde buluşabilmeleri, bir araya gelebilmeleri ve Programa ilişkin süreçlerin takibi ve yönetimi amacıyla verileriniz, Program katılımcıları olan MDA Teknoloji ve Yatırım Ticaret A.Ş. – Mentors Network Turkey ve Bon Teknoloji ve Reklam A.Ş. – Bonte ile yetkili kurum ve kuruluşlarla aktarılmaktadır.
<br><br>
6. Şirketimiz Tarafından Toplanan Verilerin Saklanma Süresi
Kişisel verileriniz, ilgili yasal mevzuatlarda belirtilen saklama sürelerince, ilgili yasal mevzuatlarda herhangi bir süre belirlenmemişse, Şirketimizin uygulamaları ve ticari yaşamının teamülleri uyarınca veya yukarıda anılan işleme amaçlarının gerekli kıldığı süre boyunca verileriniz saklanmakta ve sonrasında KVKK’nın 7. maddesine uygun olarak silinmekte, yok edilmekte ya da anonim hale getirilmektedir.
<br><br>
7. Şirketimiz Tarafından Toplanan ve İşlenen Verilerinizin Güvenliği 
Kişisel verileriniz, işlendikleri ve saklandıkları ortamlarda yetkisiz erişime maruz kalmamaları, kaybolmamaları ve zarar görmemeleri amacıyla yönetimimiz tarafından teşvik edilen Bilgi Güvenliği Yönetim Sistemi (ISO 27001 ve 27701 Standardı) teknik ve idari tedbirlerini ve ayrıca Sermaye Piyasası Kurulu tarafından yayınlanan Bilgi Güvenliği tebliği gereklilikleri ve ayrıca KVK kurulu tarafından yayımlanmış Kişisel Veri Güvenliği Rehberi gereklilikleri sürekli işletilmekte ve sürekli iyileştirme kapsamında geliştirmektedir.
<br><br>
8. Verisi Toplanan ve İşlenen İlgili Kişinin Hakları
KVKK 11. maddesi uyarınca herkes, veri sorumlusuna başvurarak kendisiyle ilgili; 
a)	Kişisel veri işlenip işlenmediğini öğrenme,
b) 	Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, 
c) 	Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, 
ç) 	Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, 
d) 	Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, 
e) 	7. maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini / yok edilmesini isteme, 
f) 	(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, 
g) 	İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, 
ğ) 	Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahiptir.
<br><br>
9. Kişisel Verilerinizin Korunması Hususunda Haklarınız Nelerdir?
Kişisel verilerin korunmasına ilişkin mevzuat kapsamında, KVKK madde 11 uyarınca sahip olduğunuz haklarınızı kullanabilirsiniz.
<br><br>
İşbu Aydınlatma Metninde açık rıza gerektiren konulara açık rıza verdikten sonra, açık rızanızı her zaman geri çekebilirsiniz.
<br><br>
KVKK madde 11 kapsamında sahip olduğunuz haklarınızı kullanmak için “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” (“Tebliğ”) uyarınca;
	Yazılı olarak Akdeniz Mah. Şehit Fethi Bey Caddesi No:120/Z1 Alsancak-İzmir adresine, «Başvuru Formu» doldurarak kapalı zarf ve zarfın üzerine «Kişisel Verilerin Korunması Kanunu Gereği Bilgi Talebi» notu ile;
	kvkk@yasar.com.tr adresine daha önce Şirket’e bildirdiğiniz ve Şirket sisteminde kayıtlı bulunan elektronik posta adresinizden elektronik posta yoluyla veya

	yasarholding@hs01.kep.tr kayıtlı elektronik posta adresine kayıtlı elektronik posta yoluyla müracaat edebilirsiniz. <br><br>

Yaşar İDEA Kurum İçi Girişimcilik Programı’nın değişen ve gelişen süreçlerine ilişkin meydana gelebilecek yeni kişisel veri işleme faaliyetleri doğrultusunda işbu Aydınlatma Metni gözden geçirilebilecektir. Aydınlatma Metni’ndeki değişiklikleri takip edebilmeniz için internet sitemizi düzenli olarak ziyaret etmenizi öneririz.<br>
<br>
Saygılarımızla,<br>

Veri Sorumlusu<br>
Yaşar Holding Anonim Şirketi<br><br>
                              </div>
                            </div>
                        </div>
                        </div>
                        <span class="text-gray-color font-size-2">{{ errors.kvkk }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <ButtonSlot button-text="Üye Ol" class="btn-primary"></ButtonSlot>
                </div>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useBultenStore } from '../../store/bulten'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { vMaska } from 'maska'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA'
    })
    const bultenStore = useBultenStore()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız'),
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email('Lütfen E-posta Adresinizi Kontrol Ediniz'),
      linkedin: yup.string().required('*Lütfen Linkedin Adresinizi Yazınız'),
      kvkk: yup.bool('*Bu alan zorunludur').oneOf([true], '*Bu alan zorunludur').typeError('*Bu alan zorunludur')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, { resetForm }) => {
      bultenStore.sendMessage(values)
      resetForm()
    })

    return {
      bultenStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    FormTextSlot
  },
  directives: { maska: vMaska },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

.form-control::placeholder{
  color: #6F7273 !important;
  font-weight:500 !important;
}
</style>
