<template>
  <div class="container mt-xl-5 mt-lg-5 mt-3" v-if="!isContentLoaded">
    Lütfen bekleyiniz...
  </div>
  <div class="container mt-xl-5 mt-lg-5 mt-3" v-if="isContentLoaded">
    <div class="mb-4">
      <p class="m-0 text-midnightBlue-color font-size-11 fw-bold">Programlar</p>
    </div>
    <div class="row">
      <div class="col-6 d-none">
          <nav class="navbar navbar-light navbar-expand-lg">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link :to="{ name: 'programs' }" class="nav-link ps-0" aria-current="page">Programlar</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'programs', params : { programType:'thematic' }}" class="nav-link">Tematik Çağrılar</router-link>
              </li>
            </ul>
          </nav>
      </div>
      <div class="col-12 d-flex justify-content-end d-none">
          <div class="mb-2">
              <select v-model="statusFilter" class="form-select w-auto border-0" aria-label="Default select example">
                <option value="active">Aktif Programlar</option>
                <option value="complete">Geçmiş Programlar</option>
              </select>
          </div>
      </div>
    </div>

    <div v-if="programStore.base_events">
      <div v-if="programStore.base_events.length===0">
        <h5>Program bulunmuyor.</h5>
      </div>
    </div>

    <div class="row mb-4 programs" v-for="item in programStore.base_events" :key="item">
        <div class="col-12 mb-3 d-none">
          <img :src="item.cover_img" class="img-fluid w-100 img_radius" alt="program_images">
        </div>
        <div class="col-12 text-start d-none">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="col-12 text-start d-none">
          <p class="font_3">
            {{ item.detail }}
          </p>
        </div>
      <div>
        <div class="row pb-5">
         <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-5" v-for="item2 in item.events" :key="item2">
            <div class="mb-2 p-2">
              <img :src="item2.cover_img" class="img-fluid rounded-30 w-100" alt="program_images">
            </div>
            <div class="mb-2 px-4">
              <p class="m-0 fw-light font-size-5 text-aubergine-color">{{ item2.release_date }} - {{ item2.expire_date }}</p>
            </div>
              <div class="card-body px-4">
                <div class="text-start ellipsis-area-2">
                  <h5 class="m-0 fw-bold font-size-10 text-aubergine-color">{{ item2.title }}</h5>
                </div>
                <div class="mb-3" style="height:87px;">
                  <p class="m-0 fw-light font-size-5 text-aubergine-color line-overflow" v-html="item2.subject"></p>
                </div>
                <!--
                <div class="interactions" style="min-height: 45px !important;">
                  <div v-if="item2.myApplyStatus">
                    <i>Başvurunuzun durumu:</i>
                    <h6>{{ item2.myApplyStatus.title }}</h6>
                  </div>
                  <template v-if="item2.amIHaveToCompleteMyApplyToThisProgram">
                      <template v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                        <div class="d-flex justify-content-between mb-1">
                          <span v-if="availableApply.feed.answers.feed_program_apply_title !== undefined" class="programs-feed-title">
                          {{ availableApply.feed.answers.feed_program_apply_title.formItemReply }}
                          </span>
                          <span v-else-if="availableApply.feed.answers.feed_program_apply_description !== undefined" class="programs-feed-title">
                            {{ availableApply.feed.answers.feed_program_apply_description.formItemReply }}
                          </span>
                          <router-link :to="{  name: 'apply-form-detail', params: { eventFormToken: item2.event_form.token, applyUId: availableApply.apply.uid } }" class="btn btn-primary button_1">Başvuruya Devam Et</router-link>
                        </div>
                      </template>
                  </template>
                  <template v-if="!item2.amIHaveToCompleteMyApplyToThisProgram">
                    <div v-if="item2.canIApplyThisProgram == false">
                        <template v-if="item2.available_applies.length > 0">
                          <template v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                            <div class="d-flex justify-content-between mb-1">
                              <span v-if="availableApply.feed.answers.feed_program_apply_title !== undefined" class="programs-feed-title">
                              {{ availableApply.feed.answers.feed_program_apply_title.formItemReply }}
                              </span>
                              <span v-else-if="availableApply.feed.answers.feed_program_apply_description !== undefined" class="programs-feed-title">
                                {{ availableApply.feed.answers.feed_program_apply_description.formItemReply }}
                              </span>
                            </div>
                          </template>
                        </template>
                      </div>
                  </template>
                 </div>
                -->
              <div class="apply_area">
                  <div>
                      <div v-if="item2.is_feed_program">
                        <router-link :to="{  name: 'wall', params: { eventUId: item2.uid, eventFormToken: item2.event_form.token } }" class="btn-primary text-decoration-none d-flex align-items-center justify-content-center" style="height:36px !important;width:max-content !important;">Başvur</router-link>
                      </div>
                     <div v-if="!item2.is_feed_program">
                        <div v-if="!item2.amIHaveToCompleteMyApplyToThisProgram && item2.status">
                          <router-link :to="{  name: 'apply-form', params: { eventFormToken: item2.event_form.token } }" class="btn-primary text-decoration-none d-flex align-items-center justify-content-center" style="height:36px !important;width:max-content !important;">Başvur</router-link>
                        </div>
                        <div v-if="item2.amIHaveToCompleteMyApplyToThisProgram">
                          <div v-if="item2.available_applies.length > 0">
                            <div v-for="availableApply in item2.available_applies" :key="availableApply.uid">
                              <router-link :to="{  name: 'apply-form-detail', params: { eventFormToken: item2.event_form.token, applyUId: availableApply.apply.uid } }" class="btn-primary purple font-size-base fw-bold btn border-0">Başvuruya Devam Et</router-link>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '../../store/auth'
import { useProgramStore } from '@/store/program'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    const authStore = useAuthStore()
    const programStore = useProgramStore()
    const isContentLoaded = ref(false)

    const route = useRoute()
    let programType = route.params.programType

    const statusFilter = ref('active')

    const load = () => {
      programType = route.params.programType
      isContentLoaded.value = false
      programStore.getPrograms(getFilteredType()).then(
        response => {
          isContentLoaded.value = true
        }
      )
    }

    onMounted(() => {
      load()
    })

    watch(route, () => {
      load()
    })

    watch(statusFilter, (newValue) => {
      isContentLoaded.value = false
      programStore.getPrograms(getFilteredType()).then(
        response => {
          isContentLoaded.value = true
        }
      )
    })

    const getFilteredType = () => {
      if (programType === 'thematic') return statusFilter.value + '_thematic_calls'
      else return statusFilter.value
    }

    return {
      authStore,
      programStore,
      isContentLoaded,
      statusFilter
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
.ellipsis-area-2 {
  height: 100px;
  overflow: hidden;
  h5{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.hover-border{
  border: solid 5px transparent !important;
}
.hover-border:hover{
  border: solid 5px #fdb913 !important;
}
</style>
