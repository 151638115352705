<template>
    <th scope="row" class="font-size-xl dark-gray fw-bold">
      {{ data.rank }}
    </th>
    <td>
      <div
        class="d-flex align-items-center"
        style="width: max-content !important"
      >
        <p class="m-0 me-2 font-size-xl dark-gray fw-bold">{{ data.feed.apply.user.name }} {{ data.feed.apply.user.surname }}</p>
        <p class="m-0 font-size-xl dark-gray fw-normal">{{ data.feed.apply.user.createDate }}</p>
      </div>
      <div>
        <p class="m-0">
          {{ data.feed.answers.feed_program_apply_title.formItemReply }}
        </p>
      </div>
    </td>
    <td class="text-center font-size-lg dark-gray-2 fw-bold align-middle">
      {{ data.feed.counts.likes }}
    </td>
    <td class="text-center font-size-lg dark-gray-2 fw-bold align-middle">
      {{ data.feed.counts.comments }}
    </td>
    <td class="text-center font-size-lg dark-gray-2 fw-bold align-middle">
       {{ data.feed.counts.views }}
    </td>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'WallPost',
  props: {
    data: {}
  },
  data () { return {} },
  setup (props) {
    return { }
  }
})
</script>
