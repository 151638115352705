import { BaseService } from './BaseService'
import { useConfigStore } from '@/store/config'
const COOKIE_POLICY = '/v1/static-page/cookies_policy'

class CookiesPolicyService extends BaseService {
  getCookiesPolicyInfo () {
    const sectionKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'inHouseUserCookiesPolicy' : 'externalUserCookiesPolicy'
    return this.get(COOKIE_POLICY + '?sectionKey=' + sectionKey)
  }
}

export default new CookiesPolicyService()
