<template>
<div class="container-fluid bg-dodgerBlue-color p-0 pt-4 pb-2">
<div class="container">
        <div class="row mb-5 pt-xl-5 pt-lg-5 pt-3">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-5 ">
                    <div class="mb-5">
                    <a href="/">
                        <img src="@/assets/LeadImages/img-11.svg" alt="Idea Logo" height="78" class="img-fluid">
                    </a>
                    </div>
                    <div class="d-none">
                        <a href="">
                          <img src="@/assets/LeadImages/img-4.svg" height="22" alt="Instagram Logo Image" class="me-4">
                        </a>
                        <a href="">
                          <img src="@/assets/LeadImages/img-5.svg" height="22" alt="Facebook Logo Image" class="me-4">
                        </a>
                        <a href="">
                          <img src="@/assets/LeadImages/img-6.svg" height="20" alt="Thread Logo Image">
                        </a>
                    </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3  footer_links pe-0">
                        <div class="mb-2">
                        <a href="/AboutPage">Hakkında</a>
                        </div>
                        <div class="mb-2">
                        <a href="/programDetail">Program</a>
                        </div>
                        <div class="mb-2">
                       <a href="/sikca-sorulan-sorular">S.S.S.</a>
                        </div>
                         <div class="mb-2">
                        <a href="/contact-logout-view">Bize Ulaşın</a>
                        </div>
                        <!--
                        <div class="mb-2">
                        <a href="/blog">Blog</a>
                        </div>
                        -->
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4  footer_links pe-0">
                    <div class="mb-2">
                       <a class="btn p-0 m-0 border-0" href="/kvkk">{{kvkkStore.kvkkInfo.title}} Metni</a>
                    </div>
                     <div class="mb-2">
                       <a class="btn p-0 m-0 border-0" href="/kullanim-kosullari">Kullanım Koşulları</a>
                    </div>
                    <div class="mb-2">
                       <a class="btn p-0 m-0 border-0" href="/yasal-bildirim">Yasal Bildirim</a>
                    </div>
                    <div class="mb-2">
                       <a class="btn p-0 m-0 border-0" href="/cerez-politikasi">{{kvkkStore.cookieInfo.title}}</a>
                    </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-12 mt-xl-0 mt-lg-0 mt-3">
                    <div class="mb-4">
                        <p class="m-0 text-white-color fw-light font-size-5 down-md-fs-1">İnovasyon ve girişimcilik çalışmalarından haberdar olmak, açık programlar hakkındaki gelişmeleri takip etmek ve düşüncelerinizi paylaşmak için...</p>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-4 col-sm-5 col-6">
                            <a href="/contact-logout-view">
                              <button type="btn" class="btn btn-primary w-100 down-xl-fs-1 down-sm-fs-4 px-0 font-size-6">Bizimle İletişime Geç!</button>
                            </a>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-4 col-sm-5 col-6">
                            <a href="/Ebulten">
                                <button type="btn" class="btn btn-primary w-100 down-xl-fs-1 down-sm-fs-4 px-0 font-size-6">E-Bülten’e Üye Ol</button>
                            </a>
                        </div>
                    </div>
                    </div>
        </div>
        <div class="row pb-2">
            <div class="col-12 up-lg-d-none mb-3">
                <p class="m-0 text-white-color font-size-6 fw-light down-md-fs-1"><strong>Yaşar İDEA,</strong> Yaşar Topluluğu’nun inovasyon platformudur.</p>
            </div>
            <div class="col-5 pe-0">
                <p class="m-0 text-white-color font-size-3 fw-light">© 2024 Yaşar Holding</p>
            </div>
            <div class="col-7 ps-0 d-flex align-items-center justify-content-end" style="z-index:9999 !important;">
                <p class="m-0 text-white-color font-size-1 fw-light">Creation Technology by
                <a href="https://bonapply.com/" target="_blank">
                    <img src="@/assets/LeadImages/img-3.svg" alt="Bonte Logo" height="8" class="ms-2">
                </a>
            </p>
            </div>
            <div class="col-12 text-center down-lg-d-none" style="margin-top:-23px;">
                <p class="m-0 text-white-color font-size-4 fw-light"><strong>Yaşar İDEA,</strong> Yaşar Topluluğu’nun inovasyon platformudur.</p>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useKvkkStore } from '@/store/kvkk'
import { useCookiesPolicyStore } from '@/store/cookies_policy'

export default defineComponent({
  setup () {
    const kvkkStore = useKvkkStore()
    const cookiesPolicyStore = useCookiesPolicyStore()
    kvkkStore.getKvkkInfo()
    kvkkStore.getCookieInfo()
    cookiesPolicyStore.getCookiesPolicyInfo()
    return {
      kvkkStore,
      cookiesPolicyStore
    }
  }
})
</script>
<style lang="scss" scoped>


.btn-primary:hover{
    background-color: #C1E600 !important;
}
</style>
