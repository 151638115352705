import { acceptHMRUpdate, defineStore } from 'pinia'
import CollaborateService from '@/services/collaborate.service'

export const useCollaborateStore = defineStore({
  id: 'collaborateStore',
  state: () => ({
    posts: null,
    partnershipRequestsToMe: null,
    myCollaborationsToOtherIdeas: null
  }),
  setup () {},
  actions: {
    requestToJoin (applyId) {
      return CollaborateService.requestToJoin(applyId).then(
        response => {
          return response
        }
      )
    },
    leaveRequestToJoin (applyId) {
      return CollaborateService.leaveRequestToJoin(applyId).then(
        response => {
          return response
        }
      )
    },
    getMyCollaborationsToOtherIdeas () {
      return CollaborateService.getMyCollaborationsToOtherIdeas().then(
        response => {
          this.myCollaborationsToOtherIdeas = response.data.data.posts
          return response
        }
      )
    },
    getPartnershipRequestsToMe () {
      return CollaborateService.getPartnershipRequestsToMe().then(
        response => {
          this.partnershipRequestsToMe = response.data.data.partnerShipRequestsToMe
          return response
        }
      )
    },
    reply (id, process) {
      return CollaborateService.replyRequest(id, process).then(
        response => {
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCollaborateStore, import.meta.hot))
}
