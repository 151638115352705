<template>
    <div class="row">
        <div class="col-6">
          <div class="dropdown">
            <button class="btn dropdown-toggle ps-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Filtre <i class="fa fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu bg-white rounded-10">
              <li>
                <a class="dropdown-item" href="#">Fayda Tipi <i class="fa fa-chevron-right float-end"></i></a>
                <ul class="dropdown-menu dropdown-submenu rounded-10 bg-white" style="min-width: 250px !important;">
                    <li class="px-3" v-for="beneficiaryType in applyStore.eventBeneficiaryTypes" v-bind:key="beneficiaryType.id">
                      <div class="form-check">
                        <input @change="onBeneficiariesChange($event)" v-model="checkedBeneficiaryIds"  class="form-check-input" type="checkbox" :value="beneficiaryType.id" :id="'feed-filter-beneficiary-' + beneficiaryType.id">
                        <label class="form-check-label" :for="'feed-filter-beneficiary-' + beneficiaryType.id">
                          {{ beneficiaryType.title  }}
                        </label>
                      </div>
                    </li>
                </ul>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a class="dropdown-item" href="#">Tarih Aralığı
                  <div class="mt-2">
                  <Datepicker v-model="date" range
                  selectText="Uygula"
                  cancelText="İptal"
                  :enableTimePicker="false"
                  @update:modelValue="handleDate"
                  :format="format"
                  :previewFormat="format"
                  :dayNames="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
                  locale="tr"
                  />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <button class="btn dropdown-toggle text-end pe-0" type="button" id="dropdownWallSort" data-bs-toggle="dropdown" aria-expanded="false">
              Artan/Azalan <i class="fa fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu bg-white round-30" aria-labelledby="dropdownWallSort">
              <li><a @click="orderBy('complete_date','desc')" class="dropdown-item" href="#">En Yeni</a></li>
              <li><a @click="orderBy('complete_date','asc')" class="dropdown-item" href="#">En Eski</a></li>
              <li><a @click="orderBy('commentCount','asc')" class="dropdown-item" href="#">Yorum Sayısına Göre Artan</a></li>
              <li><a @click="orderBy('commentCount','desc')" class="dropdown-item" href="#">Yorum Sayısına Göre Azalan</a></li>
              <li><a @click="orderBy('likeCount','asc')" class="dropdown-item" href="#">Beğeni Sayısına Göre Artan</a></li>
              <li><a @click="orderBy('likeCount','desc')" class="dropdown-item" href="#">Beğeni Sayısına Göre Azalan</a></li>
              <li><a @click="orderBy('viewCount','asc')" class="dropdown-item" href="#">Görüntüleme Sayısına Göre Artan</a></li>
              <li><a @click="orderBy('viewCount','desc')" class="dropdown-item" href="#">Görüntüleme Sayısına Göre Azalan</a></li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useFeedStore } from '@/store/feed'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'WallHeader',
  props: {
    eventUid: null
  },
  setup (props) {
    const feedStore = useFeedStore()
    const date = ref()
    const checkedBeneficiaryIds = ref([])
    const applyStore = useApplyStore()
    applyStore.getBeneficiaryTypes(props.eventUid)
    const format = (modelData) => {
      const dateFrom = modelData[0]
      const dateTo = modelData[1]
      const dateFromDay = dateFrom.getDate()
      const dateFromMonth = dateFrom.getMonth() + 1
      const dateFromYear = dateFrom.getFullYear()

      const dateToDay = dateTo.getDate()
      const dateToMonth = dateTo.getMonth() + 1
      const dateToYear = dateTo.getFullYear()

      return `${dateFromDay}/${dateFromMonth}/${dateFromYear} - ${dateToDay}/${dateToMonth}/${dateToYear}`
    }
    const onBeneficiariesChange = (event) => {
      feedStore.filter.beneficiaryTypeIds = checkedBeneficiaryIds.value
      feedStore.feed()
    }

    const orderBy = (field, direction) => {
      feedStore.resetPaging()
      feedStore.resetPosts()
      feedStore.filter.orderBy = field
      feedStore.filter.orderDirection = direction
      feedStore.feed(true)
    }

    const handleDate = (modelData) => {
      let dateFrom = modelData[0]
      let dateTo = modelData[1]
      dateFrom = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1).toString().padStart(2, '0') + '-' + dateFrom.getDate().toString().padStart(2, '0')
      dateTo = dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1).toString().padStart(2, '0') + '-' + dateTo.getDate().toString().padStart(2, '0')
      date.value = modelData
      feedStore.filter.createDateFrom = dateFrom
      feedStore.filter.createDateTo = dateTo
      feedStore.feed()
    }

    watch(date, () => {
      if (date.value == null) {
        feedStore.filter.createDateFrom = ''
        feedStore.filter.createDateTo = ''
        feedStore.feed()
      }
    })

    return {
      date,
      format,
      checkedBeneficiaryIds,
      onBeneficiariesChange,
      handleDate,
      orderBy,
      applyStore
    }
  },
  components: {
    Datepicker
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
</style>
