import { BaseService } from './BaseService'
const REPORT = '/v1/dashboard/report'
const LIST_OF_IDEAS = '/v1/dashboard/list_of_ideas'

class ReportService extends BaseService {
  getReport () {
    return this.get(REPORT)
  }

  getListOfIdeas (orderParam, orderByParam) {
    return this.get(LIST_OF_IDEAS + '?order=' + orderParam + '&order_by=' + orderByParam)
  }
}

export default new ReportService()
