import { BaseService } from './BaseService'
import { useConfigStore } from '@/store/config'

const FAQ_PATH = '/v1/faq/'

/* eslint-disable */
class FaqService extends BaseService {
  // Sık Sorulan Sorular Bilgilerini Almak
  getFaqInfo () {
    const pageKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'in_house_user' : 'external_user'
    return this.get(FAQ_PATH + pageKey + '/list')
  }
  // End
}
export default new FaqService()
/* eslint-disable */
