<template>
 <div class="nav d-block nav-pills me-3 min-width mb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <router-link :to="{ name: 'my-ideas' }" class="nav-link text-start font-size-lg">Fikirlerim</router-link>
    <router-link :to="{ name: 'my-returned-ideas' }" class="nav-link text-start font-size-lg">İade Edilen Fikirlerim</router-link>
    <router-link :to="{ name: 'my-editable-ideas' }" class="nav-link text-start font-size-lg">Düzenleme Bekleyen Fikirlerim</router-link>
    <!--
    <router-link :to="{ name: 'my-collaborations-to-other-ideas' }" class="nav-link text-start font-size-lg">Ortak Olduğum Fikirler</router-link>
    <router-link :to="{ name: 'partnership-requests-to-me' }" class="nav-link text-start font-size-lg">Gelen Ortak Olma Talepleri</router-link>
    -->
    <router-link :to="{ name: 'drafts-view' }" class="nav-link text-start font-size-lg">Taslaklarım</router-link>
    <router-link :to="{ name: 'report-view' }" class="nav-link text-start font-size-lg">Raporlar</router-link>
    <router-link :to="{ name: 'notifications-view' }" class="nav-link text-start font-size-lg">Bildirimler</router-link>
</div>
</template>
