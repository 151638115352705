import { acceptHMRUpdate, defineStore } from 'pinia'
import CommentService from '@/services/comment.service'

export const useCommentStore = defineStore({
  id: 'commentStore',
  state: () => ({
    activeRootEntity: null,
    activeRootEntityId: null,
    comments: []
  }),
  setup () {},
  actions: {
    get (data) {
      return CommentService.get(data).then(
        response => {
          this.activeRootEntity = data.parentEntity
          this.activeRootEntityId = data.parentEntityId
          if (response.data.data.rootComment) {
            if (response.data.data.rootComment.materialPath) {
              this.comments[response.data.data.rootComment.materialPath] = response.data.data.rootComment.children
            }
          }
          return response
        }
      )
    },
    add (data) {
      return CommentService.add(data).then(
        response => {
          return response
        }
      )
    },
    remove (commentId) {
      return CommentService.remove(commentId).then(
        response => {
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommentStore, import.meta.hot))
}
