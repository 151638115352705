<template>
       <div id="aboutUs" class="container mb-5 down-md-pt-none" style="padding-top:2rem;margin-top:0rem;">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-12 d-flex align-items-center mb-xl-3 mb-lg-3 mb-0 position-relative">
               <div v-bind:style="{ backgroundImage: 'url(' + section.coverImg + ')' }" style="background-size: cover;
            background-repeat: no-repeat !important;
            background-position: center !important;
            border-radius: 30px !important;
            min-height: 100% !important;
            width:100% !important;" loading="lazy">
            <img src="@/assets/LeadImages/img-2.png" alt="Idea Image" class="position-absolute down-lg-d-none" style="top:-30px;right:-25px;z-index:-99 !important;" loading="lazy">
            </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-12 d-flex align-items-center px-xl-5 px-lg-5 px-3">
                <div>
                    <div v-if="landingInfo.data.content.pageKey == 'in_house_user_landing_page'" class="mb-3">
                        <p class="font-size-13 text-aubergine-color fw-bold down-md-fs-3 down-md-fs-5">
                            {{ section.title }}
                        </p>
                    </div>
                     <div v-if="landingInfo.data.content.pageKey == 'in_house_user_landing_page'" class="mb-2">
                        <p class="font-size-13 text-aubergine-color fw-bold down-md-fs-3 down-md-fs-5">
                            {{ section.subTitle }}
                        </p>
                    </div>
                    <div v-if="landingInfo.data.content.pageKey == 'external_user_landing_page'">
                        <p class="font-size-12 text-aubergine-color fw-bold down-md-fs-3 down-md-fs-5 m-0">
                            {{ section.title }}
                        </p>
                    </div>
                    <div>
                        <p class="m-0 text-aubergine-color fw-bold font-size-11 down-md-fs-5">Gelecek seninle burada!</p>
                    </div>
                    <div class="mb-3">
                        <p class="m-0 font-size-6 fw-normal text-aubergine-color" v-html="section.content"></p>
                    </div>
                    <a href="/AboutPage">
                    <button type="btn" class="btn btn-primary">Devamını Oku</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent, onMounted} from 'vue'

export default defineComponent({
  props: {
    section: {},
    landingInfo: {}
  },
  setup (props,context) {
    onMounted(() => {
      setTimeout(function () {
        context.emit('isReady', {})
      }, 200)
    })
  }
})
</script>

