import { acceptHMRUpdate, defineStore } from 'pinia'
import cookiesPolicyService from '@/services/cookie_policy.service'

export const useCookiesPolicyStore = defineStore({
  id: 'cookiesPolicyStore',
  state: () => ({
    cookiesPolicyInfo: []
  }),
  setup () {},
  actions: {
    getCookiesPolicyInfo () {
      return cookiesPolicyService.getCookiesPolicyInfo().then(
        response => {
          this.cookiesPolicyInfo = response.data.data.section
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCookiesPolicyStore, import.meta.hot))
}
