<template>
<div class="sticky-top w-100">
  <div class="container mt-1">
        <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand me-5" href="/" alt="Idea Logo">
                  <img src="@/assets/LeadImages/logo-element.png" alt="Idea Logo" class="ideaLogo" height="75" style="padding: 10px;"/>
                </a>
                <button id="navbarToggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent" data-bs-auto-close="true">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 d-flex align-items-center justify-content-end pt-2">
                    <li class="nav-item mt-3 mt-md-0 mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;">
                      <a :class="{ 'active-link': $route.path === '/AboutPage' }" class="test text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/AboutPage">Hakkında</a>
                    </li>
                    <li class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;">
                      <a :class="{ 'active-link': $route.path === '/programDetail' }" class="text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/programDetail">Program</a>
                    </li>
                    <li class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;">
                      <a :class="{ 'active-link': $route.path === '/blog' }" class="text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/blog">Blog</a>
                    </li>
                   <li class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;" v-if="!authStore.isLoggedIn">
                      <a :class="{ 'active-link': $route.path === '/contact-logout-view' }" class="text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/contact-logout-view">Bize Ulaşın</a>
                   </li>
                   <li class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;" v-if="authStore.isLoggedIn">
                      <a :class="{ 'active-link': $route.path === '/contact-view' }" class="text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/contact-view">Bize Ulaşın</a>
                   </li>
                   <li class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 down_md2_margin_bottom" style="margin-right:3rem;" v-bind:key="6">
                      <a :class="{ 'active-link': $route.path === '/sikca-sorulan-sorular' }" class="text-decoration-none fw-bold text-dodgerBlue-color font-size-5" href="/sikca-sorulan-sorular">S.S.S</a>
                   </li>
                   <li  v-if="authStore.isLoggedIn" class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 up_992_display_none" style="margin-right:3rem;">
                      <a href="/programs" class="dropdown-item px-0" style="color:#0D6EE9 !important;">Yeni Başvuru Formu</a>
                    </li>
                   <li  v-if="authStore.isLoggedIn" class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 up_992_display_none" style="margin-right:3rem;">
                      <a href="/my-ideas" class="dropdown-item px-0" style="color:#0D6EE9 !important;">Fikirlerim</a>
                    </li>
                    <li  v-if="authStore.isLoggedIn" class="nav-item mb-2 down-lg-w-100 down-md-mr-none down-lg-ps down_md_pl_0 up_992_display_none" style="margin-right:3rem;">
                      <a href="/my-profile" class="dropdown-item px-0" style="color:#0D6EE9 !important;" >Profilim</a>
                    </li>
                    <li  v-if="authStore.isLoggedIn" class="down-lg-w-100 mb-2 down-md-mr-none down-lg-ps down_md_pl_0 up_992_display_none mb-3" style="margin-right:3rem;">
                      <a href="/logout" class="btn px-0 text-dodgerBlue-color border-0" :class="{ 'active-link': $route.path === '/logout' }">Çıkış Yap</a>
                    </li>
                    <div class="down_md_left down_992_width_full">
                      <li v-if="!authStore.isLoggedIn"  class="mb-1 down-md-mr-none  down_md_pl_0 mb-3 d-flex align-items-center justify-content-center" style="margin-right:3rem;width:150px!important;height:35px !important;">
                      <button :class="{ 'active-link': $route.path === '/login' }" v-if="!authStore.isLoggedIn" @click="goToLogin()" type="submit" class="h-100 w-100 d-flex align-items-center justify-content-center px-0 py-0 btn btn-primary text-decoration-none fw-bold font-size-5">Şimdi Başvur!</button>
                      <a :class="{ 'active-link': $route.path === '/login' }" v-if="authStore.isLoggedIn" type="submit" class="h-100 w-100 d-flex align-items-center justify-content-center px-0 py-0 btn btn-primary text-decoration-none fw-bold font-size-5" href="/programs">Şimdi Başvur!</a>
                    </li>
                    </div>

                </ul>
                <div class=" down_992_display_none" style="position:relative;">
                   <div v-if="authStore.isLoggedIn" class="dropdown menu_dropdown d-flex">
                  <button class="btn dropdown-toggle d-flex d-flex align-items-center border-0 pe-4" style="color:#0D6EE9 !important;" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ authStore.user }}
                    <i class="fa-solid fa-user-large ms-2"></i>
                  </button>
                  <ul class="dropdown-menu border-0 bg-white rounded-20 shadow px-3" aria-labelledby="dropdownMenuButton1" style="margin-left:-42px;left:0 !important;min-width:182px !important;width:-webkit-fill-available !important;">
                    <li>
                      <a href="/programs" class="dropdown-item p-0 py-1" style="color:#0D6EE9 !important;">Yeni Başvuru Formu</a>
                    </li>
                    <li>
                      <a href="/my-ideas" class="dropdown-item p-0 py-1" style="color:#0D6EE9 !important;">Fikirlerim</a>
                    </li>
                    <li>
                      <a href="/my-profile" class="dropdown-item p-0 py-1" style="color:#0D6EE9 !important;" >Profilim</a>
                    </li>
                    <li class="mb-4">
                      <a href="/logout" class="dropdown-item p-0 py-1" type="submit" style="color:#0D6EE9 !important;">Çıkış Yap</a>
                    </li>
                  </ul>
                </div>
                </div>

              </div>
        </nav>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useRoute } from 'vue-router'
import Button from "@/components/Cookie/Shared/Button.vue";
import {useToast} from "vue-toastification";

export default defineComponent({
  components: {Button},
  data() {
    return {
      selectedIndex: null
    };
  },
  mounted() {
    this.loadSelectedItem();
  },
  setup () {
    const route = useRoute()
    const authStore = useAuthStore()
    const toast = useToast()
    const goToLogin = () => {
      localStorage.setItem('redirectToUrl', '/programs')
      toast.error('Bu sayfaya erişebilmek için giriş yapmalısınız')
      authStore.logout()
    }
    return {
      authStore,
      goToLogin
    }
  },
  methods: {
    selectItem(index) {
      this.selectedIndex = index;
      localStorage.setItem('selectedIndex', index)
    },
    loadSelectedItem() {
    const selectedItem = localStorage.getItem('selectedIndex');
    if (selectedItem !== null) {
      this.selectedIndex = parseInt(selectedItem);
    }
  }
  }
})
</script>
<style lang="scss" scoped>
.active-link {
  border-bottom: 2px solid #C1E600;
}
a{
  padding-bottom: 0.3rem;
}
a:hover{
    font-weight: bold !important;
    text-shadow: 0.1px 0.1px #402661;
}
.dropdown-item:hover{
  border-radius:10px !important;
}
.nav-item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #C1E600;
    transition: width .3s;
}

.nav-item:hover::after {
    width: 100%;
    //transition: width .3s;
}
.my-list--selected::after {
  height: 2px;
  background: #C1E600;
  width: 100%;
}
.sticky-top {
    position: fixed !important;
    top: 0 !important;
    margin: 0 !important;
    z-index: 1000 !important;
    background-color: white;
}

</style>
