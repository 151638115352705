import { acceptHMRUpdate, defineStore } from 'pinia'
import LeaderService from '@/services/leader.service'
import { useRoute } from 'vue-router'

export const useLeaderStore = defineStore({
  id: 'leaderStore',
  state: () => ({
    leaderboards_list: [],
    route: useRoute()
  }),
  setup () { },
  actions: {
    getLeaderInfo () {
      let eventUId = 'all'
      if (this.route.params.eventUId !== undefined && this.route.params.eventUId !== null) {
        eventUId = this.route.params.eventUId
      }
      return LeaderService.getLeaderInfo(eventUId).then(
        response => {
          this.leaderboards_list = response.data.data.leaderboards_list
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLeaderStore, import.meta.hot))
}
