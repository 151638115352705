<template>
<div class="container">
   <div class="row up-lg-mx up-lg-px up-md-pt-pb down-md-mb-mt">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 down-md-mb d-flex align-items-center">
        <img src="@/assets/LeadImages/img-7.png" class="img-fluid">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 up-lg-ps d-flex align-items-center">
      <div class="w-100">
        <div class="mb-2">
          <FormTextSlot class="m-0 text-midnightBlue-color font-size-11 fw-bold down-md-fs-3" form-text="Giriş Yap"></FormTextSlot>
        </div>
        <div class="mb-4">
          <p class="m-0 text-gray-color font-size-6 fw-500"> Program sadece Yaşar Topluluğu çalışanlarına açık olup, sisteme iş e-posta adresi ve bilgisayar şifreniz ile giriş yapabilirsiniz.</p>
        </div>
        <!--
       <Form></Form>
       <form @submit="onSubmit">
         <div class="form-outline mb-4 text-start">
           <label for="username" class="mb-1">Kullanıcı Adı</label>
           <field id="username" name="username" placeholder="Kullanıcı Adınızı Yazınız" class="form-control shadow-none" />
           <span class="red bs">{{ errors.username }}</span>
           <span class="red" v-if="errorMessageField === 'username'">{{ errorMessage }}</span>
         </div>
         <div class="form-outline mb-4 text-start">
           <label for="password" class="mb-1">Şifre</label>
           <field id="password" name="password" placeholder="Parolanızı Yazınız" class="form-control shadow-none" type="password"/>
           <span class="red">{{ errors.password }}</span>
           <span class="red" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
         </div>
         <div class="d-flex align-items-center justify-content-start">
           <div class="pt-1 mb-4 text-end">
             <ButtonSlot button-text="Giriş Yap"></ButtonSlot>
           </div>
         </div>
         <div v-if="registerButtonVisibility" class="text-start mt-4">
           <router-link to="/forgot-password" class="purple2 text-decoration " style="color:#402661 !important;font-weight:500!important;">*Şifremi Unuttum</router-link>
         </div>

          <div v-if="registerButtonVisibility" class="form_footer d-flex text-start pt-3">
            <p class="me-2 fw-normal black">Hesabınız yok mu? <a href="/register" class="text-decoration" style="color:#402661!important;font-weight:500!important;">Kayıt Ol</a></p>
          </div>
        </form>

        <div class=" mb-3 text-center">
          veya
        </div>
-->
        <div class="pt-1 mb-4" v-if="(configStore.data.KEYCLOAK_AUTHENTICATION_ENABLED)">
          <button @click="authStore.oauthStart('keycloak')" type="button" class="btn btn-primary">Yaşar ile Giriş</button>
        </div>
      </div>
    </div>
   </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { useConfigStore } from '../../store/config'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Giriş Yap'
      if (window.location.hostname !== configStore.data.VUE_APP_INTERNAL_DOMAIN) {
        registerButtonVisibility.value = true
      }
    })
    const registerButtonVisibility = ref(false)
    const router = useRouter()
    const configStore = useConfigStore()
    const authStore = useAuthStore()
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const schema = yup.object().shape({
      username: yup.string().required('*Kullanıcı Adınızı Yazınız'),
      password: yup.string().required('*Şifrenizi Yazınız').min(6, '*En az 6 karakter girmelisiniz')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      resetErrorMessage()
      authStore.login(values).then(
        response => {
          if (response.status === 200) {
            if (response.data.redirect && response.data.redirect !== []) {
              if (response.data.redirect.endpoint === 'renew_password') {
                // Şifre yenileme sayfasına yönlendiriyor
                const token = response.data.redirect.additionalData.reset_token
                router.push('/renew-password/' + token)
              }
            } else {
              const redirectToUrl = localStorage.getItem('redirectToUrl')
              if (redirectToUrl === undefined || redirectToUrl === null) {
                router.push('/programs')
              } else {
                router.push(redirectToUrl)
              }
            }
            localStorage.removeItem('redirectToUrl')
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })
    return {
      configStore,
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField,
      registerButtonVisibility
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

.form-control::placeholder{
  color: #6F7273 !important;
  font-weight:500 !important;
}
</style>
