import axios from 'axios'
import { useAuthStore } from '../store/auth'
import { useToast } from 'vue-toastification'

const toast = useToast()
const lastErrorMessage = ''

// const BASE_URL = (process.env.VUE_APP_API_BASE_URL !== undefined) ? process.env.VUE_APP_API_BASE_URL + '/api' : 'https://localhost/api'
// axios.defaults.baseURL = BASE_URL

axios.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const authStore = useAuthStore()
    const originalRequest = error.config
    if (error.response.status === 401 && error.response.data.message === 'Expired JWT Token') {
      console.log(error.response.data.message)
    }

    if (
      (error.response.status === 401 || error.response.status === 404) &&
      originalRequest.url.includes('api/token/refresh')
    ) {
      authStore.logout()
      return Promise.reject(error)
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await authStore.renewToken()
      originalRequest.headers.Authorization = `Bearer ${authStore.token}`
      return axios(originalRequest)
    }
    return Promise.reject(error)
  }
)

export class BaseService {
  getFullUrl (path) {
    return axios.defaults.baseURL + path
  }

  getHeaders () {
    const authStore = useAuthStore()
    let headers = { 'Content-Type': 'application-json' }
    if (undefined !== authStore.getToken() && authStore.getToken() !== null) {
      headers = { ...headers, ...{ Authorization: `Bearer ${authStore.getToken()}` } }
      return { headers }
    }
    return headers
  }

  handleSuccess (result) {
    if (result.data.messageType === 'toast') {
      const resultMessage = (result && result.data && result.data.message) ||
        result.data.detail ||
        result.message ||
        result.toString()
      if (resultMessage === '[object Object]') return
      toast.success(resultMessage)
    }
  }

  handleError (error) {
    let errorMessage = ''
    if (undefined !== error.response && undefined !== error.response.data) {
      if (undefined !== error.response.data.message && error.response.data.messageType === 'toast') {
        errorMessage = error.response.data.message
      } else if (undefined !== error.response.data.detail) {
        // errorMessage = error.response.data.detail
      }
    } else {
      if (undefined !== error.message) {
        // errorMessage = error.message
      } else if (error.toString()) {
        // errorMessage = error.toString()
      }
    }

    if (errorMessage !== '') {
      if (errorMessage === 'Missing JWT Refresh Token') errorMessage = 'Lütfen kullanıcı bilgilerinizi kontrol ediniz.'
      if (errorMessage === 'Invalid credentials.') errorMessage = 'Kullanıcı bilgileriniz hatalı.'

      if (
        errorMessage !== lastErrorMessage &&
        errorMessage !== 'Internal Server Error' &&
        errorMessage !== 'Undefined error!' &&
        errorMessage !== 'Request failed with status code 400' &&
        errorMessage !== 'Invalid credentials.' &&
        errorMessage !== 'Missing JWT Refresh Token' &&
        errorMessage !== 'Invalid JWT Token'
      ) {
        toast.error(errorMessage)
        // lastErrorMessage = errorMessage
      } else {
        console.log('Service error:', errorMessage)
      }
    }
    return error
  }

  get (path, params = null, useHeaders = true) {
    let options
    if (useHeaders) options = { ...this.getHeaders(), ...{ params } }
    else options = params
    return axios
      .get(this.getFullUrl(path), options).then(
        response => {
          this.handleSuccess(response)
          return response
        },
        error => {
          this.handleError(error)
          return error
        }
      ).catch(
        error => {
          this.handleError(error)
          return error
        }
      )
  }

  post (path, formData) {
    return axios
      .post(this.getFullUrl(path), formData, this.getHeaders()).then(
        response => {
          this.handleSuccess(response)
          return response
        },
        error => {
          this.handleError(error)
          return error
        }
      ).catch(
        error => {
          this.handleError(error)
          return error
        }
      )
  }

  put (path, formData) {
    return axios
      .put(this.getFullUrl(path), formData, this.getHeaders()).then(
        response => {
          this.handleSuccess(response)
          return response
        },
        error => {
          this.handleError(error)
          return error
        }
      ).catch(
        error => {
          this.handleError(error)
          return error
        }
      )
  }

  delete (path, deleteItem) {
    return axios
      .delete(this.getFullUrl(path + '/' + deleteItem), this.getHeaders()).then(
        response => {
          this.handleSuccess(response)
          return response
        },
        error => {
          this.handleError(error)
          return error
        }
      ).catch(
        error => {
          this.handleError(error)
          return error
        }
      )
  }
}
