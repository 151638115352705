<template>
    <div class="container mb-5">
            <div class="row">
                <div class="col-12 mb-4">
                    <p class="m-0 text-center font-size-11 text-aubergine-color fw-bold down-md-fs-5">İnovasyon Odak Alanlarımız</p>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex justify-content-center down-sm-mb-2" style="margin-bottom:3rem;" v-for="sectionItem in section.items" :key="sectionItem">
                       <div class="text-center bg-purple-color rounded-5 py-4 w-100">
                        <div class="mb-4">
                            <img :src="sectionItem.coverImg" height="74" alt="Girisimcilik Egitimi">
                        </div>
                        <div class="d-flex h-75  justify-content-space-between flex-wrap">
                            <div class="px-4 text-start mb-2">
                                <p class="m-0 font-size-6 text-left text-aubergine-color fw-bold">
                                {{sectionItem.title}}
                                </p>
                            </div>
                            <div class="text-left mt-2 px-2 down-md-height-2" style="height: 280px; overflow-y: scroll;text-align:left;">
                                <p class="m-0 font-size-5 text-aubergine-color fw-normal text-left" style="line-height:1.5rem;" v-html="sectionItem.content"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import {defineComponent, onMounted} from 'vue'
import { useAuthStore } from '../../store/auth'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props,context) {
    onMounted(() => {
      setTimeout(function () {
        context.emit('isReady', {})
      }, 200)
    })
    const authStore = useAuthStore()
    return {
      authStore
    }
  }
})
</script>
<style scoped>
::-webkit-scrollbar {
  width: 5px; /* Scroll barı genişliği */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scroll barın arka plan rengi */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Scroll bar rengi */
}
</style>

