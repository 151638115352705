import { BaseService } from './BaseService'
const APPLICATIONS_PATH = '/v1/user-profile/applies'

class ApplicationsService extends BaseService {
  getApplications () {
    return this.get(APPLICATIONS_PATH)
  }
}

export default new ApplicationsService()
