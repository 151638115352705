<template>
    <div class="container mt-xl-5 mt-lg-5 mt-3">
        <div class="row mb-xl-5 mb-lg-5 mb-3">
            <div class="col-12 mb-3">
                <img src="@/assets/LeadImages/program-detail.png" class="img-fluid rounded-5">
            </div>
                <div class="col-12 my-xl-4 my-lg-4 my-2 text-center">
                <h2 class="text-blueDark-color font-size-11 down-md-fs-5">Yaşar İDEA Kurum İçi Girişimcilik Programı</h2>
            </div>
            <div class="col-xl-4 col-lg-4 col-12 pe-xl-3 pe-lg-3 pe-2">
                <p class="fw-bold font-size-8 pe-xl-4 pe-lg-4 pe-0 down_md2_font1">Yaşar İDEA Kurum İçi Girişimcilik Programı, Yaşar Topluluğu’nun inovasyon, büyüme ve rekabet gücünü artırmak amacıyla kurum çalışanlarının yeni fikir ve iş modelleri geliştirmesini teşvik eden bir girişimcilik programıdır.
                </p>
            </div>
            <div class="col-xl-8 col-lg-8 col-12 font-size-6 fw-normal">
                <p class="mb-0">Yaşar İDEA ile Topluluk çalışanlarımıza yaratıcı ve yenilikçi fikirlerini start-up metodolojileri ile hayata geçirme imkanı veriyoruz. Program kapsamında kurum içi girişimcilerimizi 14 haftalık yoğun bir eğitim ve mentorluk programı ile destekliyoruz. Hızlandırma sürecinde bir yandan tasarım odaklı düşünme, müşteriyi anlama, ihtiyaçlara yönelik çözüm geliştirme, sürdürülebilir değer yaratma, pazar ve rekabet analizi gibi konularda gelişmelerini; diğer yandan yenilikçi çalışma modellerini deneyimlemelerini sağlıyoruz.
                </p>
                <p>Belirlediğimiz inovasyon odak alanlarında Topluluğumuzu ileriye taşıma potansiyeline sahip takımlara ise MVP geliştirmelerini yapacakları ve ürün veya hizmetlerini satışa hazır hale getirecekleri 20 haftalık benzersiz bir süreç deneyimi sunuyoruz.
                </p>
                <p>Ayrıca MVP aşamasına geçmelerinden bağımsız olarak tüm kurum içi girişimcilerimizi Yaşar Topluluğu içerisinde “inovasyon elçisi” olarak görevlendiriyor, Program’ın ileriki dönemlerinde girişimci koçu ve/veya mentor olarak konumlandırıyor; böylece Program’ın döngüsel bir şekilde beslenmesini sağlıyoruz.
                </p>
            </div>
        </div>
        <div class="row mb-xl-5 mb-lg-5 mb-3 mt-2">
            <div class="col-12">
                <h3 class="font-size-11 down-md-fs-5">Kimler Başvurabilir?</h3>
                <p class="m-0 font-size-6">
                Yaşar İDEA Kurum İçi Girişimcilik Programı, Yaşar Topluluğu çalışanlarına yönelik düzenlenmektedir. Topluluk genelinde, belirlenen inovasyon odak alanlarında, yenilikçi bir fikre sahip olan ve/veya kurum içi girişimcilikle ilgilenen ve süreci deneyimlemek isteyen, her yaştan ve mevkiden beyaz yaka çalışanlar, Program’a bireysel olarak başvuru yapabilir. Bir başka deyişle, Program’a belirlenen inovasyon odak alanlarına yönelik fikir geliştirenlerin yanı sıra herhangi bir fikre sahip olmayan ancak takımlarda yer alarak kurum içi girişimciliği deneyimlemek ve bu alanda yetkinlik geliştirmek isteyen beyaz yaka çalışanlarımız da başvurabilirler. Başvuru sayfasına girdiğinizde fikir sahipleri ve takım üyesi olmak isteyenler için ayrı formlar göreceksiniz. Size uyan formu doldurarak, başvurunuzu gerçekleştirebilirsiniz.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <inovation-view></inovation-view>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import InovationView from '../../components/landing/InovationProcess.vue'
export default defineComponent({
  setup () {},
  components: {
    InovationView
  }
})
</script>
<style lang="scss" scoped>

</style>