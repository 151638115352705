<template>
<div class="container">
    <div class="row up-lg-mx up-lg-px up-md-pt-pb down-md-mb-mt">
      <div class="col-xl-6 col-lg-6 col-md-6 col-12 down-md-mb d-flex align-items-center">
        <img src="@/assets/LeadImages/img-9.png" class="img-fluid">
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-12 up-lg-ps d-flex align-items-center">
        <div class="w-100">
          <div class="mb-2">
            <FormTextSlot class="m-0 text-midnightBlue-color font-size-11 fw-bold down-md-fs-3" form-text="Kayıt Ol"></FormTextSlot>
          </div>
          <div class="mb-4">
            <p class="m-0 text-gray-color font-size-6 fw-500">Contrary to popular belief, Lorem Ipsum is not simply </p>
          </div>
          <Form></Form>
          <form @submit="onSubmit">
            <div class="mb-4 up-lg-input-width">
            <div class="form-outline mb-3 text-start">
              <field id="name" name="name" placeholder="Ad" class="form-control shadow-none" />
              <span class="red text-gray-color font-size-2">{{ errors.name }}</span>
            </div>
            <div class="form-outline mb-3 text-start">
              <field id="surname" name="surname" placeholder="Soyad" class="form-control shadow-none" />
              <span class="red text-gray-color font-size-2">{{ errors.surname }}</span>
            </div>
            <div class="form-outline mb-3 text-start">
              <field id="email" name="email" placeholder="Mail Adresi" class="form-control shadow-none" />
              <span class="red text-gray-color font-size-2">{{ errors.email }}</span>
              <span class="red text-gray-color font-size-2" v-if="errorMessageField === 'email'">{{ errorMessage }}</span>
            </div>
            <div class="form-outline mb-4 text-start">
              <field id="password" name="password" placeholder="Şifre" class="form-control shadow-none" type="password"/>
              <span class="red text-gray-color font-size-2">{{ errors.password }}</span>
              <span class="red text-gray-color font-size-2" v-if="errorMessageField === 'password'">{{ errorMessage }}</span>
            </div>
            </div>
            <div class="col-12 mb-3">
              <ButtonSlot button-text="Kayıt Ol" class="me-2"></ButtonSlot>
              <a href="/login" class="font-size-3 text-green-color fw-bold text-decoration-none">Giriş Yap</a>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Kayıt Ol'
    })
    const authStore = useAuthStore()
    const router = useRouter()
    const nameRegExp = /^([A-Za-z" "çğjöşüÇĞJÖŞÜİı]+)?$/
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Soyadınızı Yazınız').matches(nameRegExp, '*Lütfen Geçerli Bir Ad Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız').matches(nameRegExp, '*Lütfen Geçerli Bir Soyad Yazınız'),
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email('Lütfen Geçerli Bir E-Posta Adresi Yazınız'),
      password: yup.string().required('*Lütfen Şifrenizi Yazınız').min(6, 'En az 6 karakter girmelisiniz'),
    })
    const errorMessage = ref(null)
    const errorMessageField = ref(null)
    const resetErrorMessage = () => {
      errorMessage.value = ''
      errorMessageField.value = ''
    }
    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      resetErrorMessage()
      authStore.signUp(values).then(
        response => {
          if (response.status === 200) {
            router.push('/login')
          } else {
            if (response.response.data.messageType === 'formInput') {
              errorMessage.value = response.response.data.message
              errorMessageField.value = response.response.data.messageField
            }
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors,
      errorMessage,
      errorMessageField
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

.form-control::placeholder{
  color: #6F7273 !important;
  font-weight:500 !important;
}
</style>
