<template>
        <div class="container">
            <div class="row down_992_margin_none down_992_padding_bottom_none down_992_padding_top_none" style="margin-top:3rem;margin-bottom:3rem;padding-top:3rem;padding-bottom:3rem;">
                <div class="col-xl-6 col-lg-6 col-12 m-auto py-5 pe-xl-5 pe-lg-5 pe-3 down-md2-border-right" style="border-right: solid 1px #C1E600;">
                    <div>
                        <div class="text-center text-md-end">
                            <p class="m-0 text-aubergine-color fw-bold" style="font-size:40px;">Yaşar İDEA.</p>
                        </div>
                        <div class="text-center text-md-end">
                            <p class="m-0 text-aubergine-color font-size-11 fw-bold">Gelecek seninle burada!</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12 ps-xl-5 ps-lg-5 ps-3">
                    <div class="mb-4">
                        <p class="m-0 font-size-6 text-aubergine-color fw-bold">İdea. Herşeyin başlangıcı. Tertemiz beyaz bir sayfa.<br>Bir fikir, bir yolculuk, bir hedef…</p>
                    </div>
                     <div class="mb-4">
                        <p class="m-0 font-size-6 text-aubergine-color fw-bold">İdea. Hem gelenek, hem gelecek.</p>
                    </div>
                    <div class="mb-4">
                        <p class="m-0 font-size-6 text-aubergine-color fw-bold">Hayalini kurdukların, gelecek için yapacakların…<br>Bir fikir, bir ışık, bir kıvılcım…  Hepsi sende var, biliyoruz.<br>Hepsini duyuyor, hepsini görüyor, heyecanını destekliyoruz.</p>
                    </div>
                    <div>
                        <p class="m-0 font-size-6 text-aubergine-color fw-bold">Yaşar’ın idealistlerini İdea’ya ışık olmaya bekliyoruz.</p>
                    </div>
                </div>
            </div>
            <div class="row mb-xl-5 mb-lg-5 mb-0 pb-5 down-md-pl-pr-none down-md2-pl-pr-none" style="padding-left:3rem;padding-right:3rem;">
                <div class="col-12 text-center mb-xl-5 mb-lg-5 mb-0">
                    <p class="m-0 text-aubergine-color font-size-6 fw-normal down-md2-text-left">
                    Özümüzden gelen inovatif ve girişimci ruhumuzla 79 yıldır durmadan çalışıyoruz. Herkese daha iyi bir yaşam sunma vizyonuyla, tüketicilerimizin yaşamına değer katan yenilikçi ürünler ve hizmetler geliştiriyoruz.
                    İnovasyon ve girişimcilik çalışmalarımızı tek bir platformda buluşturduğumuz Yaşar İDEA ile geleceği resmetme potansiyeline sahip büyük fikirler ile yaratıcı ve vizyoner insan kaynağımızı bir araya getirerek topluluğumuzu daha da ileriye taşımayı hedefliyoruz.</p>
                </div>
            </div>
        </div>
</template>

