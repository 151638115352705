<template>
  <LandingExternalView/>
</template>
<script>
import { defineComponent } from 'vue'
import LandingExternalView from '@/views/landing/LandingExternalView'

export default defineComponent({
  setup () {
    return {
    }
  },
  components: {
    LandingExternalView
  }
})
</script>
