<template>
  <h5 class="text-start">{{ formText }}</h5>
</template>
<script>
export default {
  props: {
    formText: {
      type: String,
      default: () => 'Default'
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
