import { BaseService } from './BaseService'
const FEED_INDEX = '/v1/feed'
const FEED_DETAIL = '/v1/feed-detail'

class FeedService extends BaseService {
  feed (eventUId = 'all', filter, paging) {
    return this.post(FEED_INDEX + '/' + eventUId, { filter, paging })
  }

  singleFeed (applyUId) {
    return this.get(FEED_DETAIL + '/' + applyUId)
  }
}

export default new FeedService()
