<template>
<div class="container">
   <div v-if="kvkkStore.kvkkInfo.length != 0" class="row my-5">
        <div class="col-12 text-start mb-3">
            <h3>{{kvkkStore.kvkkInfo.title}}</h3>
        </div>
        <div class="col-12" v-html="kvkkStore.kvkkInfo.content"></div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useKvkkStore } from '../../store/kvkk'

export default defineComponent({
  setup () {
    const kvkkStore = useKvkkStore()
    kvkkStore.getKvkkInfo()
    return {
      kvkkStore
    }
  },
  components: { }
})
</script>
