<template>
   <div class="container">
      <div id="carouselExample" class="carousel slide mb-xl-5 mb-lg-5 mb-3">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="down_md_none">
              <!-- Web Banner -->
              <img src="@/assets/LeadImages/banner-1.webp" class="d-block w-100" alt="..." loading="lazy">
            </div>
            <div class="up-md-none">
              <!-- Mobile Banner -->
              <img src="@/assets/LeadImages/mobil1.webp" class="d-block w-100" style="border-radius:30px !important;"  alt="..." loading="lazy">
            </div>
          </div>
          <div class="carousel-item">
            <div class="down_md_none">
              <!-- Web Banner -->
              <img src="@/assets/LeadImages/banner-2.webp" class="d-block w-100" alt="..." loading="lazy">
            </div>
            <div class="up-md-none">
              <!-- Mobile Banner -->
              <img src="@/assets/LeadImages/mobil2.webp" class="d-block w-100" style="border-radius:30px !important;"  alt="..." loading="lazy">
            </div>
          </div>
        </div>
        <!-- Carousel controls -->
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <svg style="fill:#0D6EE9 !important;height:40px;margin-left:5px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <svg style="fill:#0D6EE9 !important;height:40px;margin-right:5px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
  </button>
      </div>
       <carousel :items-to-show="1" :touchDrag="false" :wrap-around="true" :autoplay="10000" class="home_banner_slider d-none" style="margin-top:-30px;">
          <slide v-for="sectionItem in section.items" :key="sectionItem">
            <div class="container-fluid px-0 mb-2 down-md-pb-none down_md_none" v-if="sectionItem.title == null" v-bind:style="{ backgroundImage: 'url(' + sectionItem.coverImg + ')' }" style="background-size: cover !important;background-repeat: no-repeat;width: 100%;height: 74vh;background-position: left top !important;">
              <div class="container">
                <div @click="goTolink(sectionItem.video,sectionItem.link)" class="row h-100" :class="( sectionItem.link != '' || sectionItem.video != '' ) ? 'cursor-pointer' : ''">
                  <div class="col-xl-5 col-lg-9 col-12 d-flex align-items-center h-100">
                    <div>
                    <div class="d-block text-start mb-3">
                      <p class="m-0 font-size-13 text-aubergine-color fw-bold down-md-fs-3">{{sectionItem.title}}</p>
                    </div>
                    <div class="d-block text-start mb-5">
                      <p class="m-0 font-size-11 text-aubergine-color fw-500" v-html="sectionItem.content"></p>
                    </div>
                    <!--
                    <div class="d-block text-start">
                      <p class="m-0 text-blueDark-color font-size-7 fw-bold">Yaşar İDEA</p>
                      <p class="m-0 text-aubergine-color font-size-9 fw-500">Gelecek seninle burada!</p>
                    </div>
                    -->
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-3 col-12 h-100"></div>
                </div>
              </div>
            </div>
             <div class="container-fluid px-0 mb-2 down-md-pb-none up-md-none" v-if="sectionItem.title == null" v-bind:style="{ backgroundImage: 'url(' + sectionItem.mobileCoverImg + ')' }" style="background-size: cover !important;background-repeat: no-repeat;width: 100%;height: 60vh;background-position:top !important;">
              <div class="container">
                <div @click="goTolink(sectionItem.video,sectionItem.link)" class="row" :class="( sectionItem.link != '' || sectionItem.video != '' ) ? 'cursor-pointer' : ''">
                  <div class="col-xl-5 col-lg-9 col-12 d-flex align-items-center h-50">
                    <div>
                    <div class="d-block text-start mb-3">
                      <p class="m-0 font-size-13 text-aubergine-color fw-bold down-md-fs-3">{{sectionItem.title}}</p>
                    </div>
                    <div class="d-block text-start mb-5">
                      <p class="m-0 font-size-11 text-aubergine-color fw-500" v-html="sectionItem.content"></p>
                    </div>
                    <!--
                    <div class="d-block text-start">
                      <p class="m-0 text-blueDark-color font-size-7 fw-bold">Yaşar İDEA</p>
                      <p class="m-0 text-aubergine-color font-size-9 fw-500">Gelecek seninle burada!</p>
                    </div>
                    -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="container-fluid" v-if="sectionItem.title != null">
              <div @click="goTolink(sectionItem.video,sectionItem.link)" class="row w-100 m-0" :class="( sectionItem.link != '' || sectionItem.video != '' ) ? 'cursor-pointer' : ''">
                <div class="col-12 p-0">
                  <img :src="sectionItem.coverImg" class="img-fluid down_md2_font4 w-100 rounded-10" alt="landing image">
                </div>
              </div>
            </div>
          </slide>
          <template #addons>
            <div class="touch-blocker w-100" style="height: 100%;"> </div>
            <Navigation />
            <Pagination />
          </template>
      </carousel>
  </div>
  <!-- Video Modal -->
  <div class="modal fade" id="videoModalModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true" data-bs-backdrop='static'>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 rounded-30 border-0">
          <div class="modal-header border-0 p-1">
            <button @click="closeVideo()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" id="videoModalBody"></div>
        </div>
      </div>
    </div>
  <button type="button" data-bs-toggle="modal" data-bs-target="#videoModalModal" id="openVideoModal" class="d-none"></button>
</template>
<script>
import {defineComponent, onMounted} from 'vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props,context) {
    onMounted(() => {
      setTimeout(function () {
        context.emit('isReady', {})
      }, 200)
    })
    const goTolink = (video, link) => {
      if (video != null && video !== '') {
        document.getElementById('videoModalBody').innerHTML = '<video width="400" height="230" controls style="max-width: 100%"><source src="' + video + '" type="video/mp4"></video>'
        document.getElementById('openVideoModal').click()
      } else {
        if (link != null && link !== '') {
          window.location.href = link
        }
      }
    }
    const closeVideo = (video, link) => {
      document.getElementById('videoModalBody').innerHTML = ''
    }

    return {
      goTolink,
      closeVideo
    }
  },
  components: {
    Pagination,
    Navigation,
    Carousel,
    Slide
  }
})
</script>

<style>
.carousel-control-prev, .carousel-control-next{
  opacity: 1 !important;
  width:8% !important
}
.carousel-indicators{
  bottom: -11%;
}
.carousel-indicators button{
  border-radius: 100% !important;
  width: 10px !important;
  height: 10px !important;
  background-color:#D9D9D9 !important;
}
.carousel-indicators .active{
  background-color: #0D6EE9 !important;
}
.touch-blocker {
  position: absolute;
  top: 0;
}
@media (max-width: 766px) {
  .home_banner_slider {
    height: 60vh
  }
  .carousel-indicators{
  bottom: -0% !important;
}
}

@media (min-width: 767px) {
  .home_banner_slider {
    height: 70vh;
  }
}
@media only screen and (max-width:1200px){
  .carousel-indicators{
  bottom: -13%;
}
}
@media only screen and (max-width:992px){
  .carousel-indicators{
  bottom: -18%;
}
}
</style>
