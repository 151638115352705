<template>
	<div v-if="isActive.cookie" class="cookie">
		<div class="cookie--header">
			<h2>{{title}}</h2>
		</div>
		<div class="cookie--body">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-12">
          	<p>{{description}}</p>
        </div>
      </div>
		</div>
		<div class="cookie--footer">
			<ButtonComp v-for="button in buttonData" :button="button" ></ButtonComp>
		</div>
	</div>
	<div v-if="!isActive.cookie && !isActive.settings" @click="settingsHandler" class="cookie-bubble btn btn-secondary btn-sm px-4">Çerez Tercihleri</div>
	<CookieSettings v-if="isActive.settings" :isShow="isActive" :cookieSettings="cookieSettingsList"></CookieSettings>
</template>

<script setup>
import {computed, reactive} from "vue";
import ButtonComp from "./Shared/Button.vue";
import {setCookie,getCookie} from "@/utils/CookieFunc";
import CookieSettings from "@/components/Cookie/CookieSettings.vue";
import { useCookieStore } from '@/store/cookie'

const cookieStore = useCookieStore()

const title = "Daha iyi bir deneyim için izninizi rica ediyoruz.";
const description = "Bizimle paylaştığınız kişisel verilerinizin güvenliğine önem veriyoruz. Bu amaçla çerez verilerinizin hukuka uygun olarak işlenmesi ve korunması için azami hassasiyeti gösteriyoruz. Çerezlere ilişkin detaylı bilgiye Çerez Politikası’ndan ulaşabilirsiniz. “Çerez Ayarları” bölümünden tercihlerinizi yönetebilirsiniz.";
const rejectActive = true;
const cookieSettingsList = [
  {
    title: "Zorunlu çerezler",
    description: "Bunlar, web sitemizin işletilmesi için zorunlu çerezlerdir.",
    isActive: true,
    useSwitch: false,
  },
  {
    tag: "analyticalAndPerformanceCookies",
    title: "Analitik/performans çerezleri",
    description: "Ziyaretçileri tanımamızı, saymamızı ve ziyaretçilerimizin web sitemizin çevresinde nasıl hareket ettiklerini görmemizi sağlarlar.",
    isActive: cookieStore.cookie.analyticalAndPerformanceCookies,
    useSwitch: true,
  },
  {
    tag: "functionalityCookies",
    title: "İşlevsellik çerezleri",
    description: "Bunlar, web sitemize geri döndüğünüzde sizi tanımak için kullanılır.",
    isActive: cookieStore.cookie.functionalityCookies,
    useSwitch: true,
  },
  {
    tag: "targetingCookies",
    title: "Hedef tespit çerezleri",
    description: "Bu çerezler web sitemizi ziyaretinizi, ziyaret ettiğiniz sayfaları ve takip ettiğiniz bağlantıları kaydeder. Bu bilgileri, web sitemizi ve görüntülenen reklamları ilginize daha uygun hale getirmek için kullanacağız. Bu bilgileri bu amaçla üçüncü şahıslarla da paylaşabiliriz.",
    isActive: cookieStore.cookie.targetingCookies,
    useSwitch: true,
  },
];

const cookieActive = computed( () => {
  return getCookie("cookieShow") !== "false";
})

const isActive = reactive({
  cookie: cookieActive.value,
  settings: false
})

const acceptHandler = () => {
  cookieStore.cookie.analyticalAndPerformanceCookies = 1;
  cookieStore.cookie.functionalityCookies = 1;
  cookieStore.cookie.targetingCookies = 1;

  cookieStore.updateCookie().then(
    response => {
      if (response.status === 200) {
        isActive.cookie = false
        setCookie("cookieShow",false) // 10 minute to clear cookie
      }
    }
  );
};

const declineHandler = () => {
  cookieStore.cookie.analyticalAndPerformanceCookies = 0;
  cookieStore.cookie.functionalityCookies = 0;
  cookieStore.cookie.targetingCookies = 0;

  cookieStore.updateCookie().then(
    response => {
      if (response.status === 200) {
        isActive.cookie = false
        setCookie("cookieShow",false) // 10 minute to clear cookie
      }
    }
  );
};

const settingsHandler = () => {
  isActive.settings = true
  isActive.cookie = false
};

const buttonData = {
  accept : {
    text: "Tümünü Kabul Et",
    handler: acceptHandler
  },
  decline : {
    text: "Tümünü Reddet",
    active: rejectActive,
    handler: declineHandler,
  },
  settings : {
    text: "Çerez Ayarları",
    handler: settingsHandler
  }
}
</script>

<style lang="scss" scoped>
  .cookie-bubble{
    position: fixed;
    bottom: 42px;
    right: 20px;
    background-color: #6a6a6ae0;
  }
  .cookie{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #007FFF;
    border: 2px solid #007FFF;
    opacity: 0.9;
    border-radius: 0;
    position: fixed;
    bottom:0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.8s ease-in-out;
    animation: cookie 0.8s ease-in-out;

    &--header{
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      h2{
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &--body{
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      text-align: left;
      p{
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &--footer{
      width: 100%;
      display: flex;
      justify-content : start;
      align-items: center;
      font-size: 14px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

    }
  }

  @keyframes cookie {
    0%{
      bottom: -200px;
    }
    100%{
      bottom: 20px;
    }
  }
</style>
