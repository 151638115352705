import { BaseService } from './BaseService'
const POPULAR_INFO_PATH = '/v1/ideas/popular-list'

class PopularService extends BaseService {
  getPopularInfo (eventUId = 'all') {
    return this.get(POPULAR_INFO_PATH + '/' + eventUId)
  }
}

export default new PopularService()
