import { BaseService } from './BaseService'
const LEADER_INFO_PATH = '/v1/leaderboard/list'

class LeaderService extends BaseService {
  getLeaderInfo (eventUId = 'all') {
    return this.get(LEADER_INFO_PATH + '/' + eventUId)
  }
}

export default new LeaderService()
