<template>
  <div class="toggle">
    <div class="toggle--button" @click="toggleHandler()">
      <input :checked="!input" type="checkbox" class="toggle--checkbox">
      <div class="toggle--knobs">
        <span>Evet</span>
      </div>
      <div class="toggle--layer"></div>
    </div>
  </div>
</template>

<script setup >
  import { ref, toRefs } from 'vue';
  import {useCookieStore} from "@/store/cookie";

  const cookieStore = useCookieStore()

	const props = defineProps(["cookieSetting"])

	const {isActive} = toRefs(props.cookieSetting)

	const input = ref(isActive.value)

	const toggleHandler = () => {
		isActive.value = !isActive.value

    let status = 0;
    if(isActive.value) status = 1;

    if(props.cookieSetting.tag === 'analyticalAndPerformanceCookies') cookieStore.cookie.analyticalAndPerformanceCookies = status;
    if(props.cookieSetting.tag === 'functionalityCookies') cookieStore.cookie.functionalityCookies = status;
    if(props.cookieSetting.tag === 'targetingCookies') cookieStore.cookie.targetingCookies = status;
	}
</script>

<style lang="scss" scoped>

.toggle{
	&--button{
		position: relative;
		top: 50%;
		width: 80px;
		height: 30px;
		overflow: hidden;
		background-color: #b3b2b2;
		border: 1px solid #acacac;
	}

	&--checkbox{
		position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;

		&:checked + .toggle--knobs{

			&>span{
				color: #4e4e4e;
			}
		}

		&:checked + .toggle--knobs::before{
			left: 42px;
    	background-color: #F44336;
      height: 20px;
      width: 30px;
		}

		&:checked + .toggle--knobs::after{
			color: #fff;
		}

		&:checked + .toggle--layer{
			background-color: #F44336;
		}

	}

	&--knobs{
		z-index: 2;

		&>span{
			display: inline-block;
			left: 3px;
			z-index: 1;
		}

		&::before , &::after, &>span{
			position: absolute;
			top: 4px;
			width: 30px;
			height: 20px;
			font-size: 10px;
			font-weight: bold;
			text-align: center;
			line-height: .4;
			padding: 10px 5px;
			border-radius: 2px;
			transition: 0.3s ease all;
		}
		&::before{
			content: '';
			left: 4px;
			// background-color: #03A9F4;
			background-color: #c1e600;
      width: 30px;
      height: 20px;
		}
		&::after{
			content: '';
			right: 4px;
			color: #4e4e4e;
		}
	}
	&--layer{
		width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
	}
}



</style>
