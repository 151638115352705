<template>
  <div>
    <p class="font_2">Değerlendirme Durumu</p>
  </div>
  <div class="d-flex">
    <div>
      <div class="d-flex align-items-center">
        <div style="width: 20px;height: 20px;border-radius: 100%;background-color: #5f6281;"></div>
        <hr class="hr_line">
      </div>
      <div>
        <p style="font-size: 14px!important;color: #5f6281!important;font-weight: bold!important;">Başvuru<br>Yapıldı</p>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <div style="width: 20px;height: 20px;border-radius: 100%;background-color: #d9dbea;"></div>
        <hr class="hr_line">
      </div>
      <div>
        <p class="fw-bold" style="font-size: 14px;color: #989bba;">Juri<br>Puanladı</p>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <div style="width: 20px;height: 20px;border-radius: 100%;background-color: #d9dbea;"></div>
        <hr>
      </div>
      <div>
        <p class="fw-bold" style="font-size: 14px;color: #989bba;">Başvuru<br>Reddedildi</p>
      </div>
    </div>
  </div>
</template>

