<template>
    <div class="container mb-5">
        <!--
        <div class="row">
            <div class="col-12 up-md-padding-1 pb-0">
                <p class="text-aubergine-color font-size-11 fw-bold down-md-fs-5">
                   {{section.title}}
                </p>
            </div>
        </div>
        -->
        <div v-for="sectionItem in section.items" :key="sectionItem">
            <div class="row up-md-padding-1 py-xl-5 py-lg-5 py-4 bg-blueDark-color rounded-5 down-md-pl-pr-3 mx-1" style="padding-right:3rem;padding-left:3rem;">
                <div class="col-xl-4 col-lg-4 col-md-4 col-12 text-center mb-xl-0 mb-lg-0 mb-3 d-flex align-items-center justify-content-center">
                    <p class="m-0 text-white-color font-size-11 fw-bold down-md-fs-5 program-title" v-html="sectionItem.title"></p>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-8 col-12 text-left">
                    <p class="m-0 text-white-color fw-normal font-size-6 mb-3" v-html="sectionItem.content"></p>
                    <div class="apply_area down-sm-text-center">
                        <a href="/ProgramDetail" class="text-decoration-none me-2">
                        <button class="btn btn-primary down_md2_font2 mb-1" style="height: 35.5px !important;">
                            Devamını Oku
                        </button>
                        </a>
                        <a href="/book.pdf" target="_blank" class="btn btn-primary down_md2_font2 mb-1" style="height: 35.5px !important;">
                            Program Kitapçığı
                        </a>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent, onMounted} from 'vue'

export default defineComponent({
  props: {
    section: {}
  },
  setup (props,context) {
    onMounted(() => {
      setTimeout(function () {
        context.emit('isReady', {})
      }, 200)
    })
  }
})
</script>

