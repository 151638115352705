<template>
<div class="container">
     <div class="row mb-3">
        <div class="col-12 col-md-3">
            <left-list></left-list>
        </div>
        <div class="col-12 col-md-9">
          <notification-list/>
        </div>
</div>
     </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import LeftList from '@/components/bonapply/LeftList.vue'
import NotificationList from '@/components/bonapply/NotificationList'

export default defineComponent({
  name: 'NotificationsView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Bildirimler'
    })
  },
  components: {
    LeftList,
    NotificationList
  },
  methods: {

  }
})
</script>

