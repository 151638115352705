import { BaseService } from './BaseService'
const NOTIFICATION = '/v1/user/notification/list'

class NotificationService extends BaseService {
  getNotifications (paging) {
    return this.get(NOTIFICATION + '?page=' + paging.page + '&length=' + paging.length)
  }
}

export default new NotificationService()
