<template>
<div class="container mb-5 d-none">
  <div class="row up-md-padding-1">
    <div class="col-xl-4 col-lg-5 col-md-6 col-12 d-flex align-items-center pb-3">
      <div class="position-relative">
      <img src="@/assets/LeadImages/img-2.png" class="position-absolute" style="bottom:-40px !important;right:-40px !important;z-index:-99 !important;"/>
      <img src="@/assets/LeadImages/img-17.svg" class="img-fluid rounded-5 w-100">
      </div>
    </div>
    <div class="col-xl-8 col-lg-7 col-md-6 col-12 up-md-padding-1 d-flex align-items-center">
      <div class="w-100">
      <div>
        <FormTextSlot form-text="İletişim" class="m-0 text-aubergine-color font-size-7 fw-bold"></FormTextSlot>
      </div>
      <div class="my-3">
          <p class="m-0 text-gray-color font-size-5 fw-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et </p>
      </div>
      <Form></Form>
      <form @submit="onSubmit" class="mb-4">
        <div class="form-outline mb-3 text-start">
          <field id="name" name="name" placeholder="Ad" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-3 text-start">
          <field id="surname" name="surname" placeholder="Soyad" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.surname }}</span>
        </div>
        <div class="form-outline mb-3 text-start">
          <field id="email" name="email" placeholder="E-Posta Adresi" class="form-control shadow-none" />
          <span class="text-gray-color font-size-2">{{ errors.email }}</span>
        </div>
         <div class="form-outline mb-4 text-start">
          <field id="message" as="textarea" aria-rowcount="6" name="message" placeholder="Mesaj" row="5" class="form-control shadow-none" style="border-radius:30px !important;"/>
          <span class="text-gray-color font-size-2">{{ errors.message }}</span>
         </div>
        <div class="row">
          <div class="col-8 pe-0 d-flex align-items-center">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label text-aubergine-color down-md-fs-1 fw-bold" for="flexCheckDefault">
                            <!-- Button trigger modal -->
                        <button type="button" class="btn btn-transparent border-0 p-0 m-0 text-aubergine-color fw-bold text-decoration-underline down-md-fs-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        KVKK metnini
                        </button>
                        okudum, onaylıyorum.
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                ...
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        </label>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <ButtonSlot button-text="Gönder" class="btn-primary"></ButtonSlot>
                </div>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useContactStore } from '../../store/contact'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA'
    })
    const contactStore = useContactStore()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız'),
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email('Lütfen E-posta Adresinizi Kontrol Ediniz'),
      message: yup.string().required('*Lütfen Mesajınızı Yazınız').max(750, 'Maximum 750 karakter uzunluğunda mesaj yazabilirsiniz.')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, { resetForm }) => {
      contactStore.sendMessage(values)
      resetForm()
    })

    return {
      contactStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

</style>
