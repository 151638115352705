import { acceptHMRUpdate, defineStore } from 'pinia'
import notificationService from '@/services/notification.service'

export const useNotificationStore = defineStore({
  id: 'notificationStore',
  state: () => ({
    getData: true,
    paging: {
      page: 0,
      length: 30
    },
    notifications: [],
    headerNotifications: []
  }),
  setup () {},
  actions: {
    resetPaging () {
      this.getData = true
      this.paging.page = 0
    },
    getNotifications () {
      if (this.getData) {
        this.paging.page = this.paging.page + 1
        return notificationService.getNotifications(this.paging).then(
          response => {
            if (this.paging.page === 1) {
              this.notifications = response.data.data.notification_list
            } else {
              this.notifications = [].concat.apply(this.notifications, response.data.data.notification_list)
            }
            if (response.data.data.notification_list.length < this.paging.length) {
              this.getData = false
            }
            return response
          }
        )
      } else {
        return false
      }
    },
    getHeaderNotifications () {
      return notificationService.getNotifications({ page: 1, length: 30 }).then(
        response => {
          this.headerNotifications = response.data.data.notification_list
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
