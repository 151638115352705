<template>
  <Program2></Program2>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import Program2 from '../../components/bonapply/Program2.vue'

export default defineComponent({
  name: 'ProgramView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Programlar'
    })
    return {
    }
  },
  components: {
    Program2
  },
  methods: {

  }
})
</script>
