import { BaseService } from './BaseService'
const SEND_MESSAGE = '/v1/contact/send-message'

class ContactService extends BaseService {
  sendMessage (data) {
    return this.put(SEND_MESSAGE, data)
  }
}

export default new ContactService()
