import { acceptHMRUpdate, defineStore } from 'pinia'
import likeService from '@/services/like.service'

export const useLikeStore = defineStore({
  id: 'likeStore',
  state: () => ({

  }),
  setup () {},
  actions: {
    like (entityName, entityId) {
      return likeService.like(entityName, entityId)
    },
    undoLike (entityName, entityId) {
      return likeService.undoLike(entityName, entityId)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLikeStore, import.meta.hot))
}
