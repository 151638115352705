<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="Aktivasyon"></FormTextSlot>
    </div>
    <Form></Form>

    <div class="text-center">
      <div class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <h4>Aktivasyon</h4>
    </div>

  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ActivationView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Aktivasyon'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    authStore.activate().then(
      response => {
        if (response.status === 200) {
          if (response.data.redirect !== []) {
            if (response.data.redirect.endpoint === 'register') {
              // Aktivasyon öncesi kayıt sayfasına yönlendiriliyor
              const invitationToken = response.data.redirect.additionalData.invitation_token
              router.push('/register-activation/' + invitationToken)
            } else if (response.data.redirect.endpoint === 'panel') {
              // Aktivasyon sonrası panele yönlendiriliyor
              window.location.href = response.data.redirect.url
            } else {
              router.push('/login')
            }
          } else {
            router.push('/login')
          }
        }
      }
    )
    return {
      authStore
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

</style>
