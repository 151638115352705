import { BaseService } from './BaseService'
const LIKE = '/v1/like/set'

class LikeService extends BaseService {
  like (entityName, entityId) {
    const data = {
      relation_entity: entityName,
      relation_id: entityId,
      status: 1
    }
    return this.put(LIKE, data)
  }

  undoLike (entityName, entityId) {
    const data = {
      relation_entity: entityName,
      relation_id: entityId,
      status: 0
    }
    return this.put(LIKE, data)
  }
}

export default new LikeService()
