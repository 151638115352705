<template>
  <layout-type-1 class="mt-xl-5 mt-lg-5 mt-3">
    <div class="mb-4">
      <FormTextSlot form-text="Bize Ulaşın"></FormTextSlot>
    </div>
      <Form></Form>
      <form @submit="onSubmit" v-if="meStore.me">
        <div class="form-outline mb-4 text-start">
          <label for="name" class="mb-1">Ad</label>
          <field id="name" name="name" :value="meStore.me.name" placeholder="Adınızı Yazınız" class="form-control shadow-none"/>
          <span>{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
          <label for="surname" class="mb-1">Soyad</label>
          <field id="surname" name="surname" :value="meStore.me.surname" placeholder="Soyadınızı Yazınız" class="form-control shadow-none"/>
          <span>{{ errors.surname }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
          <label for="email" class="mb-1">E-Posta</label>
          <field id="email" name="email" :value="meStore.me.email" placeholder="E-Posta Adresinizi Yazınız" class="form-control shadow-none" />
          <span>{{ errors.email }}</span>
        </div>
         <div class="form-outline mb-4 text-start">
          <field id="message" as="textarea" aria-rowcount="6" name="message" placeholder="Mesajınızı Yazınız" row="5" class="form-control shadow-none"  maxLength="750"/>
          <span>{{ errors.message }}</span>
         </div>
        <div class="d-grid pt-1 mb-4 text-start col-4 offset-4">
          <ButtonSlot button-text="Gönder"></ButtonSlot>
        </div>
      </form>
  </layout-type-1>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useContactStore } from '../../store/contact'
import LayoutType1 from '../../layouts/LayoutType1.vue'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'
import { useMeStore } from '../../store/me'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | İletişim '
    })
    const meStore = useMeStore()
    const contactStore = useContactStore()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız'),
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email('Lütfen E-posta Adresinizi Kontrol Ediniz'),
      message: yup.string().required('*Lütfen Mesajınızı Yazınız').max(750, 'Maximum 750 karakter uzunluğunda mesaj yazabilirsiniz.')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, { resetForm }) => {
      values.phone = '';
      values.subscribe = 0;
      contactStore.sendMessage(values)
      resetForm()
    })

    meStore.getMyProfileData()

    return {
      meStore,
      contactStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    LayoutType1,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
