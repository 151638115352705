import { BaseService } from './BaseService'
const GET_COMMENTS = '/v1/comment'
const ADD_COMMENT = '/v1/comment/add'
const DELETE_COMMENT = '/v1/comment/delete'

class ProgramsService extends BaseService {
  get (filter) {
    return this.post(GET_COMMENTS, filter)
  }

  add (data) {
    return this.post(ADD_COMMENT, data)
  }

  remove (commentId) {
    return this.delete(DELETE_COMMENT, commentId)
  }
}

export default new ProgramsService()
