<template>
     <div class="container mt-xl-5 mt-lg-5 mt-3 mb-3">
        <div class="row down-md-mb">
            <div class="col-12 text-center mb-3">
                <p class="m-0 font-size-11 text-aubergine-color fw-bold down-md-fs-5">Sıkça Sorulan Sorular</p>
            </div>
             <div class="col-12 p-0 d-flex justify-content-center">
                <p class="m-0 font-size-6 text-blueDark-color fw-bold" style="border-bottom:solid 2px #C1E600;width:max-content;">Yaşar İDEA Kurum İçi Girişimcilik Programı</p>
            </div>
        </div>
        <div class="row up-md-padding-1 pt-4">
            <div class="col-12">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Kurum İçi Girişimcilik Programı’na kimler başvurabilir?
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Yaşar Topluluğu genelinde, belirlenen inovasyon odak alanlarında yenilikçi bir fikre sahip olan, kurum içi girişimcilikle ilgilenen ve süreci deneyimlemek isteyen beyaz yaka çalışanların hepsi Program’a bireysel olarak başvuru yapabilir.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            Daha önce herhangi bir girişimcilik deneyimim yok, yine de Program’a başvurabilir miyim? 
                        </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Evet başvuru yapabilirsiniz. Kurum İçi Girişimcilik Programı’na başvurmak için bu konuda bir deneyim ya da farklı bir özellik aranmamaktadır. Bilakis, Program Topluluk çalışanlarının girişimcilik kaslarını ve inovasyon kapasitelerini geliştirmelerini ve bu suretle Topluluğa katma değer yaratmalarını sağlama perspektifiyle hayata geçirilmiştir. 
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            Kurum İçi Girişimcilik Programı’na ne zaman ve nasıl başvurabilirim? 
                        </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Programa ilişkin iş fikri ve takım çağrıları 7 Mayıs - 25 Haziran 2024 tarihleri arasında açık olacaktır. Bu tarihler arasında <a href="https://yasaridea.bonapply.com">yasaridea.bonapply.com</a> üzerinden, ilgili başvuru formunu doldurarak başvurunuzu yapabilirsiniz. Program sadece Topluluk çalışanlarına açık olup, sisteme iş e-posta adresi ve bilgisayar şifresi ile giriş yapılması beklenmektedir.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            Program’a başvurmak için mutlaka bir iş fikrimin olması gerekli mi?
                        </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Hayır, Program’a başvurmak için mutlaka iş fikrinizin olması gerekmemektedir. Program’a belirlenen inovasyon odak alanlarına yönelik fikir geliştirenlerin yanı sıra herhangi bir fikre sahip olmayan ancak takımlarda yer alarak kurum içi girişimciliği deneyimlemek ve bu alanda yetkinlik geliştirmek isteyen beyaz yaka çalışanlarımız da başvurabilirler. Başvuru sayfasına girdiğinizde fikir sahipleri ve takım üyesi olmak isteyenler için ayrı formlar göreceksiniz. Size uyan formu doldurarak, başvurunuzu gerçekleştirebilirsiniz.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                            Program’a kabul edilecek fikirlerde aranan özellikler neler olacak?
                        </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Program’a Topluluğun inovasyon odak alanlarına yönelik fikirlerle başvuru yapabilirsin. Değerlendirme aşamasında fikrin yenilikçi yönü ve Yaşar Topluluğu’na fayda sağlama potansiyelinin yanı sıra söz konusu fikrin inovasyon odak alanlarına uyumu önceliklendirilecektir. Buna ek olarak iş fikrinin uygulanabilirliği de değerlendirme kriterleri arasında yer alacaktır.Detaylı bilgiye <a href="#">Program Kitapçığı</a>’ndan ulaşabilirsiniz.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                            Program’a birden fazla fikir ile başvuru yapabilir miyim?
                        </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Kurum İçi Girişimcilik Programı’na birden fazla fikir ile başvuru yapabilirsiniz. Ancak Program’da ancak bir takımda yer alabilirsiniz.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                            Program çerçevesinde iş fikirleri ve takımlar nasıl seçilmektedir?
                        </button>
                        </h2>
                        <div id="collapseEight" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Program’a iletilen tüm fikirler değerlendirme komitesi tarafından incelenir. Değerlendirme kriterleri çerçevesinde yapılan ilk tur elemeden sonra gerekli görürse başvuru sahipleri ile birebir görüşme talep edilebilir. Program’ın ilk döneminde Topluluğun inovasyon odak alanlarına hizmet eden 6 ila 8 tane iş fikrinin Hızlandırma Dönemi’ne seçilmesi planlanmaktadır. İş fikri ve takım üyesi başvurularından oluşan havuz içerisinden iş fikrini hayata geçirmek için gerekli yetenek setine sahip ve birbirini tamamlayan kurum içi girişimciler belirlenerek takımlar oluşturulur.  
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                            Program’a birbirine çok benzeyen fikir başvuruları gelmesi durumunda nasıl bir yol izlenir?
                        </button>
                        </h2>
                        <div id="collapseNine" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Öncelikle söz konusu fikirlerin ilk tur elemeleri geçip geçmediğine bakılır. Hızlandırma Dönemi’ne kabul edilmeleri muhtemel ise Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi başvuru sahipleri ile görüşerek birbirine benzeyen bu fikirleri birleştirme kararı alabilir. Bu durumda iki başvuru sahibinin de kurulacak takımda yer alması önerilecektir.  
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                            İş fikirlerini geliştirmek için takımlar nasıl oluşturulacak? Takımı bizim mi oluşturmamız gerekiyor? 
                        </button>
                        </h2>
                        <div id="collapseTen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Başvuru aşamasında sizlerin takım oluşturmanız beklenmemektedir. Tüm başvurular toplandıktan sonra öncelikle Program’a kabul edilecek fikirler belirlenecek, ardından takım üyesi başvuruları değerlendirilerek seçilen fikirlerin ihtiyaçlarına göre takımlar oluşturulacaktır. 
                            Takımları ihtiyaçlara uygun olarak oluşturmak ve takım içi uyumu sağlamak amacıyla Hızlandırma Dönemi öncesinde bir “Takımlaştırma Günü” organize edilecektir. İş fikrinin ihtiyaçları göz önünde bulundurularak her takımda farklı yetkinlik ve uzmanlıklara sahip ve birbirini tamamlayacak kişilerin olmasına özen gösterilecektir. Bu çerçevede Yaşar Topluluğu şirket çalışanlarından oluşan karma takımlar kurulması teşvik edilecektir.
                            Aynı şirketten ve riskli/stratejik olarak görülen departmanlardan çok sayıda başvuru olması durumunda ise Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi durumu hassas bir şekilde değerlendirilecek, son karar tüm paydaşların faydasına olacak şekilde verilecektir.

                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                            Kurum içi girişimci takımlarda rol dağılımı nasıl olacaktır? 
                        </button>
                        </h2>
                        <div id="collapseEleven" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Kurum içi girişimci takımlarda hiyerarşik bir yapı bulunmamaktadır. Takımlaştırmanın ardından tüm takım üyelerine aynı haklara sahip kurucu girişimciler olarak bakılacaktır. Bir başka deyişle, fikir sahibinin yönetimsel bir rolü olmayacak, tüm takım üyeleri iş fikrini geliştirmek için birlikte çalışacaklardır. Diğer yandan Program’da ilerleme kaydettikçe çalışmaların verimliliği açısından takım üyelerinin bazı rolleri paylaşması istenecektir. Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi bu konuda gerekli yönlendirmeleri yapacaktır. 
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve">
                            Süreç içerisinde bir takım üyesinin ayrılması durumunda ne yapılacaktır?
                        </button>
                        </h2>
                        <div id="collapseTwelve" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Hızlandırma ve/veya MVP ve Pilot Uygulama dönemlerinde takımdan ayrılan kişiler olması durumunda, gerekli görülen hallerde takıma kurum içi girişimci başvurusu yapmış ve/veya yetkinlikleri ile projenin gelişimine katkı sağlayacağına inanılan yeni bir Topluluk çalışanı dahil edilmesi mümkündür. Bu durumda takıma gelecek yeni kişi de aynı haklara sahip olacağından, süreç ilgili takımla karşılıklı mutabakat sağlanarak yürütülecektir. Ancak takımlaştırma konusunda son karar Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi’ne ait olacaktır.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                            Programın kapsamı nedir?
                        </button>
                        </h2>
                        <div id="collapseThirteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Kurum İçi Girişimcilik Programı üç aşamadan oluşmaktadır:
                            <br><br>
                            1.
                            <strong>Fikir Toplama ve Değerlendirme Dönemi:</strong> Program içerisinde fikriyle ya da takım oyuncusu olarak yer almak isteyen çalışanların başvurularının toplandığı ve değerlendirme kriterleri ışığında değerlendirildiği dönemi ifade eder.
                            <br>
                            2.
                            <strong>Hızlandırma Dönemi:</strong> Seçilen iş fikirlerinin geliştirilmesi amacıyla kurum içi girişimci takımların eğitim ve mentorluk ile destekleneceği 14 haftalık dönem Hızlandırma Dönemi olarak adlandırılır. Bu dönemde takımlar alacakları girişimcilik eğitimleri ve mentorlukların yanı sıra değer önerisi keşfi ve doğrulama çalışmaları, pazar ve rekabet araştırması, erken dönem iş modeli oluşturma, üst yönetim sunumuna hazırlık vb. gibi kapsamlı çalışmalar yürüteceklerdir. Hızlandırma Dönemi sonunda yapılacak Üst Yönetim Sunum Günü’nde MVP ve Pilot Uygulama Dönemi’ne geçecek olan takımlara karar verilecektir.
                            <br>
                            3.
                            <strong>MVP (minimum uygulanabilir ürün) ve Pilot Uygulama Dönemi:</strong> Sağlanan bütçe ile seçilen takımların ürün/hizmet geliştirme denemeleri yapacakları, MVP geliştirecekleri ve ilk müşteri bağlantılarını kuracakları 20 haftalık dönemi ifade etmektedir. Dönem sonunda takımlar, Üst Yönetim’in önüne çıkarak geldikleri aşamayı aktaracaklar ve Yönetim kararı olumlu ise yatırım sürecine geçilecektir.
                        </div>
                        </div>
                    </div>
                     <div v-show="readMore">
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="true" aria-controls="collapseFourteen">
                                Program’a kabul edilirsem kurum içi girişimciliği mevcut işim ile birlikte nasıl devam ettireceğim?
                            </button>
                            </h2>
                            <div id="collapseFourteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                Seçilen kurum içi girişimciler Program kapsamındaki çalışmalarını mevcut işlerine paralel olarak gerçekleştireceklerdir. Gerek Hızlandırma Dönemi gerekse MVP ve Pilot Uygulama Dönemi ve sonrasında kurum içi girişimcilerin iş modellerini geliştirebilmeleri için çalışma saatlerinin yüzde 10 ila 20’sini proje çalışmalarına ayırmalarına imkan tanınacaktır.  Hızlandırma Dönemi’nde en önemli kaynak takım üyelerinin zamanıdır. Takım üyeleri bu çerçevede ne kadar zaman ayırabilirlerse iş fikri o derece hızlı ve sağlıklı ilerleyecektir. Diğer yandan danışman ve mentorlarımızın eğitim ve rehberliği ile programda kullanılan yalın girişim modeli ve diğer yenilikçi çalışma yöntemleri, sınırlı kaynakların en verimli ve etkin bir şekilde kullanılmasını, ayrılan zamanlarla maksimum sonuç alınmasını sağlamaya yönelik olacaktır.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiveteen" aria-expanded="true" aria-controls="collapseFiveteen">
                                Program’a katılan kurum içi girişimler için kazanımlar neler olacak? Neden bu programa katılmalıyız? 
                            </button>
                            </h2>
                            <div id="collapseFiveteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                Program, kurum içi girişimcilere inovasyon, girişimcilik ve iş fikri/modeli geliştirme alanlarında farkındalıklarını artıracak, bu alanlardaki potansiyellerini ortaya çıkartacak ve vizyonlarını genişletecek bir kişisel dönüşüm süreci sunar. Kurum içi girişimciler inovasyon kapasitelerini geliştirme, mevcut işlerinin dışında donanımlarını güçlendirme ve kapsamlı deneyimler elde etme fırsatı yakalarlar. Bir yandan tasarım odaklı düşünme, müşteriyi anlama, ihtiyaçlara yönelik çözüm geliştirme, sürdürülebilir değer yaratma, pazar ve rekabet analizi gibi konularda gelişim sağlarlarken, diğer yandan yenilikçi çalışma modellerini de deneyimlerler. Farklı aşamalarda üst yönetime yapılan sunumlar da programdaki önemli gelişim fırsatlarından birini oluşturur. Bu çalışmaları yaparken şirket kaynaklarını kullanarak farklı işbirlikleri geliştirme ve hiyerarşik yapıdan uzak bir ortamda girişimciliği deneyimleme fırsatı elde ederler. 
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="true" aria-controls="collapseSixteen">
                                Program’da kurum içi girişimcilere hangi destekler sağlanacak?  
                            </button>
                            </h2>
                            <div id="collapseSixteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                &nbsp;&nbsp;&nbsp;&nbsp;Program’a kabul edilen kurum içi girişimciler için programdaki ilerlemelerine göre farklı teşvik ve ödül sistemleri uygulanacaktır.<br>
                                Hızlandırma Dönemi’ne katılmaya hak kazanan tüm takımlara, yenilikçi fikirlerini geliştirmeleri için 14 haftalık yoğun bir eğitim ve mentorluk programı sunulacaktır. Takımlar girişimcilik özelinde temel ve orta seviye eğitimler almanın yanı sıra birebir mentorluk desteği ile fikirlerini geliştirme imkanı bulacaklardır. Bu çerçevede oluşturulacak mentor havuzunda inovasyon ve girişimcilik ekosisteminden uzmanlarla birlikte farklı dikey alanlarda Yaşar Topluluğu tarafından belirlenecek iç mentorlar da bulunacaktır. Fikirden ticarileşmeye giden bu yolculukta, kurum içi girişimciler ayrıca network oluşturma, müşteri bağlantısı kurma, saha araştırması yapma ve yatırımcı sunumu hazırlama konularında desteklenecektir. Program sonunda ise kendilerine özgeçmişlerine ve sosyal medya profillerine kolayca ekleyebilecekleri katılım belgeleri verilecektir. Kurum içi girişimciler MVP aşamasına geçmelerinden bağımsız olarak Yaşar Topluluğu içerisinde “inovasyon elçisi” olarak görevlendirilecek, Program’ın sonraki dönemlerinde girişimci koçu ve/veya mentor olarak konumlandırılacaktır.<br>
                                MVP ve Pilot Uygulama Dönemi’ne geçmeye hak kazanan her bir takıma ilgili masraflarını karşılayabilmeleri için kaynak ayrılacak ve ürün / hizmetlerini geliştirmelerine yönelik hukuki, muhasebesel ve teknik destekler sağlanacaktır.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="true" aria-controls="collapseSeventeen">
                                İş fikirlerinin üzerinde çalışmak için Yaşar Topluluğu tarafından bütçe sağlanacak mı? 
                            </button>
                            </h2>
                            <div id="collapseSeventeen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                Hızlandırma Dönemi içerisinde henüz ürün/çözüm çıkarılmayacağı için genelde bütçe ihtiyacı olmamaktadır. Ancak kurum içi girişimcilerin saha çalışmalarını yerine getirilebilmesi amacıyla yol masrafları ve bunun gibi basit giderler program tarafından karşılanacaktır. MVP ve Pilot Uygulama Dönemi’nde ise her takım için bir bütçe ayrılacaktır. Bu bütçe final ürün/hizmeti ortaya çıkarmak için değil, ilk müşteri testlerini yapabilmek için oluşturulacak yalın ürün/pilot uygulama için kullanılacaktır.  
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="true" aria-controls="collapseEighteen">
                                Program’a seçilen iş fikirlerinin fikri mülkiyet hakları nasıl düzenlenecek? 
                            </button>
                            </h2>
                            <div id="collapseEighteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                Yaşar Topluluğu genelinde uygulanan Kurum İçi Girişimcilik Programı’na seçilen iş fikirleri, şirketin kaynakları ile geliştirildiği için fikri mülkiyet hakları da şirkete ait olacaktır. 
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="true" aria-controls="collapseNineteen">
                                Program’daki işleyiş, süreçler ve karşılıklı haklar nasıl düzenlenecek?  
                            </button>
                            </h2>
                            <div id="collapseNineteen" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                Program’a kabul edilen tüm kurum içi girişimcilerle süreçleri ve karşılıklı hakları düzenleyen “Katılım Sözleşmesi” imzalanacaktır. Bu çerçevede, takım üyelerinin Şirket Genel Müdür ve İnsan Kaynakları Departmanlarına bilgi verilerek, bağlı oldukları yöneticilerle ekiplerinin bilgilendirilmesi sağlanacaktır.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                            <h2 class="accordion-header">
                            <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="true" aria-controls="collapseTwenty">
                                Hızlandırma Dönemi’nde çalışma şartları nasıl olacaktır?
                            </button>
                            </h2>
                            <div id="collapseTwenty" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                            <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                                - 	Yaşar Topluluğu Kurum İçi Girişimcilik Programı’na seçilen çalışanların Katılım Sözleşmesi’ni imzalamaları beklenmektedir. <br>
                                - 	Kurum içi girişimciler, 14 haftalık Hızlandırma Dönemi boyunca Yaşar Topluluğu’nun inovasyon odak alanlarında çalışmalarını sürdüreceklerdir. Bu süre zarfında şirket laptop ve telefonlarını kullanacaklardır. Gerekli teknik donanıma sahip olmayan kurum içi girişimcilere program çerçevesinde kullanılmak üzere bu olanaklar şirketleri tarafından sağlanacaktır.<br>
                                - 	Program kapsamında düzenlenecek olan tüm etkinliklere katılım zorunludur. Programın amacı, fikrine gönülden bağlı olan ve bu fikrin geleceğine yürekten inanan kurum içi girişimcilere destek vermek olduğundan, takım üyelerinin çalışmaların en az %80’ine aktif katılımı beklenmektedir. <br>
                                - 	Program kayıtlarında projeye dair yer alan bilgilerin doğru, eksiksiz ve güncel tutulması için azami çabanın gösterilmesi, programın gereklilikleri kapsamında Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi tarafından talep edilen evrak, bilgi ve formların temin edilmesi, eksiksiz ve doğru  bir biçimde doldurulması ve zamanında teslim edilmesi önem taşımaktadır.<br>
                                - 	Program boyunca tüm takımlar Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi’ne haftalık bazda durum güncellemesi yapmakla yükümlüdür. <br>
                                - 	Takımlarda yer alan her bir takım üyesi, aynı haklara sahiptir. Birlikte çalışılan takım üyeleriyle, iletişimin olumlu bir şekilde sürdürülmesi, projenin başarılı olabilmesi ve takım olarak yatırım alınabilmesi için takımın motivasyonunu da üst seviyede tutulmaya gayret gösterilmesi önem taşımaktadır.<br>
                                - 	Takımların sorumluluk ve iş planı yapmaları, zaman yönetimine önem vermeleri, finansal kaynakları verimli kullanmaları, değişen şartlara hızlı ayak uydurmaları ve Yaşar Topluluğu Girişimcilik ve İnovasyon Komitesi ile yakın çalışmaları programın amacına ulaşması için gereklidir.<br>
                            </div>
                            </div>
                        </div>
                     </div>
                     <div class="d-flex justify-content-center">
                    <button class="btn mt-3"
                        @click="readMore = !readMore">
                        <button type="btn" v-if="readMore" class="btn btn-primary">Daha Az</button>
                        <button type="btn" v-else class="btn btn-primary">Tüm Soruları Oku</button>
                    </button>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BlogDetailView',
  data () {
    return {
      readMore: false
    }
  }
})
</script>
<style scoped>
.accordion-button{
    font-size: 18px !important;
}
.accordion-button::after{
  background-image: url(@/assets/LeadImages/img-10.png);
  background-size: 8px;
  background-color: #231F20;
  padding: 10px;
  width:10px;
  border-radius: 100%;
  background-position: center;
}
.accordion-button:not(.collapsed)::after{
  background-image: url(@/assets/LeadImages/img-11.png);
  background-size: 8px;
  background-color: #C1E600;
  padding: 10px;
  width:10px;
  border-radius: 100%;
  background-position: center;
}
.accordion-button:not(.collapsed){
  background-color: white !important;
  box-shadow: none !important;
  color: #383E42!important;
  font-weight:700;
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accordion-item .accordion-button.collapsed{
  font-weight: 700 !important;
}
.btn:active{
    border: 0 !important;
    box-shadow: none !important;
    border-color: transparent !important;
}
</style>
