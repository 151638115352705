import { BaseService } from './BaseService'
import { useConfigStore } from '@/store/config'
const KVKK = '/v1/static-page/kvkk'
const COOKIES_POLICY = '/v1/static-page/cookies_policy'

class KvkkService extends BaseService {
  getKvkkInfo () {
    const sectionKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'inHouseUserKvkk' : 'externalUserKvkk'
    return this.get(KVKK + '?sectionKey=' + sectionKey)
  }
  getCookieInfo () {
    const sectionKey = (window.location.hostname === useConfigStore().data.VUE_APP_INTERNAL_DOMAIN) ? 'inHouseUserKvkk' : 'externalUserCookiesPolicy'
    return this.get(COOKIES_POLICY + '?sectionKey=' + sectionKey)
  }
}

export default new KvkkService()
