<template>
<div class="main-container">
  <div v-if="!isReady" class="loaderArea d-flex align-items-center d-none">
    <div class="loader"></div>
  </div>
  <landing-header></landing-header>
    <main class="flex-shrink-1" style="margin-top:5rem;">
      <div style="min-height: 600px;padding-top:25px;">
        <router-view/>
      </div>
    </main>
    <DefaultFooter v-if="!isLanding()"/>
    <Cookie v-if="cookieReady"></Cookie>
</div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useAuthStore } from './store/auth'
import { useConfigStore } from './store/config'
import LandingHeader from './components/landing/LandingHeader'
import DefaultFooter from './components/bonapply/DefaultFooter.vue'
import { useRoute } from 'vue-router'
import Cookie from "@/components/Cookie/Cookie.vue";
import { useCookieStore } from '@/store/cookie'

export default defineComponent({
  name: 'App',
  data () { return {} },
  setup () {
    const isReady = ref(false)
    const cookieStore = useCookieStore()
    const authStore = useAuthStore()
    const configStore = useConfigStore()
    const route = useRoute()
    onMounted(() => {
      document.title = 'Yaşar IDEA'
      setTimeout(function () {
        isReady.value = true
      }, 500)
    })
    const isLanding = () => {
      return route.name === 'index';
    }

    const cookieReady = ref(false)
    cookieStore.getCookie().then(
      response => {
        if (response.status === 200) cookieReady.value = true
      }
    );

    return {
      configStore,
      authStore,
      isLanding,
      cookieReady,
      isReady
    }
  },
  components: {
    LandingHeader,
    Cookie,
    DefaultFooter
  }
})
</script>
<style>
textarea.form-control{
  border-radius: 40px !important;
}
</style>
<style>
.loaderArea {
  width: 100%;
  margin: auto;
  position: fixed;
  z-index: 99999999999999;
  background: white;
  height: 100%;
}
.loader {
  margin: auto;
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>
