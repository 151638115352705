<template>
  <div class="container">
      <div class="row applications my-5" v-for="item in applicationStore.events" :key="item">
        <div class="col-xl-4 col-12 text-start down_992_margin_bottom me-2">
          <img :src="item.cover_img" class="img-fluid w-100" alt="basvurular">
        </div>
        <div class="col-xl-6 col-12">
          <div class="text-start">
            <h4>{{ item.title }}</h4>
          </div>
          <div class="text-start">
            <p class="font_3 m-0">{{ item.detail }}</p>
          </div>
        </div>
        <div>
          <div class="row mt-5 down_992_margin_none" v-for="item2 in item.applies" :key="item2">
            <div class="col-xl-3 col-lg-3 col-12 down_992_margin_bottom">
              <div class="d-flex text-start">
                <p class="me-2 font_2">ID:</p>
                <p class="font_2">{{ item2.uid }}</p>
              </div>
              <div class="text-start font_4">
                <p class="m-0">{{ item2.complete_date }}</p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-9 col-12 down_992_margin_bottom">
              <div class="text-start d-none">
                <stepper-view></stepper-view>
              </div>
            </div>
            <div class="col-xl-3 col-12 text-end down_992_margin_bottom">
              <ButtonSlot button-text="{{ item2.button_status.title }}" class="down_992_width_full"></ButtonSlot>
            </div>
            <hr class="my-4" style="border-bottom:solid 1px #d9dbea;">
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useApplicationStore } from '../../store/applications'
import ButtonSlot from '@/layouts/ButtonSlot'
import StepperView from '@/components/bonapply/stepperView'

export default defineComponent({
  name: 'ApplicationsView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Programlar'
    })
    const applicationStore = useApplicationStore()
    applicationStore.getApplications()
    return {
      applicationStore,
      ButtonSlot
    }
  },
  components: {
    StepperView,
    ButtonSlot
  },
  methods: {

  }
})
</script>

