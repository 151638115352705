import { BaseService } from './BaseService'
const GET_COOKIES = '/v1/cookie/get'
const UPDATE_COOKIES = '/v1/cookie/update'

class ProgramsService extends BaseService {
  getCookie (token) {
    let url = GET_COOKIES;
    if(token != null) url += '?token=' + token;

    return this.get(url)
  }

  updateCookie (data) {
    return this.put(UPDATE_COOKIES, data)
  }
}

export default new ProgramsService()
