import { acceptHMRUpdate, defineStore } from 'pinia'
import kvkkService from '@/services/kvkk.service'

export const useKvkkStore = defineStore({
  id: 'kvkkStore',
  state: () => ({
    kvkkInfo: [],
    cookieInfo: []
  }),
  setup () {},
  actions: {
    getKvkkInfo () {
      return kvkkService.getKvkkInfo().then(
        response => {
          this.kvkkInfo = response.data.data.section
          return response
        }
      )
    },
    getCookieInfo () {
      return kvkkService.getCookieInfo().then(
        response => {
          this.cookieInfo = response.data.data.section
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useKvkkStore, import.meta.hot))
}
