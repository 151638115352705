<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
                <img src="@/assets/bonapply/ThematicImages/thematic-img.png" alt="thematic image" class="img-fluid"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <h5 class="black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</h5>
            </div>
            <div class="col-12 mb-3">
                <p class="m-0 black font-size-lg">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
            <div class="col-12 mb-3">
                <p class="m-0 black font-size-lg">Tematik Fikir programına ufukozturk@bontesoft.com hesabınızla fikir giriyorsunuz.</p>
            </div>
            <div class="col-12 mb-3">
                <p class="m-0 black font-size-lg">Ortalama Süresi: 3 dk</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-12">
                  <Form></Form>
            <form @submit="onSubmit">
                 <div class="form-outline mb-4 text-start">
                     <div class="d-flex justify-content-between">
                         <label for="name" class="mb-1">Adınız</label>
                         <button type="button" class="btn border-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                             <div class="border rounded-circle" style="width:14px;height:14px;font-size:7px;"><i class="fa-solid fa-question"></i></div>
                        </button>
                     </div>
                     <field id="name" name="name" placeholder="Adınızı Yazınız" class="form-control shadow-none" />
          <span>{{ errors.name }}</span>
        </div>
        <div class="form-outline mb-4 text-start">
            <div class="d-flex justify-content-between">
          <label for="surname" class="mb-1">Soyadınız</label>
           <button type="button" class="btn border-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                             <div class="border rounded-circle" style="width:14px;height:14px;font-size:7px;"><i class="fa-solid fa-question"></i></div>
                        </button>
                        </div>
          <field id="surname" name="surname" placeholder="Soyadınızı Yazınız" class="form-control shadow-none" />
          <span>{{ errors.surname }}</span>
        </div>
      <div class="form-outline mb-4 text-start">
          <div class="d-flex justify-content-between">
        <label for="email" class="mb-1">Mail Adresiniz</label>
         <button type="button" class="btn border-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                             <div class="border rounded-circle" style="width:14px;height:14px;font-size:7px;"><i class="fa-solid fa-question"></i></div>
                        </button>
                        </div>
        <field id="email" name="email" placeholder="E-Posta Adresinizi Yazınız" class="form-control shadow-none" />
        <span>{{ errors.email }}</span>
      </div>
       <div class="form-outline mb-4 text-start">
           <div class="d-flex justify-content-between">
        <label for="idea" class="mb-1">Fikrinizin başlığı nedir?</label>
         <button type="button" class="btn border-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                             <div class="border rounded-circle" style="width:14px;height:14px;font-size:7px;"><i class="fa-solid fa-question"></i></div>
                        </button>
                        </div>
        <field id="idea" name="idea" placeholder="Bana faydalı olacağını düşündüğüm için…" class="form-control shadow-none" />
        <span>{{ errors.idea }}</span>
      </div>
      <div class="form-outline mb-4 text-start">
          <div class="d-flex justify-content-between">
          <label for="description" class="form-label">Fikrinizi açıklayınız?</label>
           <button type="button" class="btn border-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                             <div class="border rounded-circle" style="width:14px;height:14px;font-size:7px;"><i class="fa-solid fa-question"></i></div>
                        </button>
                        </div>
         <field id="description" as="textarea" aria-rowcount="6" name="description" placeholder="Bana faydalı olacağını düşündüğüm için…" row="5" class="form-control shadow-none" />
        <span>{{ errors.description }}</span>
      </div>
      <div class="down_992_width_full" style="width:50%;">
          <label for="select" class="form-label">Fayda Tipi?</label>
          <select class="form-select form-outline mb-4 text-start" aria-label="Default select example">
              <option value="1">Gelir Artışı</option>
              <option value="2">Gelir Artışı</option>
              <option value="3">Gelir Artışı</option>
          </select>
      </div>
      <div>
        <label for="select" class="form-label">Fayda Tipi?</label>
    <VueMultiselect
      v-model="selected"
      :options="options"
      placeholder="Fayda Tipi Seçiniz"
      :multiple="true">
    </VueMultiselect>
  </div>
      <div>
          <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <a href="#">KVKK Metnini</a> okudum, anladım.
          </div>
      </div>
      <div class="d-grid pt-1 mb-4 text-start d-flex align-items-center">
        <ButtonSlot button-text="Gönder" class="down_992_width_full me-1" style="width:25%;"></ButtonSlot>
        <ButtonSlot button-text="Vazgeç" class="down_992_width_full btn-light-gray" style="width:25%;"></ButtonSlot>
      </div>
    </form>
            </div>
           </div>
    </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
  name: 'LoginView',
  data () { return { selected: null, options: ['Gelir Artışı', 'Maliye/Risk Azaltımı', 'Müşteri Deneyimi İyileştirme'] } },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Tematik Çağrı'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    const schema = yup.object().shape({
      name: yup.string().required('*Lütfen Adınızı Yazınız'),
      surname: yup.string().required('*Lütfen Soyadınızı Yazınız'),
      email: yup.string().required('*E-Posta Adresinizi Yazınız').email(),
      idea: yup.string().required('*Fikrinizin Başlığını Yazınız'),
      description: yup.string().required('*Fikrinizi Açıklayınız')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.login(values).then(
        response => {
          router.push('/programs')
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    VueMultiselect
  },
  methods: {

  }
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

