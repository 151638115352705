<template>
  <div class="card card-body border-0 px-0">
    <div class="border-bottom pb-4 mb-5">
      <div class="row mb-4">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-12 pb-3">
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-12" style="width:max-content !important;">
              <p class="m-0 dark-gray font-size-xl fw-bold text-capitalize"> {{ data.apply.user.name+" "+data.apply.user.surname }}</p>
               </div>
               <div class="col-xl-4 col-lg-6 col-12" style="width:max-content !important;">
              <p class="m-0 dark-gray font-size-xl">{{ data.apply.user.createDate }}</p>
               </div>
           </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12 text-end">
          <a @click="redirectTotDetail()" href="#" class="black fw-bold font-size-xl">Fikre Git</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="m-0 black font-size-xl" v-if="data.answers.feed_program_apply_title && data.apply.isIdeaWillBePublished">
            {{ data.answers.feed_program_apply_title.formItemReply }}
          </p>
          <p class="m-0 black font-size-xl" v-if="!data.apply.isIdeaWillBePublished">
            ****
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useAnalyticsStore } from '@/store/analytics'
export default defineComponent({
  name: 'WallPost',
  props: {
    data: {}
  },
  data () { return {} },
  setup (props) {
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const redirectTotDetail = () => {
      router.push({ name: 'feed', params: { applyUId: props.data.apply.uid } }).then(() => {
        analyticsStore.sendView('apply', props.data.apply.id)
      })
    }
    return {
      redirectTotDetail
    }
  }
})
</script>
