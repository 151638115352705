<template>
    <div class="container mb-5">
        <div class="row down-md-mb mb-3 down-md-pl-pr-2" style="padding-left:3rem;">
            <div class="col-12 text-left mb-3 p-0 down-md-text-center">
                <p class="m-0 font-size-11 text-aubergine-color fw-bold down-md-fs-5">Sıkça Sorulan Sorular</p>
            </div>
            <div class="col-12 text-left mb-1 p-0 down-md-center">
                <p class="m-0 font-size-6 text-blueDark-color fw-bold" style="border-bottom:solid 2px #C1E600;width:max-content;">Yaşar İDEA Kurum İçi Girişimcilik Programı</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header p-1">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Kurum İçi Girişimcilik Programı’na kimler başvurabilir?
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Yaşar Topluluğu genelinde, belirlenen inovasyon odak alanlarında yenilikçi bir fikre sahip olan, kurum içi girişimcilikle ilgilenen ve süreci deneyimlemek isteyen beyaz yaka çalışanların hepsi Program’a bireysel olarak başvuru yapabilir. 
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header p-1">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                           Daha önce herhangi bir girişimcilik deneyimim yok, yine de Program’a başvurabilir miyim?
                        </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Evet başvuru yapabilirsiniz. Kurum İçi Girişimcilik Programı’na başvurmak için bu konuda bir deneyim ya da farklı bir özellik aranmamaktadır. Bilakis, Program Topluluk çalışanlarının girişimcilik kaslarını ve inovasyon kapasitelerini geliştirmelerini ve bu suretle Topluluğa katma değer yaratmalarını sağlama perspektifiyle hayata geçirilmiştir. 
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header p-1">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            Kurum İçi Girişimcilik Programı’na ne zaman ve nasıl başvurabilirim? 
                        </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Programa ilişkin iş fikri ve takım çağrıları 7 Mayıs - 25 Haziran 2024 tarihleri arasında açık olacaktır. Bu tarihler arasında  <a href="https://yasaridea.bonapply.com">yasaridea.bonapply.com</a> üzerinden, ilgili başvuru formunu doldurarak başvurunuzu yapabilirsiniz. Program sadece Topluluk çalışanlarına açık olup, sisteme iş e-posta adresi ve bilgisayar şifresi ile giriş yapılması beklenmektedir.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item mb-2 border rounded-5 up-md-pl-pr">
                        <h2 class="accordion-header p-1">
                        <button class="accordion-button rounded-5 font-size-6 text-aubergine-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            Program’a başvurmak için mutlaka bir iş fikrimin olması gerekli mi?
                        </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse rounded-5" data-bs-parent="#accordionExample">
                        <div class="accordion-body fw-light font-size-6 fw-normal text-aubergine-color pt-0">
                            Hayır, Program’a başvurmak için mutlaka iş fikrinizin olması gerekmemektedir. Program’a belirlenen inovasyon odak alanlarına yönelik fikir geliştirenlerin yanı sıra herhangi bir fikre sahip olmayan ancak takımlarda yer alarak kurum içi girişimciliği deneyimlemek ve bu alanda yetkinlik geliştirmek isteyen beyaz yaka çalışanlarımız da başvurabilirler. Başvuru sayfasına girdiğinizde fikir sahipleri ve takım üyesi olmak isteyenler için ayrı formlar göreceksiniz. Size uyan formu doldurarak, başvurunuzu gerçekleştirebilirsiniz.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <a href="/sikca-sorulan-sorular">
                    <button type="btn" class="btn btn-primary">Tüm Soruları Oku</button>
                </a>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>

.accordion-button{
    font-size: 18px !important;
}
.accordion-button::after{
  background-image: url(@/assets/LeadImages/img-10.png);
  background-size: 8px;
  background-color: #231F20;
  padding: 10px;
  width:10px;
  border-radius: 100%;
  background-position: center;
}
.accordion-button:not(.collapsed)::after{
  background-image: url(@/assets/LeadImages/img-11.png);
  background-size: 8px;
  background-color: #C1E600;
  padding: 10px;
  width:10px;
  border-radius: 100%;
  background-position: center;
}
.accordion-button:not(.collapsed){
  background-color: white !important;
  box-shadow: none !important;
  color: #231F20 !important;
  font-weight:700;
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accordion-item .accordion-button.collapsed{
  font-weight: 700 !important;
}
</style>