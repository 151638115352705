import { acceptHMRUpdate, defineStore } from 'pinia'
import PopularService from '@/services/popular.service'
import { useRoute } from 'vue-router'

export const usePopularStore = defineStore({
  id: 'popularStore',
  state: () => ({
    popular_list: [],
    route: useRoute()
  }),
  setup () { },
  actions: {
    getPopularInfo () {
      let eventUId = 'all'
      if (this.route.params.eventUId !== undefined && this.route.params.eventUId !== null) {
        eventUId = this.route.params.eventUId
      }
      return PopularService.getPopularInfo(eventUId).then(
        response => {
          this.popular_list = response.data.data.popular_list
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePopularStore, import.meta.hot))
}
