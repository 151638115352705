<template>
  <div class="container">
    <div v-if="cookiesPolicyStore.cookiesPolicyInfo.length != 0" class="row my-5">
      <div class="col-12 text-start mb-3">
        <h3>{{cookiesPolicyStore.cookiesPolicyInfo.title}}</h3>
      </div>
      <div class="col-12" v-html="cookiesPolicyStore.cookiesPolicyInfo.content"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useCookiesPolicyStore } from '../../store/cookies_policy'

export default defineComponent({
  setup () {
    const cookiesPolicyStore = useCookiesPolicyStore()
    cookiesPolicyStore.getCookiesPolicyInfo()
    return {
      cookiesPolicyStore
    }
  },
  components: { }
})
</script>
