<template>
<div class="container">
   <div class="row my-5">
        <div class="col-12 text-start mb-3">
            <p style="font-size:16px;" class="fw-bold">YASAL BİLDİRİM</p>
        </div>
        <div class="col-12">
        Kullanıcılarımıza web sitesinin sahibinin Yaşar Holding “Yaşar” olduğunu bildiririz. (E-posta:
        info@yasar.com.tr / Telefon numarası : 0 232 495 00 00)<br><br>
        <strong>Sorumluluk</strong><br>
        Yaşar Holding’ine ait Yaşar İDEA web sitesinde, size sunulan bilgiler periyodik olarak derlenir ve
        güncellenir. Azami özene rağmen, web sitesinin içeriğinin hatasız olduğunu garanti edemeyiz. Web
        sitesinin bilgileri veya herhangi bir yönüyle ilgili olarak satılabilirlik garantisi, belirli bir kullanıma
        uygunluk garantisi ve açık veya zımni başka hiçbir garanti veremeyiz.<br><br>
        Web sitesi İçeriği, ürünlerimizle ilgili olarak, özellikle pazarlanabilirlikleri veya belirli bir amaç için
        sözleşmeye uygunlukları ile ilgili bir taahhüt veya garanti teşkil etmez. Web sitemizdeki ileriye dönük
        beyanlar, yalnızca Şehit Fethi Bey Caddesi No:120 Alsancak İzmir merkez adresindeki yönetimin
        görüşlerine, (ön) tahminlerine veya varsayımlarına dayanmaktadır bu nedenle, risklere ve
        belirsizliklere tabidirler. Yaşar, bu tür ileriye dönük beyanları güncellemekle yükümlü değildir. Yaşar,
        sağlanan bilgi veya verilerde herhangi bir zamanda önceden haber vermeksizin değişiklik veya
        ekleme yapma hakkını saklı tutar.<br><br>
        Yaşar internete bağlanması sırasında üretilen cihazlarda teknik sorunların veya mekanik arızaların
        varlığından doğabilecek her türlü sorumluluğu reddeder. Yaşar İDEA web sitesine erişimin kesintisiz
        olacağını garanti etmediği gibi, hatasız olduğunu veya herhangi bir zarara yol açabileceğini de garanti
        etmez.<br><br>
        Bu web sitesi, bazı durumlarda bağlantılar aracılığıyla üçüncü taraf sağlayıcılara atıfta bulunur.
        Yaşar’ın üçüncü taraf web sitelerinin içeriği üzerinde hiçbir etkisi yoktur. Bu nedenle Yaşar , bağlantı
        verdiği üçüncü taraf web sitelerinin içeriği ve kişisel veri koruma ve gizlilik uygulamaları ile ilgili hiçbir
        sorumluluk kabul etmemektedir. Üçüncü taraf web sitelerini kullanmadan önce, söz konusu
        sağlayıcıların ilgili hüküm ve koşullarını ve kişisel veri koruma ve gizlilik hükümlerini incelemenizi
        öneririz.<br><br>
        Yaşar, kullanıcılar tarafından sağlanan kişisel verilerin gizliliğini ve bunların Kişisel Veri Koruma
        Mevzuatına uygun olarak otomatik olarak işlenmesi garanti eder. Kişisel verilerin işlenmesi hakkında
        daha fazla bilgiyi Gizlilik ve Aydınlatma Bildirimimizde bulabilirsiniz.<br><br>
        <strong>Fikri Mülkiyet ve Kullanım Hakkı</strong><br>
        Yaşar İDEA web sitesinin resimleri ve tasarımı da dahil olmak üzere içerik, telif hakkı korumasına ve
        fikri mülkiyetin korunmasına ilişkin diğer yasalara tabidir. Web sitemiz ve içeriği, Yaşar Topluluğu
        şirketlerinin sahip olduğu fikri mülkiyetin kullanımı için herhangi bir lisans ve onay vermemektedir.
        Özellikle, web sitemizde yer alan içeriğin yazılı izin alınmadan ticari amaçlarla çoğaltılmasına,
        dağıtılmasına, iletilmesine, işlenmesine, değiştirilmesine veya başka herhangi bir şekilde
        kullanılmasına izin vermiyoruz.<br><br>
        <strong>Ticari Marka</strong><br>
        Yaşar Topluluğu Şirketleri'nin ticari markaları Yaşar Holding ve bağlı şirketlerin yazılı izni olmadan
        kullanılamaz. Aynı kural şirket logoları ve işaretleri için de geçerlidir.
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {}
  },
  components: { }
})
</script>