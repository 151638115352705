<template>
  <button
    class="text-start font-size-lg down_md_pl_0 active btn btn-primary mb-2 down_md_w100 down_md_display_center button_1"
    id="v-pills-home-tab"
    data-bs-toggle="pill"
    data-bs-target="#v-pills-home"
    type="button"
    role="tab"
    aria-controls="v-pills-home"
    aria-selected="true"
    @click="onNewButtonClick"
    v-if="authStore.isLoggedIn"
  >
    <span class="fa fa-plus me-2"></span> Fikir Gönder
  </button>

  <BSModal ref="refModal">
      <template #header>
      </template>
      <template #body>
        <apply-form :applyFormUId="applyFormUId" @onApplySuccess="onApplySuccess"/>
      </template>
      <template #footer>
      </template>
  </BSModal>

  <ApplySuccessModal ref="refApplySuccessModal"/>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useApplyStore } from '../../store/apply'
import { useAuthStore } from '../../store/auth'
import ApplySuccessModal from '@/components/bonapply/ApplySuccessModal'

export default defineComponent({
  name: 'NewIdea',
  data () { return {} },
  setup () {
    const applyStore = useApplyStore()
    const authStore = useAuthStore()
    const refModal = ref(null)
    const refApplySuccessModal = ref(null)
    const applyFormUId = ref(null)
    onMounted(() => {

    })
    const onNewButtonClick = () => {
      if (!authStore.checkIsLoggedIn()) return

      applyFormUId.value = null

      applyStore.create().then(
        response => {
          if (response.status === 200) {
            applyFormUId.value = response.data.data.apply.uid
            refModal.value.show()
          } else {
            refModal.value.dismiss()
          }
        }
      )
    }

    const onApplySuccess = () => {
      refModal.value.close()
      refApplySuccessModal.value.show()
    }

    return {
      refModal,
      refApplySuccessModal,
      onNewButtonClick,
      applyFormUId,
      onApplySuccess,
      authStore
    }
  },
  components: {
    ApplySuccessModal
  }
})
</script>
<style lang="scss" scoped>

</style>
