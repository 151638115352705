<template>
    <div>
        <label class="fw-bolder">{{ data.title }}</label>
        <button v-if="data.description=='1'"
                type="button"
                class="btn btn-sm p-0 float-end"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :title="data.descriptionVal">
            <span class="fa fa-question-circle"></span>
        </button>
    </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { Tooltip } from 'bootstrap'

export default defineComponent({
  name: 'FormItemLabel',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    onMounted(() => {
      const toolTips = document.querySelectorAll('button[data-bs-toggle="tooltip"]')
      Array.from(toolTips).forEach(tooltipNode => {
        // eslint-disable-next-line no-new
        new Tooltip(tooltipNode)
      })
    })

    return {

    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

</style>
