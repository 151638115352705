<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
              <left-list></left-list>
            </div>
            <div class="col-12 col-md-9"  v-if="applyStore.myIdeas">
              <div v-if="applyStore.myIdeas.length === 0">Henüz başvurunuz bulunmamaktadır.</div>
                <div v-for="post in applyStore.myIdeas" :key="post">
                  <div v-if="post.event.eventKey === 'feed_program'">
                    <wall-post :data="post" :likeBox="true" :commentBox="true" layout="my-ideas"/>
                  </div>
                  <div v-else>
                    <apply-cart :apply="post" :seeApplicationButton="true"/>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '../../store/apply'
import WallPost from '../../components/bonapply/WallPost'
import ApplyCart from '../../components/bonapply/ApplyCart'
import LeftList from '../../components/bonapply/LeftList.vue'

export default defineComponent({
  name: 'MyIdeasView',
  data () { return {} },
  setup () {
    onMounted(() => {
      applyStore.resetPaging()
      document.title = 'Yaşar IDEA | Fikirlerim'
      const listElm = document.querySelector('html')
      window.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (keepScrolling.value) {
            keepScrolling.value = false
            setTimeout(function () {
              keepScrolling.value = true
            }, 500)
            getMyIdeaList()
          }
        }
      })
      getMyIdeaList()
    })
    const getMyIdeaList = () => {
      if (applyStore.getData) {
        applyStore.getMyIdeas()
      }
    }
    const applyStore = useApplyStore()
    const keepScrolling = ref(true)
    return {
      applyStore
    }
  },
  components: {
    WallPost,
    ApplyCart,
    LeftList
  },
  methods: {

  }
})
</script>
