<template>
       <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
              <left-list></left-list>
            </div>
            <div class="col-12 col-md-9">
                <div class="row mb-5">
                    <div class="col-12 col-lg-6">
                        <div class="shadow-lg rounded-20 py-4 px-5 down_sm_padding_1 mb-4">
                            <div class="row">

  <BarChart :chartData="chartData" :options="chartOptions" />

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="shadow-lg rounded-20 py-4 px-5 down_sm_padding_1 mb-4">
                            <div class="row">
                                <div class="col-12 ps-0">
                                    <p class="black fw-bold font-size-base">Genel Statü</p>
                                </div>
                                <div class="col-12">
                                  <template v-for="status in reportStore.reportData.status_list" :key="status">
                                    <div class="row p-4 mb-3 d-flex align-items-center border rounded-10">
                                        <div class="col-6">
                                            <p class="m-0 black fw-bold font-size-base">{{ status.title }}</p>
                                        </div>
                                        <div class="col-6 text-end">
                                            <p class="m-0 teal fw-bold font-size-base">{{ status.total }}</p>
                                        </div>
                                    </div>
                                  </template>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                      <div class="shadow-lg rounded-20  position-relative">
                          <div v-if="sortingInProgress" class="position-absolute loader-area">
                            <span>
                              <span class="fa fa-sm fa-spinner fa-spin"></span>
                            </span>
                          </div>
                          <div class="table-responsive py-4 px-5 down_sm_padding_1 mb-4">
                            <table class="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th @click="getListOfIdeas('liked_count')" scope="col" class="text-center cursor-pointer">
                                    Beğeni
                                    <i class="fa-solid fa-arrow-right-arrow-left me-2 dark-gray" style="transform: rotate(90deg);"></i>
                                  </th>
                                  <th @click="getListOfIdeas('view_count')" scope="col" class="text-center cursor-pointer">
                                    Görüntüleme
                                    <i class="fa-solid fa-arrow-right-arrow-left me-2 dark-gray" style="transform: rotate(90deg);"></i>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="feed in reportStore.listOfIdeas" :key="feed">
                                  <tr>
                                      <th scope="row">
                                        <p style="width:max-content !important;" class="black fw-bold font-size-base m-0">{{feed.answers.feed_program_apply_title.formItemReply}}</p>
                                        <p style="width:max-content !important;" class="light-gray font-size-base fw-light m-0">{{feed.apply.user.name}} {{feed.apply.user.surname}}</p>
                                      </th>
                                      <td class="">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="rounded-10 p-3 d-flex align-items-center justify-content-center" style="height:30px !important; width:90px !important; background-color: rgba(147, 147, 147, 0.16);">
                                            <i class="fa-solid fa-thumbs-up me-2 dark-gray"></i>
                                            <p class="m-0 dark-gray font-size-sm fw-bold">{{feed.counts.likes}}</p>
                                        </div>
                                        </div>
                                      </td>
                                      <td class="">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="rounded-10 p-3 d-flex align-items-center justify-content-center" style="height:30px !important; width:90px !important; background-color: rgba(147, 147, 147, 0.16);">
                                            <i class="fa-solid fa-eye dark-gray me-2"></i>
                                            <p class="m-0 dark-gray font-size-sm fw-bold">{{feed.counts.views}}</p>
                                        </div>
                                        </div>
                                      </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useReportStore } from '../../store/report'
import LeftList from '../../components/bonapply/LeftList.vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default defineComponent({
  name: 'CommonView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Raporlar'
      reportStore.getReport().then(response => {
        const labels = []
        const chartDataValues = []
        reportStore.reportData.list_of_idea_totals.forEach((item) => {
          labels.push(item.title)
          chartDataValues.push(parseInt(item.total))
        })

        chartData.value = {
          labels,
          datasets: [
            {
              data: chartDataValues,
              backgroundColor: ['#54d14d', '#ffb300', '#1a9eff', '#ff3838']
            }
          ],
          options: {
            legend: {
              display: false
            }
          }
        }
      })
      getListOfIdeas('liked_view')
    })
    const reportStore = useReportStore()

    const chartOptions = ref({
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          },
          ticks: {
            stepSize: 1
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      }
    })
    const chartData = ref(null)
    const sortingInProgress = ref(false)
    let activeOrderBy = 'ASC'
    const getListOfIdeas = (orderParam) => {
      sortingInProgress.value = true
      if (activeOrderBy === 'ASC') {
        activeOrderBy = 'DESC'
      } else {
        activeOrderBy = 'ASC'
      }
      reportStore.getListOfIdeas(orderParam, activeOrderBy).then(
        response => {
          sortingInProgress.value = false
        }
      )
    }
    return {
      reportStore,
      chartOptions,
      chartData,
      getListOfIdeas,
      sortingInProgress
    }
  },
  components: {
    LeftList,
    BarChart
  },
  methods: {

  }
})
</script>

