import { acceptHMRUpdate, defineStore } from 'pinia'
import meService from '../services/me.service'

export const useMeStore = defineStore({
  id: 'meStore',
  state: () => ({
    me: null
  }),
  setup () {

  },
  actions: {
    getMyProfileData () {
      return meService.getMyProfileData().then(
        response => {
          this.me = response.data.data
          return response
        }
      )
    },
    updateMyProfileData () {
      return meService.updateMyProfileData()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMeStore, import.meta.hot))
}
