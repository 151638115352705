<template>
    <div class="col-12">
      <div v-if="!serviceError" class="row">
        <div v-if="!isFormReady" class="text-center">
          <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <h4>Form hazırlanıyor, lütfen bekleyin.</h4>
        </div>

        <div class="mb-4" v-if="isFormReady && applyStore.applyViewData != null && applyStore.applyViewData.parent_apply_will_be_selected === 1">
            <div class="p-0 mb-2">
              <FormItemParentApply :data="applyStore.applyViewData"/>
              <div v-if="hasError(0)" class="text-danger d-none">{{ itemError(0) }} </div>
            </div>
        </div>

        <div class="mb-4"  :class="formItemClass(formItem)" v-for="(formItem) in applyStore.formItems" :key="formItem">
            <div v-if="isFormReady" class="p-0 mb-2">
                <form-item :data="formItem" :disabled="viewOnlyMode"/>
                <div v-if="hasError(formItem.id)" class="text-danger d-none">{{ itemError(formItem.id) }} </div>
            </div>
        </div>

        <div class="mb-4" v-for="(agreement) in applyStore.agreements" :key="agreement">
            <div v-if="isFormReady" class="p-0 mb-2">
                <agreement-check :agreement="agreement" ref="agreementItems" />
            </div>
        </div>
        <div v-if="isFormReady" class="alert alert-info"><i class="fa fa-info-circle fa-sm"></i> Yapmış olduğunuz değişiklikler otomatik olarak kayıt edilmektedir</diV>

        <div class="d-flex justify-content-between" v-if="isFormReady">
          <ApplyPreview :applyId="applyStore.applyId" class="me-2" @onSendClick="onApplyPreviewSendClick"/>
          <button class="btn btn-secondary button_4 font-size-base" @click="onApplyButtonClick($event)">Gönder
            <span v-if="isSendingApply || applyStore.isUpdating">
              <span  class="fa fa-sm fa-spinner fa-spin"></span>
            </span>
          </button>
        </div>

        <!--
        <div id="errorMessages">
          <div v-if="errorMessages != null" class="mt-5">
            <div class="fw-bold mb-2">EKSİKLER</div>
            <div class="mb-1" v-for="(errorMessage) in errorMessages" :key="agreement">
              <span class="fw-bolder" style="color: #dc3545">{{ errorMessage.error_message }}</span>
            </div>
          </div>
        </div>
        -->

      </div>
    </div>

    <div v-if="serviceError" class="row">
        <div class="col-12">
          <h5 class="text-center">Form oluşturma esnasında beklenmeyen bir hata oluştu</h5>
        </div>
    </div>
</template>
<script>
import { defineComponent, defineExpose, onMounted, ref, watch } from 'vue'
import { useApplyStore } from '../../store/apply'
import ApplyPreview from '../../components/bonapply/ApplyPreview'
import AgreementCheck from '../../components/bonapply/AgreementCheck'
import { useToast } from 'vue-toastification'
import FormItemParentApply from "@/components/bonapply/form/items/FormItemParentApply.vue";

export default defineComponent({
  name: 'ApplyForm',
  props: {
    applyFormUId: null
  },
  data () { return {} },
  setup (props, context) {
    const toast = useToast()
    const agreementItems = ref(null)
    const sendApplyAfterLoad = ref(false)
    onMounted(() => {
      if (props.applyFormUId) {
        loadForm()
      }
    })
    const applyStore = useApplyStore()
    const viewOnlyMode = ref(null)
    const isFormReady = ref(false)
    const serviceError = ref(false)
    const isSendingApply = ref(false)
    const errorMessages = ref(null)

    watch(() => props.applyFormUId, (first, seccond) => {
      if (props.applyFormUId !== null) loadForm()
    })

    const loadForm = () => {
      isSendingApply.value = false
      isFormReady.value = false
      if (props.applyFormUId !== undefined) {
        applyStore.get(props.applyFormUId).then(
          response => {
            if (response.status === 200) {
              viewOnlyMode.value = (response.data.data.demo !== 0)
              isFormReady.value = true

              if (sendApplyAfterLoad.value) {
                toast.error('Başvuru yapabilmek için sözleşmeleri onaylamanız gerekmektedir.')
              }
            } else {
              serviceError.value = true
            }

            sendApplyAfterLoad.value = false
          }
        )
      }
    }

    const sendApply = () => {
      if (isSendingApply.value === true) return
      // if (applyStore.isUpdating === true) return
      if (checkAgreements()) {
        errorMessages.value = null
        isSendingApply.value = true
        applyStore.sendApply().then(
          response => {
            if (response.status === 200) {
              context.emit('onApplySuccess', {})
            } else {
              isSendingApply.value = false
              errorMessages.value = response.response.data.data.error_messages
              setTimeout(function() {
                document.getElementById("errorMessages").scrollIntoView()
              }, 200);
            }
          }
        )
      }
      return false
    }

    const checkAgreements = () => {
      if (applyStore.agreements.length === 0) return true
      let hasError = false
      agreementItems.value.forEach((agreementItem) => {
        if (!agreementItem.inputValue) hasError = true
      })
      if (hasError) {
        toast.error('Başvuru yapabilmek için sözleşmeleri onaylamanız gerekmektedir.')
        return false
      } else {
        return true
      }
    }

    const onApplyButtonClick = (event) => {
      sendApply()
    }

    const onApplyPreviewSendClick = () => {
      sendApply()
    }

    const onPreviewClick = (event) => {

    }

    const formItemClass = (formItem) => {
      let cssClass = formItem.itemClass
      if (hasError(formItem.id)) cssClass += ' text-danger'
      return cssClass
    }

    const hasError = (itemId) => {
      if (applyStore.formErrors) {
        if (applyStore.formErrors[itemId] !== undefined) {
          return applyStore.formErrors[itemId]
        } else {
          return false
        }
      }
      return false
    }

    const itemError = (itemId) => {
      if (applyStore.formErrors) {
        if (applyStore.formErrors[itemId] !== undefined) {
          return applyStore.formErrors[itemId].error_message
        } else {
          return false
        }
      }
      return false
    }

    const sendApplyAfterLoaded = () => {
      sendApplyAfterLoad.value = true
    }

    defineExpose({ sendApplyAfterLoaded })

    return {
      isFormReady,
      applyStore,
      onApplyButtonClick,
      onApplyPreviewSendClick,
      onPreviewClick,
      viewOnlyMode,
      hasError,
      itemError,
      agreementItems,
      serviceError,
      isSendingApply,
      formItemClass,
      sendApplyAfterLoaded,
      errorMessages
    }
  },
  components: {
    FormItemParentApply,
    ApplyPreview,
    AgreementCheck
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>


@import 'src/styles/vakifbank-style.scss';
</style>
