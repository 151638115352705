<template>
  <div>
    <FormItemParentApply v-if="data.itemType=='parent_apply'" :data="data"  :disabled="disabled"/>
    <FormItemFile v-if="data.itemType=='file'"                :data="data"  :disabled="disabled"/>
    <FormItemYesNo v-if="data.itemType=='yes_no'"             :data="data"  :disabled="disabled"/>
    <FormItemSubTitle v-if="data.itemType=='sub_title'"       :data="data"  :disabled="disabled"/>
    <FormItemURL v-if="data.itemType=='input_url'"            :data="data"  :disabled="disabled"/>
    <FormItemNumber v-if="data.itemType=='input_number'"      :data="data"  :disabled="disabled"/>
    <FormItemPhone v-if="data.itemType=='input_phone'"        :data="data"  :disabled="disabled"/>
    <FormItemEmail v-if="data.itemType=='input_email'"        :data="data"  :disabled="disabled"/>
    <FormItemTextarea v-if="data.itemType=='textarea'"        :data="data"  :disabled="disabled"/>
    <FormItemInput v-if="data.itemType=='input'"              :data="data"  :disabled="disabled"/>
    <FormItemDate v-if="data.itemType=='date'"                :data="data"  :disabled="disabled"/>
    <FormItemRadio v-if="data.itemType=='radio'"              :data="data"  :disabled="disabled"/>
    <FormItemCheckbox v-if="data.itemType=='checkbox'"        :data="data"  :disabled="disabled"/>
    <FormItemSelectBox v-if="data.itemType=='selectbox'"      :data="data"  :disabled="disabled"/>
    <FormItemCity v-if="data.itemType=='city'"                :data="data"  :disabled="disabled"/>
    <FormItemStarRating v-if="data.itemType=='rating'"        :data="data"  :disabled="disabled"/>
    <FormItemConditional v-if="data.itemType=='conditional'"  :data="data"  :disabled="disabled"/>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'

import FormItemFile from '@/components/bonapply/form/items/FormItemFile.vue'
import FormItemYesNo from '@/components/bonapply/form/items/FormItemYesNo.vue'
import FormItemSubTitle from '@/components/bonapply/form/items/FormItemSubTitle.vue'
import FormItemURL from '@/components/bonapply/form/items/FormItemURL.vue'
import FormItemNumber from '@/components/bonapply/form/items/FormItemNumber.vue'
import FormItemPhone from '@/components/bonapply/form/items/FormItemPhone.vue'
import FormItemEmail from '@/components/bonapply/form/items/FormItemEmail.vue'
import FormItemTextarea from '@/components/bonapply/form/items/FormItemTextarea.vue'
import FormItemInput from '@/components/bonapply/form/items/FormItemInput.vue'
import FormItemStarRating from '@/components/bonapply/form/items/FormItemStarRating.vue'
import FormItemDate from '@/components/bonapply/form/items/FormItemDate.vue'
import FormItemRadio from '@/components/bonapply/form/items/FormItemRadio.vue'
import FormItemCheckbox from '@/components/bonapply/form/items/FormItemCheckbox.vue'
import FormItemSelectBox from '@/components/bonapply/form/items/FormItemSelectBox.vue'
import FormItemCity from '@/components/bonapply/form/items/FormItemCity.vue'
import FormItemConditional from '@/components/bonapply/form/items/FormItemConditional.vue'
import FormItemParentApply from "@/components/bonapply/form/items/FormItemParentApply.vue";

export default defineComponent({
  name: 'FormItem',
  props: {
    data: {},
    disabled: null
  },
  disabled () { return false },
  data () { return {} },
  setup (props) {
    onMounted(() => {

    })
    return {

    }
  },
  components: {
    FormItemParentApply,
    FormItemFile,
    FormItemYesNo,
    FormItemSubTitle,
    FormItemURL,
    FormItemNumber,
    FormItemPhone,
    FormItemEmail,
    FormItemTextarea,
    FormItemInput,
    FormItemStarRating,
    FormItemDate,
    FormItemRadio,
    FormItemCheckbox,
    FormItemSelectBox,
    FormItemCity,
    FormItemConditional
  },
  methods: {

  }
})
</script>

