import { BaseService } from './BaseService'
const VIEW = '/v1/views/add'

class AnalyticsService extends BaseService {
  sendView (model, id) {
    const data = {
      relation: model,
      relation_id: id
    }
    return this.post(VIEW, data)
  }
}

export default new AnalyticsService()
