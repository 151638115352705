<template>
<div></div>
</template>

<script>
import { defineComponent } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'LogoutView',
  setup () {
    const router = useRouter()
    const authStore = useAuthStore()

    authStore.logout()
    router.push('/login')

    return {

    }
  }
})
</script>
