<template>
  <layout-type-1>
    <div class="mb-4">
      <FormTextSlot form-text="OAuth"></FormTextSlot>
    </div>
    <Form></Form>

    <div class="text-center">
      <div class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <h4>OAuth</h4>
    </div>

  </layout-type-1>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'OAuthView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'Yaşar IDEA | OAuth'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    authStore.oauthCheck().then(
      response => {
        router.push('/programs')
      }
    )
    return {
      authStore
    }
  },
  components: {
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

</style>
