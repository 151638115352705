<template>
<a :href="'/blog/' + post.slug" class="text-decoration-none">
    <div class="bg-purple-color rounded-5 p-4">
      <div class="mb-2" v-if="post.coverImg != ''">
          <img :src="post.coverImg" class="img-fluid" alt="Blog Image">
      </div>
      <div class="mb-4">
        <p class="m-0 text-lightGray-color font-size-5 fw-semibold">{{ post.releaseDate }}</p>
      </div>
      <div style="height:100px !important;">
        <p class="m-0 text-midnightBlue-color fw-bold font-size-7 line-overflow down-md-fs-2" v-html="post.content"></p>
      </div>
    </div>
</a>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlogCard',
  props: {
    post: {}
  },
  data () { return {} },
  setup (props) {
    return {

    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>


.hover-border{
  border: solid 5px transparent !important;
}
.hover-border:hover{
  border: solid 5px #fdb913 !important;
}
</style>
