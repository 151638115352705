<template>
<div class="container">
   <div class="row up-lg-mx up-lg-px up-md-pt-pb down-md-mb-mt">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 down-md-mb d-flex align-items-center">
        <img src="@/assets/LeadImages/img-8.png" class="img-fluid">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 up-lg-ps d-flex align-items-center">
      <div class="w-100">
        <div class="mb-2">
          <FormTextSlot class="m-0 text-midnightBlue-color font-size-11 fw-bold down-md-fs-3" form-text="Şifremi Unuttum"></FormTextSlot>
        </div>
        <div class="mb-4">
          <p class="m-0 text-gray-color font-size-6 fw-500">Contrary to popular belief, Lorem Ipsum is not simply </p>
        </div>
        <Form></Form>
        <form @submit="onSubmit">
          <div class="mb-4 up-lg-input-width">
            <div class="form-outline mb-4 text-start">
            <field id="email" name="email" placeholder="Mail Adresi" class="form-control shadow-none" />
            <span class="red text-gray-color font-size-2">{{ errors.email }}</span>
          </div>
          <div class="col-12">
            <ButtonSlot class="btn-primary" button-text="Şifre Gönder"></ButtonSlot>
          </div>
          </div>
        </form>
      </div>
    </div>
   </div>
</div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useForm, Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { useAuthStore } from '../../store/auth'
import { useRouter } from 'vue-router'
import ButtonSlot from '../../layouts/ButtonSlot.vue'
import FormTextSlot from '../../layouts/FormTextSlot'

export default defineComponent({
  name: 'ForgotPasswordView',
  data () { return {} },
  setup (props) {
    onMounted(() => {
      document.title = 'Yaşar IDEA | Şifremi Unuttum'
    })
    const router = useRouter()
    const authStore = useAuthStore()
    const schema = yup.object().shape({
      email: yup.string().required('*Lütfen E-Posta Adresinizi Yazınız').email()
    })

    const { handleSubmit, isSubmitting, errors } = useForm({
      validationSchema: schema
    })

    const onSubmit = handleSubmit((values, actions) => {
      authStore.passwordUpdateabilityCheck(values, actions).then(
        response => {
          if (response.status === 200 && response.data.data.status === 1) {
            authStore.resetPassword(values, actions).then(
              response => {
                if (response.status === 200) {
                  router.push('/login')
                }
              }
            )
          } else {
            // toast.error(response.data.message)
          }
        }
      )
    })

    return {
      authStore,
      onSubmit,
      isSubmitting,
      errors
    }
  },
  components: {
    Form,
    Field,
    ButtonSlot,
    FormTextSlot
  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>

.form-control::placeholder{
  color: #6F7273 !important;
  font-weight:500 !important;
}
</style>
