import { acceptHMRUpdate, defineStore } from 'pinia'
import analyticsService from '@/services/analytics.service'

export const useAnalyticsStore = defineStore({
  id: 'analyticsStore',
  state: () => ({

  }),
  setup () {},
  actions: {
    sendView (model, id) {
      return analyticsService.sendView(model, id).then(
        response => {
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnalyticsStore, import.meta.hot))
}
